import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import { matchPath } from "react-router";
import isEmpty from "lodash/isEmpty";

import { history } from "../../routes/urlLocations";
import { CLEAR_ERRORS, SET_ERRORS } from "../errors/errors.action";
import { prepareFilesDataForSave } from "../file/file.utils";
import { setFamilyChildRequest } from "../children/children.api";
import {
  SET_FAMILY_CHILD,
  SET_FAMILY_CHILD_FAILURE,
  SET_FAMILY_CHILD_SUCCESS,
  SET_NO_CHILD
} from "../children/children.action";
import openNotification from "../../utils/notifications";
import { isUrlMatch } from "../router/router.utils";
import { urlLocations } from "../../routes/urlLocations";
import { uploadFilesUtil } from "../family/family.utils";
import { CLEAR_FILES } from "../file/file.action";
import { LOCATION_CHANGE } from "connected-react-router";
import { GET_FAMILY_INFO } from "../family/family.action";
import { SET_NO_CHILD_FAILURE, SET_NO_CHILD_SUCCESS } from "./children.action";
import { setNoChildrenRequest } from "./children.api";

function* setFamilyChild({ payload }) {
  try {
    yield put({ type: CLEAR_ERRORS });
    const files = yield select(state => state.files);
    const prepareFiles = prepareFilesDataForSave(files.children);

    const { response } = yield setFamilyChildRequest({
      child: { ...payload, ...prepareFiles }
    });
    if (response.status === 200) {
      yield put({ type: SET_FAMILY_CHILD_SUCCESS });
      yield put({ type: GET_FAMILY_INFO });
      history.push(urlLocations.childrenList);
      yield call(openNotification, {
        message: "FamilyPage.Child.Success",
        description: "FamilyPage.Child.SuccessMessage",
        type: "success"
      });
    } else {
      yield put({
        type: SET_ERRORS,
        payload: response.data.data
      });
      yield call(openNotification, {
        message: "FamilyPage.Child.Error",
        description: "FamilyPage.Child.ErrorMessage",
        type: "error"
      });
      yield put({ type: SET_FAMILY_CHILD_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

function* setChildFilesOnLocationChange({ payload }) {
  try {
    yield delay(500);
    const children = yield select(state => state.family.children);

    if (isUrlMatch(payload, urlLocations.childInfo) && !isEmpty(children)) {
      const id = matchPath(payload.location.pathname, {
        path: urlLocations.childInfo,
        exact: true
      }).params?.id;

      const foundChildren = children.find(child => child.id.toString() === id);
      yield uploadFilesUtil({ children: foundChildren }, "children");
    } else {
      yield put({
        type: CLEAR_FILES,
        payload: {
          formName: "children"
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* setNoChildren() {
  try {
    const { response } = yield setNoChildrenRequest({
      children: null,
      numberOfChildren: 0
    });
    if (response.status === 200) {
      yield put({ type: SET_NO_CHILD_SUCCESS });
      yield put({ type: GET_FAMILY_INFO });
    } else {
      yield put({ type: SET_NO_CHILD_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* childrenSaga() {
  yield takeEvery(SET_FAMILY_CHILD, setFamilyChild);
  yield takeEvery(SET_NO_CHILD, setNoChildren);
  yield takeEvery(LOCATION_CHANGE, setChildFilesOnLocationChange);
}
