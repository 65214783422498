import React from 'react';

import Styled from "../../containers/MarriageNOCDetails/styled";
import RadioField from "../../components/RadioField/RadioField";
import {FORMS_NAME, PREVIOUS_ENGAGEMENT_RADIO} from "../../constants/constants";
import InputField from "../../components/InputField/InputField";
import MarriageNocDetailsSection from "../../containers/MarriageNOCDetails/MarriageNOCDetails.Section";
import {formValueSelector} from "redux-form";
import {useSelector} from "react-redux";

const MarriageNocDetailsPreviousEngagement = ({disabled} = {disabled: false}) => {

    const formSelector = formValueSelector(FORMS_NAME.MARRIAGE_NOC);
    const prevEngagement = useSelector(state => formSelector(state, 'prevEngagement'));

    return (
        <MarriageNocDetailsSection title={"MarriageNOCDetails.PreviousEngagement"}>
            <Styled.FieldsWrap>
                <Styled.Field>
                    <RadioField
                        radioSet={PREVIOUS_ENGAGEMENT_RADIO}
                        label={"MarriageNOCDetails.PreviousEngagement"}
                        name={"prevEngagement"}
                        disabled={disabled}
                    />
                </Styled.Field>
                {prevEngagement && <Styled.Field>
                    <InputField
                        label={"MarriageNOCDetails.HowManyTimes"}
                        placeholder={"MarriageNOCDetails.HowManyTimes"}
                        name={"howManyEngagements"}
                        disabled={disabled}
                    />
                </Styled.Field>}
            </Styled.FieldsWrap>
        </MarriageNocDetailsSection>
    );
};

export default MarriageNocDetailsPreviousEngagement;
