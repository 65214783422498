import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledLiturgyItem = styled.div`
  max-width: 688px;
  width: 100%;
  
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(139, 155, 163, 0.1);
  border: solid 1px #d6e1e9;
  background-color: var(--liturgyBottom);
  margin-bottom: 17px;
  overflow: hidden;
`
export default StyledLiturgyItem ;