import React from 'react';
import { reduxForm } from "redux-form";
import InputField from "../../../../components/InputField/InputField";
import Button from "../../../../components/Button/Button";
import Styled from './styled'
import {forgotPasswordAction} from "../../../../services/password/password.action";
import {FormattedMessage} from "react-intl";

const ForgotPasswordForm = ({ handleSubmit, setPopupStatus }) => (
    <Styled.Content>
        <form onSubmit={handleSubmit}>
            <h1>
                <FormattedMessage id="ForgotPassword.Title" />
            </h1>
            <p>
               <FormattedMessage id="ForgotPassword.Description" />
            </p>
            <InputField
                name="email"
                placeholder="Login.Email.Placeholder"
                label="Login.Email.Placeholder"
            />
            <Styled.ButtonRow>
                <Button htmlType="submit" ><FormattedMessage id="ForgotPassword.Send" /></Button>
                <Button
                    uiType="secondary"
                    onClick={setPopupStatus.bind(null, null)}
                >
                    <FormattedMessage id="ForgotPassword.Close" />
                </Button>
        </Styled.ButtonRow>

        </form>
    </Styled.Content>
);

export default reduxForm({
    form: "forgotPasswordForm",
    onSubmit: (value, dispatch) => dispatch(forgotPasswordAction(value))
})(ForgotPasswordForm)