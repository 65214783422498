import styled from 'styled-components';
import {Radio} from 'antd';

export const StyledFilters = styled(Radio.Group)`
  width: 100%;
  margin-top: 35px;
`
export const StyledFilterTab = styled(Radio.Button)`
  width: 33%;
  text-align: center;
  font-family: var(--fontProximaRegular);
  height: auto;
`
