import React from "react";
import {FormattedMessage} from "react-intl";

import Styled from "./styled";
import StyledFormTitle from "../styled/StyledFormTitle";
import ResetPasswordForm from "./ResetPasswordPage.Form/ResetPasswordPage.Form";
import {withRouter} from "react-router";

const ResetPasswordPage = ({ location }) => {
    const code = new URLSearchParams(location.search).get('code');

    return (
    <Styled.Page>
        <StyledFormTitle>
            <FormattedMessage id="ResetPassword.Title" />
        </StyledFormTitle>
        <p>
            <FormattedMessage id="ResetPassword.Description" />
        </p>
        <ResetPasswordForm
            code={code}
        />
    </Styled.Page>
)};


export default withRouter(ResetPasswordPage);