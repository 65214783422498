import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledBookingCardCode = styled.div`
	
    width: 89px;
    
    
    canvas {
        width: 89px !important;
        height: 89px !important;
    }
    
     @media ${DEVICE.laptop} {
		position: absolute;
		right: 55px; 
        top: 22px;
	}

      
`

export default StyledBookingCardCode;


