import styled from "styled-components";
import { DEVICE } from "../../../../constants/media";

const StyledLiturgyBooking = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${({ isPrivate }) => (isPrivate ? "" : "#f8f9fb")};
  margin: 0;
  padding: 18px 12px 4px 12px;

  @media ${DEVICE.tablet} {
    width: 640px;
    min-height: 104px;
    margin: 16px 24px 24px;
    padding: 18px 22px 0 22px;
  }
  @media ${DEVICE.laptopL} {
    min-height: 104px;
  }
`;
export default StyledLiturgyBooking;
