export default {
  //Main Page
  "MainPage.Logo": "UAE Copts Logo",
  "MainPage.Description.Title": "Description",
  "MainPage.Description":
    "Uae Copts is your portal to be able to book to attend holy liturgies during the current restrictions." +
    "\nIn order to be able to book, you will need to fill the full details about your family members who lives in UAE",
  "MainPage.Description2":
    "\nPlease make sure that you have prepared all of this data/documents for each family member before starting:" +
    "\n • Profile photo." +
    "\n • Emirates ID number, and scanned copy for both sides." +
    "\n • Egyptian national ID number, and scanned copy for both sides." +
    "\n • Passport number for non-Egyptian members if any.",
  "MainPage.ContactPerson": "Contact Person",
  //Header
  "Header.Notification.Alt": "Your notification",
  "Header.Logout": "Logout",
  "Header.PrivacyPolicy": "Privacy policy",

  //logo
  "Logo.Main.Alt": "UAE Copts",
  "Logo.Page.Alt": "UAE Copts",
  //Registration
  "Registration.Title": "Registration",
  "Registration.Email.Placeholder": "Email",
  "Registration.Password.Placeholder": "Password",
  "Registration.Username.Placeholder": "Username",
  "Registration.Status.Placeholder": "Who is in your account",
  "Registration.Country": "Select your Country",
  "Registration.Button": "Register",
  "Registration.Agreement": "I agree to the ",
  "Registration.TermsAndConditions": " Terms & Conditions",
  "Registration.Bottom": "Already registered? ",
  "Registration.Bottom.SignIn": "Sign In",
  "Registration.FamilyStatus.Alone": "Alone",
  "Registration.FamilyStatus.Family": "Family",

  //REGISTRATION NOTIFICATIONS
  "Registration.Notification.SuccessMessage":
    "Registration successfully complete. We send you an email to confirm your email.",
  "Registration.Notification.Success": "Email Confirmation needed",
  "Registration.Notification.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",
  "Registration.Notification.Error": "Error",

  //Login
  "Login.Title": "Welcome back",
  "Login.Email.Placeholder": "Email",
  "Login.Password.Placeholder": "Password",
  "Login.Button": "Login",
  "Login.success": "We send you email :)",
  "Login.CTA": "I don’t have an account?",
  "Login.CTA.Register": " Register",
  "Login.Forgot": "Forgot a password?",
  //DISCLAIMER

  "Disclaimer.Title": "Please do not book in case of the following:",
  "Disclaimer.Desc":
    "\t•\tIf you feel any symptoms of sickness, fever, any sickness in the respiratory system e.g. difficulty in breathing or any of the Corona virus symptoms\n" +
    "\t•\tIf you have been in contact with any positive COVID-19 cases\n" +
    "\t•\tIn case you suffer from any chronic diseases and prone to infections",

  //LOGIN  NOTIFICATIONS
  "Login.Notification.SuccessMessage": "Login successfully",
  "Login.Notification.Success": "Success",
  "Login.Notification.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",
  "Login.Notification.Error": "Error",

  //Home page
  "HomePage.Family.Title": "Edit Family info",
  "HomePage.Family.Desc":
    "Here you can see and edit the information of your family members at any time",
  "HomePage.Liturgy.Title": "Holy Liturgy Booking",
  "HomePage.Liturgy.Desc":
    "Here you can see the available Holy Liturgies at all churches, and book to attend any of them",
  "HomePage.Bottom.Title": "Booking Roles",
  "HomePage.Bottom.Desc":
    "Each person is eligible to book only one liturgy." +
    "\nKids less than 12 years old are not allowed to book" +
    "\nYou cannot book to attend liturgies in multiple churches in UAE" +
    "\nVespers reservation is separate and does not interfere with Liturgy reservations",
  "HomePage.Bottom.Desc2": null,
  "HomePage.Booking.Title": "Manage your Bookings",
  "HomePage.Booking.Desc":
    "Her you can see bookings you have made to attend the Holy Liturgies. Also you will be able to edit, or cancel your bookings",
  "HomePage.Others.Title": "Other Services",
  "HomePage.Others.Desc":
    "Here you can check other church services like request a Marriage NOC, and so on",

  //Family Page
  "FamilyPage.Aside.PersonalInfo": "Your information",
  "FamilyPage.Aside.SpouseInfo": "Spouse information",
  "FamilyPage.Aside.ChildrenInfo": "Children information",
  "FamilyPage.Aside.ParentsInfo": "Parents information",
  "FamilyPage.Aside.AddressInfo": "Your Address",
  "FamilyPage.Aside.BackButton": "Back to menu",

  "FamilyPage.Section.Main": "Main information",
  "FamilyPage.Section.Contact": "Contact details",
  "FamilyPage.Section.Identification": "Identification",
  "FamilyPage.Section.Additional": "Additional info",

  //Main section
  "FamilyPage.Personal.Title": "Fill your information",
  "FamilyPage.Personal.YourPhoto": "Your photo",
  "FamilyPage.Form.Additional": "Additional info",
  "FamilyPage.Form.FirstName": "First Name",
  "FamilyPage.Form.SecondName": "Second Name",
  "FamilyPage.Form.LastName": "Last Name",
  "FamilyPage.Form.Gender": "Gender",
  "FamilyPage.Form.DateOfBirth": "Date of Birth",
  "FamilyPage.Form.Male": "Male",
  "FamilyPage.Form.Female": "Female",
  //Contact
  "FamilyPage.Form.Email": "Email",
  "FamilyPage.Form.Phone": "Mobile",
  "FamilyPage.Form.Whatsapp": "Whatsapp (if different)",

  "FamilyPage.Form.SocialStatus": "Social Status",
  "FamilyPage.Form.SocialStatus.Single": "Single",
  "FamilyPage.Form.SocialStatus.Widow": "Widow",
  "FamilyPage.Form.SocialStatus.Married": "Married",
  "FamilyPage.Form.SocialStatus.Other": "Other",

  "FamilyPage.Form.VisaType": "Visa Type",
  "FamilyPage.Form.VisaType.Residence": "Residence",
  "FamilyPage.Form.VisaType.Visit": "Visit",
  "FamilyPage.Form.VisaType.Other": "Other",
  "FamilyPage.Form.EmiratesID": "Emirates ID",
  "FamilyPage.Form.NationalID": "Egyption National ID",

  "FamilyPage.Form.FatherOfConfession": "Father of Confession",
  "FamilyPage.Form.Service": "Service",
  "FamilyPage.Form.WorkingField": "Working field",
  "FamilyPage.Form.Nationality": "Nationality",
  "FamilyPage.Form.Nationality.Egyptian": "Egyptian",
  "FamilyPage.Form.Nationality.Other": "Other",
  "FamilyPage.Form.PassportNumber": "Passport number",
  "FamilyPage.Form.Deacon": "Deacon",
  "FamilyPage.Form.Deacon.Yes": "Yes",
  "FamilyPage.Form.Deacon.No": "No",
  "FamilyPage.Form.SaveButton": "Save info",
  "FamilyPage.Form.UploadPhoto": "Upload new photo",
  "FamilyPage.Form.PhotoInfo": "The maximum size is 2 MB. JPG or PNG file",
  "FamilyPage.Form.DocumentsInfo":
    "The maximum size is 2 MB. JPG, PNG or PDF file",

  "FamilyPage.Form.UploadNationalID": "Upload Egyptian National ID copy",
  "FamilyPage.Form.UploadEmirateID": "Upload Emirate ID copy",

  //PERSONAL NOTIFICATIONS
  "FamilyPage.Personal.Success": "Success",
  "FamilyPage.Personal.SuccessMessage":
    "Personal information successfully saved",
  "FamilyPage.Personal.Error": "Error",
  "FamilyPage.Personal.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",

  //SPOUSE
  "FamilyPage.Spouse.Title": "Your spouse information",
  "FamilyPage.Spouse.YourPhoto": "Spouse photo",
  "FamilyPage.Spouse.SpouseStatus": "Spouse status",
  "FamilyPage.Spouse.SpouseStatus.Available": "Available",
  "FamilyPage.Spouse.SpouseStatus.PassedAway": "Passed away",
  "FamilyPage.Spouse.SpouseStatus.NotAvailable": "Not available",

  //SPOUSE NOTIFICATIONS
  "FamilyPage.Spouse.Success": "Success",
  "FamilyPage.Spouse.SuccessMessage": "Spouse information successfully saved",
  "FamilyPage.Spouse.Error": "Error",
  "FamilyPage.Spouse.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",

  //CHILDREN
  "FamilyPage.Child.ListTitle": "Your children information",
  "FamilyPage.Child.Title": "Your child information",
  "FamilyPage.Child.YourPhoto": "Children photo",
  "FamilyPage.Child.NoChildren": "I have no children",
  "FamilyPage.Child.AddChild": "Add child",
  "FamilyPage.Child.Next": "Next",
  "FamilyPage.Child.BackToList": "Back to list",

  //CHILDREN NOTIFICATIONS
  "FamilyPage.Child.Success": "Success",
  "FamilyPage.Child.SuccessMessage": "Child information successfully saved",
  "FamilyPage.Child.Error": "Error",
  "FamilyPage.Child.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",

  //PARENTS
  "FamilyPage.Parent.ListTitle": "Your parents information",
  "FamilyPage.Parent.Title": "Your parent information",
  "FamilyPage.Parent.NoParents":
    "I have no parents living with me in same home",
  "FamilyPage.Parent.AddParent": "Add parent",
  "FamilyPage.Parent.Next": "Next",
  "FamilyPage.Parent.Finish": "Finish",

  //PARENTS NOTIFICATIONS
  "FamilyPage.Parent.Success": "Success",
  "FamilyPage.Parent.SuccessMessage": "Parent information successfully saved",
  "FamilyPage.Parent.Error": "Error",
  "FamilyPage.Parent.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",

  //ADDRESS
  "FamilyPage.Address.Title": "Your address information",
  "FamilyPage.Address.CurrentLocation": "Current location",
  "FamilyPage.Address.AddCurrentLocation": "At home now? Add Current location",
  "FamilyPage.Address.CurrentLocationAdded": "Home Location Added",
  "FamilyPage.Address.Additional": "Additional info",

  "FamilyPage.Address.Emirates": "Emirates",
  "FamilyPage.Address.Address": "Address",
  "FamilyPage.Address.Area": "Area",
  "FamilyPage.Address.Church": "Church",
  "FamilyPage.Address.Save": "Save",

  //ADDRESS NOTIFICATIONS
  "FamilyPage.Address.Success": "Success",
  "FamilyPage.Address.SuccessMessage": "Address information successfully saved",
  "FamilyPage.Address.Error": "Error",
  "FamilyPage.Address.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",

  //Liturgy
  "LiturgyPage.Title": "Select the prayer you want to attend",
  "LiturgyPage.Booked": "Booked",
  "LiturgyPage.Exclusive": "Exclusive for { churchName } members only",
  "LiturgyPage.NotRestricted": "Open Booking",
  "LiturgyPage.NoLiturgy": "No more events available for this week",
  "LiturgyPage.Filter.Liturgies": "Liturgies & Prayers",
  "LiturgyPage.Filter.Meetings": "Meetings & Services",
  "LiturgyPage.Filter.SundaySchools": "Activities",

  "LiturgyPage.Button.Book": "Book",
  "LiturgyPage.Button.Booked": "Already Booked",
  "LiturgyPage.Button.FullyBooked": "Fully Booked",
  "LiturgyPage.Modal.Loading": "Loading",
  "LiturgyPage.Modal.Church": "Church",
  "LiturgyPage.Modal.Date": "Date",
  "LiturgyPage.Modal.Time": "Time",
  "LiturgyPage.Modal.Attend": "Who will attend",
  "LiturgyPage.Modal.BookNow": "Book now",
  "LiturgyPage.Modal.Close": "Close",
  "LiturgyPage.Aside.Back": "Back to menu",
  "LiturgyPage.Aside.Count":
    "{ count, plural, one { Prayer } other { Prayers }  }",

  //Liturgy NOTIFICATIONS
  "LiturgyPage.Notification.Success": "Success",
  "LiturgyPage.Notification.SuccessMessage": "Event successfully booked",
  "LiturgyPage.Notification.Error": "Error",
  "LiturgyPage.Notification.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",
  "Event.Booking.noAvailSpace":
    "Looks there is a height demand on booking this Liturgy and it became fully booked before confirming your booking, please refresh your page and choose another Liturgy",
  "Event.ModifyBooking.noAvailSpace":
    "This Liturgy is fully booked، and cannot add new members to it",
  "LiturgyPage.BookedModal.Title": "Already Booked",
  "LiturgyPage.BookedModal.CloseButton": "Close",
  "LiturgyPage.BookedModal.Text":
    "You already booked this liturgy, you can add/remove members from { link }",
  "LiturgyPage.BookedModal.Link": "my booking screen",

  //Bookings
  "BookingsPage.Title": "Your Bookings",
  "BookingsPage.Back": "Back to menu",
  "BookingsPage.Canceled": "Canceled",
  "BookingsPage.Church": "Church",
  "BookingsPage.Date": "Date",
  "BookingsPage.Time": "Time",
  "BookingsPage.Attend": "Attend",
  "BookingsPage.Cancel": "Cancel booking",
  "BookingsPage.Modify": "Modify booking",
  "BookingsPage.Empty": "Sorry, you don't have any bookings yet",

  //
  "BookingsPage.Notification.Cancel.Success": "Success",
  "BookingsPage.Notification.Cancel.SuccessMessage":
    "Booking successfully canceled",

  //Errors
  "Auth.form.error.email.taken": "Email is already taken",
  "Auth.form.error.password.provide": "Please provide your password",
  "Auth.form.error.username.taken": "Username already taken",
  "Auth.form.error.email.provide": "Please provide your email",
  "Auth.form.error.invalid": "Invalid email or password",

  "Family.member.dateOfBirth.required": "Required",
  "Family.member.gender.required": "Required",
  "Family.member.mobile.required": "Required",
  "Family.member.mobile.exists":
    "Mobile already exists, please make sure you register only once",
  "Family.member.nationality.required": "Required",
  "Family.member.passportNumber.required": "Required",
  "Family.member.profilePic.required": "Required",
  "Family.member.visaType.required": "Required",
  "Family.member.email.required": "Required",
  "Family.member.email.exists":
    "Email already exists, please make sure you register only once",
  "Family.member.nationalIDNumber.required": "Required",
  "Family.member.nationalIDNumber.exists":
    "Egyptian ID Number already exists, please make sure you register only once",
  "Family.member.firstName.required": "Required",
  "Family.member.lastName.required": "Required",
  "Family.member.secondName.required": "Required",
  "Family.member.email.notValid": "Email not valid",
  "Family.member.emiratesID.required": "Required",
  "Family.member.emiratesIDCopy.required": "Required",
  "Family.member.nationalIDCopy.required": "Required",
  "Family.member.emiratesID.exists":
    "Emirates ID already exists, please make sure you register only once",
  "Family.member.fullName.exists":
    "Name already exists, please make sure you register only once",
  "Family.address.emirate.required": "Required",
  "Family.address.addressLine.required": "Required",
  "Family.address.area.required": "Required",
  "Family.address.church.required": "Required",
  "Password.Validation":
    "Your password must be at least 8 characters long, " +
    "contain at least one number and have a mixture of uppercase and lowercase letters.",

  //RESEND EMAIL
  "ResendEmail.Title": "Email Verification Needed",
  "ResendEmail.Description":
    "This email account is not verified. You need to verify your email address before start using the portal." +
    '\nWe have sent you a verification email after you have registered, please check your inbox and click "Verify My Email" button.' +
    "\nIf you cannot find that email, please check the Junk folder." +
    "\n\nYou can resend the verification email by writing your email below.",
  "ResendEmail.Resend": "Resend",
  "ResendEmail.Cancel": "Close",
  "ResendEmail.Notification.Success": "Success",
  "ResendEmail.Notification.SuccessMessage":
    "Verification email sent successfully.",
  "ResendEmail.Notification.Error": "Error",
  "ResendEmail.Notification.ErrorMessage":
    "There is something wrong with the data you have entered, please try again later, or contact your church support",
  //Forgot password
  "ForgotPassword.Title": "Forgot Password?",
  "ForgotPassword.Description":
    "Enter you email address and we will send you an email to reset your password",
  "ForgotPassword.Send": "Send",
  "ForgotPassword.Close": "Close",
  "ForgotPassword.Notification.Success": "Success",
  "ForgotPassword.Notification.SuccessMessage": "Email was successfully send",
  "ForgotPassword.Notification.Error": "Error",
  "ForgotPassword.Notification.ErrorMessage":
    "There is something wrong with the data you have entered, please try again later, or contact your church support",
  "Auth.form.error.email.format":
    "Not valid email address, please write it the correct format",
  "Auth.form.error.user.not-exist":
    "No accounts found for this email address, please make sure to write it correctly",

  //Reset password
  "ResetPassword.Title": "Reset Your Password",
  "ResetPassword.Description": "Please enter your new password",

  "ResetPassword.Button": "Set password",
  "ResetPassword.Notification.Success": "Success",
  "ResetPassword.Notification.SuccessMessage":
    "Yor password has been reset successfully",
  "ResetPassword.Notification.Error": "Error",
  "ResetPassword.Notification.ErrorMessage":
    "There is something wrong with the data you have entered, please try again later, or contact your church support",
  "ResetPassword.Password": "New Password",
  "ResetPassword.ConfirmPassword": "Confirm New password",

  //MODIFY BOOKING
  "ModifyBooking.Title": "Modify booking",
  "ModifyBooking.Description":
    "You can add or remove your family members form this booking, as long as it is following to the booking rules",
  "ModifyBooking.ModifyButton": "Modify",
  "ModifyBooking.CancelButton": "Cancel",
  "ModifyBooking.Notification.Success": "Success",
  "ModifyBooking.Notification.SuccessMessage": "Booking successfully modified",
  "ModifyBooking.Notification.Error": "Error",
  "ModifyBooking.Notification.ErrorMessage": "Booking modification error",

  //CANCEL BOOKING
  "CancelBooking.Message": "Are you sure you want to cancel?",
  "CancelBooking.Warning":
    "Not attending or canceling  in less than 24 hours, will prevent you from booking next weekend",
  "CancelBooking.Yes": "Yes, Cancel",
  "CancelBooking.No": "No",
  //copyright
  "Aside.Copyright": "© 2020 UAE Copts",

  "MarriageNOC.Header": "Marriage NOC Requests",
  "MarriageNOC.NoPrevious": "You don't have any previous Marriage NOC Requests",
  "MarriageNOC.DraftsSection": "In Progress",
  "MarriageNOC.OthersSection": "Previous NOCs",
  "MarriageNOC.ModalHeader": "Select Member",

  //Status
  "Status.Pending": "Pending",
  "Status.Draft": "Draft",
  "Status.NeedMoreInfo": "Need more info",
  "Status.Rejected": "Rejected",
  "Status.Ready": "Ready",
  "Status.HeadedOver": "Headed over",
  "Status.Canceled": "Canceled",

  //Marriage NOC Details
  "MarriageNOCDetails.Title": "Marriage NOC Details",
  "MarriageNOCDetails.ArabicOnly": "All information has to be in Arabic Only",
  "MarriageNOCDetails.UpdateStatus": "Update Status",
  "MarriageNOCDetails.Church": "Church",
  "MarriageNOCDetails.Status": "Status",
  "MarriageNOCDetails.PersonalInfo": "Personal info",
  "MarriageNOCDetails.FullName": "Full name",
  "MarriageNOCDetails.DateOfBirth": "Date Of Birth",
  "MarriageNOCDetails.IsCopticOrthodox": "Is Coptic Orthodox",
  "MarriageNOCDetails.IsCopticOrthodox.Yes": "Yes",
  "MarriageNOCDetails.IsCopticOrthodox.No": "No",
  "MarriageNOCDetails.PlaceOfBirth": "Place Of Birth",
  "MarriageNOCDetails.DateOfBaptism": "Date of Baptism",
  "MarriageNOCDetails.PlaceOfBaptism": "Place Of Baptism",
  "MarriageNOCDetails.EgyptNationalID": "Egypt National ID",
  "MarriageNOCDetails.EmiratesID": "Emirates ID",
  "MarriageNOCDetails.MobileNumber": "Mobile Number",
  "MarriageNOCDetails.EmailAddress": "Email Address",
  "MarriageNOCDetails.PassportNumber": "Passport Number",
  "MarriageNOCDetails.EducationDegree": "Education Degree",
  "MarriageNOCDetails.AddressInEgypt": "Address in Egypt",
  "MarriageNOCDetails.AddressInUAE": "Address in UAE",
  "MarriageNOCDetails.CurrentJob": "Current Job and Employer",
  "MarriageNOCDetails.ChurchInEgypt": "Church in Egypt",
  "MarriageNOCDetails.FatherOfConfession": "Father of Confession",
  "MarriageNOCDetails.FatherOfConfessionChurch": "Father of Confession Church",
  "MarriageNOCDetails.ConfessionFrequency": "Confession Frequency",
  "MarriageNOCDetails.CommunionFrequency": "Communion Frequency",
  "MarriageNOCDetails.UAEArrivalDate": "UAE Arrival Date",
  "MarriageNOCDetails.UAEDepartDate": "UAE Departure Date",

  "MarriageNOCDetails.PreviousEngagement": "Previous Engagement",
  "MarriageNOCDetails.HowManyTimes": "How Many Times",
  "MarriageNOCDetails.PreviousEngagement.Yes": "Yes",
  "MarriageNOCDetails.PreviousEngagement.No": "No",

  "MarriageNOCDetails.PreviousMarriage": "Previous Marriage",
  "MarriageNOCDetails.PreviousMarriage.Yes": "Yes",
  "MarriageNOCDetails.PreviousMarriage.No": "No",
  "MarriageNOCDetails.PreviousMarriageField": "Previous Marriage",
  "MarriageNOCDetails.PreviousMarriageLocation": "Previous Marriage Location",
  "MarriageNOCDetails.PreviousMarriageDate": "Previous Marriage Date",
  "MarriageNOCDetails.PreviousMarriageChurch": "Previous Marriage Church",
  "MarriageNOCDetails.PreviousMarriageFather": "Previous Marriage Father",
  "MarriageNOCDetails.CurrentStatus": "Current Status",
  "MarriageNOCDetails.WidowedDate": "Widowed Date",
  "MarriageNOCDetails.AnotherMarriageBeforeWidowed":
    "Another Marriage Before Widowed",
  "MarriageNOCDetails.AnotherMarriageBeforeWidowed.Yes": "Yes",
  "MarriageNOCDetails.AnotherMarriageBeforeWidowed.No": "No",
  "MarriageNOCDetails.PreviousSpouseName":
    "Previous Spouse Name, Date, Location",
  "MarriageNOCDetails.Date": "Date",
  "MarriageNOCDetails.Location": "Location",

  "MarriageNOCDetails.Divorcement": "Divorcement",
  "MarriageNOCDetails.IsDivorced": "Is Divorced?",
  "MarriageNOCDetails.IsDivorced.Yes": "Yes",
  "MarriageNOCDetails.IsDivorced.No": "No",
  "MarriageNOCDetails.Divorcement.Court": "Court Name",
  "MarriageNOCDetails.Divorcement.CaseNumber": "Case Number",
  "MarriageNOCDetails.Divorcement.Date": "Date",
  "MarriageNOCDetails.Divorcement.ReMarriageCertificate":
    "Re-Marriage Certificate Issuer & Date",
  "MarriageNOCDetails.Divorcement.NumberOfChildren": "Number of Children",
  "MarriageNOCDetails.Divorcement.NameAgeEtc": "Names, ages, is Baptized",
  "MarriageNOCDetails.Divorcement.RelevantKindToChildren":
    "Relevant Kind to Children",

  "MarriageNOCDetails.Witnesses": "Witnesses",
  "MarriageNOCDetails.FirstWitnessNationalID": "First Witness National ID",
  "MarriageNOCDetails.FirstWitnessName": "First Witness Name",
  "MarriageNOCDetails.SecondWitnessName": "Second Witness Name",
  "MarriageNOCDetails.SecondWitnessNationalID": "Second Witness National ID",

  "MarriageNOCDetails.HardCopy": "Hard copy",
  "MarriageNOCDetails.CancellationProof": "Cancellation Proof",
  "MarriageNOCDetails.Photo": "Photo",

  "MarriageNOCDetails.CurrentStatus.Single": "Single",
  "MarriageNOCDetails.CurrentStatus.Divorced": "Divorced",
  "MarriageNOCDetails.CurrentStatus.Widow": "Widow",

  "MarriageNOCDetails.UpdateInformation": "Update information",

  "MarriageNOCDetails.StatusUpdateSuccess": "Status was successfully updated",
  "MarriageNOCDetails.DetailsUpdateSuccess": "Details was successfully updated",

  "MarriageNOCDetails.PrevMarriageLocation.Inside": "Inside Egypt",
  "MarriageNOCDetails.PrevMarriageLocation.Outside": "Outside Egypt",

  "MarriageNOCDetails.MilitaryStatus": "Military Status",
  "MarriageNOCDetails.MilitaryStatus.NotNeeded": "Not Needed",
  "MarriageNOCDetails.MilitaryStatus.Done": "Service Done",
  "MarriageNOCDetails.MilitaryStatus.TempExemption": "Temp Exemption",

  "MarriageNOCDetails.SaveDraft": "Save Draft",
  "MarriageNOCDetails.Submit": "Submit",

  "MarriageNOCDetails.SuccessfullyUpdate": "Successfully updated",
  "MarriageNOCDetails.SuccessfullySubmitted": "Successfully submitted",

  "MarriageNOCDetails.UploadDocuments": "Upload Documents",
  "MarriageNOCDetails.UploadDocumentsHint": "Required: Documents" +
    "\n Passport Copy" +
    "\n Visa Copy" +
    "\n Egypt National ID (for Egyptians)" +
    "\n Emirates ID Copy",
  "MarriageNOCDetails.Documents": "Documents",

  //MarriageNOC list page
  "MarriageNOC.Create": "Create new Request",

  //Errors
  "MarriageNOC.Notification.Error": "Error",
  "MarriageNOC.Notification.ErrorMessage": "There is something happened, please retry again or contact church secretary",
  "marriage-noc.draft.member-already-has-draft": "This member already has a draft NOC, please edit it instead of creating new one"
};
