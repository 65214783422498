import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledBookingCardCol = styled.div`
    padding: 0 0 20px 32px;
       
    @media ${DEVICE.mobileDevices} {
		width: 100%;
	}
`

export default StyledBookingCardCol;


