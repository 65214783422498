import styled from "styled-components";

const StyledModalTitle = styled.h2`
  font-family: var(--fontProximaBold);
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  color: ${({ isPrivate }) => {
    return isPrivate ? "" : "#ffffff";
  }};
  padding: 0;
`;
export default StyledModalTitle;
