import styled from 'styled-components';
import bodyBg from '../../../assets/img/bgbodytext.png';

const StyledAutorizeLayout = styled.div`
	position: relative;
	background: var(--bgLight);
	min-height: 100vh;
	overflow-x: hidden;
	
	&:after {	
		content: '';
		position: absolute;
		width: 70%;
		height: 10vh;
		bottom: 0;
		left: 15%;
		overflow: hidden;
		z-index: 0;
		background-image: url(${bodyBg});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
	}
  
`
export default StyledAutorizeLayout;
