import styled from 'styled-components';

const StyledLiturgyItemBottom = styled.div`
  padding: 16px 23px 16px 32px;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
  &>div {
    margin-bottom: 0;
  }
`
export default StyledLiturgyItemBottom;