import styled from 'styled-components'

const StyledPhotoSectionText = styled.div`
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color:var(--gray);
  padding-top: 12px;
  padding-bottom: 5px;
`
export default StyledPhotoSectionText;


