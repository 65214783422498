import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  BOOK_EVENT,
  BOOK_EVENT_FAILURE,
  BOOK_EVENT_SUCCESS,
  CANCEL_BOOKING,
  CANCEL_BOOKING_FAILURE,
  CANCEL_BOOKING_SUCCESS,
  GET_MY_BOOKINGS,
  GET_MY_BOOKINGS_FAILURE,
  GET_MY_BOOKINGS_SUCCESS,
  MODIFY_BOOKING,
  MODIFY_BOOKING_FAILURE,
  MODIFY_BOOKING_SUCCESS,
} from "./bookings.action";

import {
  bookEventRequest,
  cancelBookingRequest,
  modifyBookingRequest,
  myBookingsRequest,
} from "./bookings.api";
import { isUrlMatch } from "../router/router.utils";
import { urlLocations } from "../../routes/urlLocations";
import { SET_MODAL_STATUS } from "../modals/modals.action";
import openNotification from "../../utils/notifications";
import { GET_EVENTS } from "../events/events.action";
import { SET_LOADER_STATUS } from "../loader/loader.action";
import { LOADERS } from "../../constants/constants";
import { SET_GENERAL_LOADER_STATUS } from "../generalLoader/generalLoader.action";
import { CHECK_EVENT_AVAILABILITY } from "../eventAvailability/eventAvailability.action";

function* getBookingsOnLocationChangeData({ payload }) {
  try {
    yield delay(500);

    const bookings = yield select((state) => state.bookings);
    if (
      (isUrlMatch(payload, urlLocations.liturgyInfo) ||
        isUrlMatch(payload, urlLocations.booking) ||
        isUrlMatch(payload, urlLocations.privateEvent)) &&
      !bookings
    ) {
      yield put({ type: GET_MY_BOOKINGS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchBookings() {
  try {
    yield put({
      type: SET_GENERAL_LOADER_STATUS,
      payload: 1,
    });
    const { response } = yield call(myBookingsRequest);

    if (response.status === 200) {
      yield put({
        type: GET_MY_BOOKINGS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_MY_BOOKINGS_FAILURE,
      });
    }
  } catch (error) {
    yield put({ type: GET_MY_BOOKINGS_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: SET_GENERAL_LOADER_STATUS,
      payload: -1,
    });
  }
}

function* bookEvent({ payload }) {
  try {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.bookingEvent,
        status: true,
      },
    });
    const { response } = yield call(bookEventRequest, { ...payload });

    if (response.status === 200) {
      yield put({
        type: BOOK_EVENT_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: SET_MODAL_STATUS,
        payload: null,
      });
      yield put({
        type: CHECK_EVENT_AVAILABILITY,
        payload: {
          id: response.data.event.id,
        },
      });
      yield put({
        type: GET_MY_BOOKINGS,
      });
      yield put({
        type: GET_EVENTS,
      });

      yield call(openNotification, {
        message: "LiturgyPage.Notification.Success",
        description: "LiturgyPage.Notification.SuccessMessage",
        type: "success",
      });
    } else {
      if (response.data.data.event) {
        yield call(openNotification, {
          message: "LiturgyPage.Notification.Error",
          description: response.data.data.event,
          type: "error",
        });
      } else {
        yield call(openNotification, {
          message: "LiturgyPage.Notification.Error",
          description: "LiturgyPage.Notification.ErrorMessage",
          type: "error",
        });
      }
      yield put({
        type: BOOK_EVENT_FAILURE,
      });
    }
  } catch (error) {
    yield put({ type: BOOK_EVENT_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.bookingEvent,
        status: false,
      },
    });
  }
}

function* cancelBooking({ payload: id }) {
  try {
    const { response } = yield call(cancelBookingRequest, id);

    if (response.status === 200) {
      yield put({
        type: CANCEL_BOOKING_SUCCESS,
      });
      yield call(openNotification, {
        message: "BookingsPage.Notification.Cancel.Success",
        description: "BookingsPage.Notification.Cancel.SuccessMessage",
        type: "success",
      });
      yield put({
        type: SET_MODAL_STATUS,
        payload: null,
      });
      yield put({
        type: GET_MY_BOOKINGS,
      });
    } else {
      yield put({
        type: CANCEL_BOOKING_FAILURE,
      });
    }
  } catch (error) {
    yield put({ type: CANCEL_BOOKING_FAILURE });
    console.log(error);
  }
}

function* modifyBooking({ payload: { event, bookedMembers } }) {
  try {
    const { response } = yield call(modifyBookingRequest, {
      event,
      bookedMembers,
    });

    if (response.status === 200) {
      yield put({
        type: MODIFY_BOOKING_SUCCESS,
      });
      yield call(openNotification, {
        message: "ModifyBooking.Notification.Success",
        description: "ModifyBooking.Notification.SuccessMessage",
        type: "success",
      });
      yield put({
        type: SET_MODAL_STATUS,
        payload: null,
      });
      yield put({
        type: GET_MY_BOOKINGS,
      });
    } else {
      if (response.data.data.event) {
        yield call(openNotification, {
          message: "LiturgyPage.Notification.Error",
          description: response.data.data.event,
          type: "error",
        });
      } else {
        yield call(openNotification, {
          message: "LiturgyPage.Notification.Error",
          description: "LiturgyPage.Notification.ErrorMessage",
          type: "error",
        });
      }
      yield put({
        type: MODIFY_BOOKING_FAILURE,
      });
    }
  } catch (error) {
    yield put({ type: MODIFY_BOOKING_FAILURE });
    console.log(error);
  }
}

export default function* bookingSaga() {
  yield takeLatest(LOCATION_CHANGE, getBookingsOnLocationChangeData);
  yield takeLatest(GET_MY_BOOKINGS, fetchBookings);
  yield takeLatest(BOOK_EVENT, bookEvent);
  yield takeLatest(CANCEL_BOOKING, cancelBooking);
  yield takeLatest(MODIFY_BOOKING, modifyBooking);
}
