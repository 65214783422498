import React from "react";

import Header from "../../components/Header/Header.jsx";
import {useDispatch, useSelector} from "react-redux";
import {getMarriageNOCsSelector} from "../../services/marriageNoc/marriageNoc.selector";
import MarriageNocCard from "./MarriageNoc.Card";
import Styled from "./styled";
import Button from "../../components/Button/Button";
import {FormattedMessage} from "react-intl";
import MarriageNocModal from "./MarriageNoc.Modal";
import {familySelector} from "../../services/family/family.selector";
import {setModalStatusAction} from "../../services/modals/modals.action";
import {MODALS_ID, STATUS} from "../../constants/constants";
import {createMarriageNocDraftAction} from "../../services/marriageNoc/marriageNoc.actions";
import StyledPageTitle from "../styled/StyledPageTitle";
import partition from 'lodash/partition';
import MarriageNocDetailsSection from "../MarriageNOCDetails/MarriageNOCDetails.Section";
import StyledEmptyContent from "../styled/StyledEmptyContent";

const MarriageNoc = React.memo(() => {
  const marriageNocs = useSelector(getMarriageNOCsSelector);
  const userFamily = useSelector(familySelector);
  const dispatch = useDispatch();

  const nocs = partition(marriageNocs, (item) => item.status === STATUS.DRAFT || item.status === STATUS.PENDING || item.status === STATUS.NEED_MORE_INFO)

  const isModalShown =
    userFamily?.spouse ||
    userFamily?.grandParents?.length ||
    userFamily.children?.length;

  const handleButtonClick = () => {
    if (isModalShown) {
      dispatch(setModalStatusAction(MODALS_ID.MARRIAGE_NOC_MODAL));
      return;
    }
    dispatch(
      createMarriageNocDraftAction({member: userFamily.mainMember.id})
    );
  };

  return (
    <>
      <Header logo/>
      <Styled.Container>
        <Styled.HeaderRow>
          <StyledPageTitle>
            <FormattedMessage id={"MarriageNOC.Header"}/>
          </StyledPageTitle>
          <Button onClick={handleButtonClick}>
            <FormattedMessage id={"MarriageNOC.Create"}/>
          </Button>
        </Styled.HeaderRow>
        {!marriageNocs || marriageNocs.length === 0 && <StyledEmptyContent>
          <FormattedMessage id={"MarriageNOC.NoPrevious"}/>
        </StyledEmptyContent>}
        {nocs[0] && nocs[0].length > 0 &&
          <MarriageNocDetailsSection title={"MarriageNOC.DraftsSection"}>
            <Styled.List>
              {nocs[0]?.map((marriageNoc) => (
                <MarriageNocCard {...marriageNoc} key={marriageNoc?.id}/>
              ))}
            </Styled.List>
          </MarriageNocDetailsSection>
        }
        {nocs[1] && nocs[1].length > 0 &&
        <MarriageNocDetailsSection title={"MarriageNOC.OthersSection"}>
          <Styled.List>
            {nocs[1]?.map((marriageNoc) => (
              <MarriageNocCard {...marriageNoc} key={marriageNoc?.id}/>
            ))}
          </Styled.List>
        </MarriageNocDetailsSection>
        }
      </Styled.Container>
      <MarriageNocModal/>
    </>
  );
});

export default MarriageNoc;
