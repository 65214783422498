import React, { useState, useEffect } from "react";

import LiturgyAside from "./LiturgyAside/LiturgyAside";
import LiturgyList from "./LiturgyList/LiturgyList";
import LiturgyModal from "./LiturgyModal/LiturgyModal";
import Aside from "../../components/Aside/Aside";
import Header from "../../components/Header/Header";
import StyledPageContent from "../styled/StyledPageContent";
import StyledPageMainInfo from "../styled/StyledPageMainInfo";
import StyledPageLayout from "../styled/StyledPageLayout";
import StyledCollapseIcon from "../styled/StyledCollapseIcon";
import LiturgyBookedModal from "./LiturgyBookedModal/LiturgyBookedModal";

const LiturgyPage = () => {
  const [selectedEvent, selectEvent] = useState(null);
  const [isCollapse, setCollapse] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onCollapse = () => {
    setCollapse(!isCollapse);
  };

  return (
    <StyledPageLayout>
      <Aside
        trigger={true}
        collapsible
        collapsed={isCollapse}
        onCollapse={onCollapse}
      >
        <LiturgyAside onCollapse={onCollapse} />
      </Aside>
      <StyledCollapseIcon onClick={onCollapse}></StyledCollapseIcon>

      <StyledPageContent collapsed={isCollapse}>
        <Header logo={false} />

        <StyledPageMainInfo>
          <LiturgyList selectEvent={selectEvent} />
          {selectedEvent && (
            <LiturgyModal
              selectEvent={selectEvent}
              selectedEvent={selectedEvent}
            />
          )}
        </StyledPageMainInfo>
      </StyledPageContent>
      <LiturgyBookedModal />
    </StyledPageLayout>
  );
};

export default LiturgyPage;
