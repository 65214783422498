import styled from 'styled-components'


const StyledLangControl = styled.div`
{
  position: ${({ posType }) => posType ? posType : 'flex'};
  top: 40px;
  right: 40px;
  width: 167px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #d6e1e9;
  margin-right: 16px;
}
`

export default StyledLangControl;


