import React from "react";
import ModalWrapper from "../../hoc/withModal";
import { MODALS_ID } from "../../constants/constants";
import MarriageNocModalContent from "./MarriageNoc.ModalContent";

const MarriageNocModal = () => {
  return (
    <ModalWrapper
      modalId={MODALS_ID.MARRIAGE_NOC_MODAL}
      component={MarriageNocModalContent}
    />
  );
};

export default MarriageNocModal;
