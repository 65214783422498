import styled from 'styled-components';

const StyledAsideCopyright = styled.p`
	position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 13px;
    line-height: 1.23;
    letter-spacing: normal;
    color: #77869f;
`
export default StyledAsideCopyright;