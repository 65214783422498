import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import isArray from 'lodash/isArray';
import isEmpty from "lodash/isEmpty";

import notification from '../../../../utils/notifications'
import CheckboxField from "../../../../components/CheckboxField/CheckboxField";
import {bookEventAction, modifyBookingAction} from "../../../../services/bookings/bookings.action";
import {prepareMemberIds} from "../../../../services/events/events.utils";
import { createStructuredSelector } from "reselect";
import {eventAvailabilitySelector} from "../../../../services/eventAvailability/eventAvailability.selector";
import StyledModalForm from "../../../LiturgyPage/LiturgyModal/LiturgyModalForm/styled/StyledModalForm";
import StyledLiturgyBookingMembers
    from "../../../LiturgyPage/LiturgyModal/LiturgyModalForm/styled/StyledLiturgyBookingMembers";
import StyledLiturgyBookingReason
    from "../../../LiturgyPage/LiturgyModal/LiturgyModalForm/styled/StyledLiturgyBookingReason";
import {getLocalizedKey} from "../../../../utils/utils";
import StyledModalFooter from "../../../LiturgyPage/LiturgyModal/LiturgyModalForm/styled/StyledModalFooter";
import StyledModalButtonClose from "../../../LiturgyPage/LiturgyModal/LiturgyModalForm/styled/StyledModalButtonClose";
import {FormattedMessage} from "react-intl";
import StyledModalButtonBook from "../../../LiturgyPage/LiturgyModal/LiturgyModalForm/styled/StyledModalButtonBook";
import {setModalStatusAction} from "../../../../services/modals/modals.action";
import Styled from './styled';

const BookingPageModifyForm = ({
    handleSubmit,
    members,
    bookedMembers,
    availability,
    setModalStatus,
    ...form
}) => {

const preparedBookedMembers =
    isArray(bookedMembers) ? bookedMembers : [bookedMembers];

return (
    <StyledModalForm>
        <Styled.Content>
        <form onSubmit={handleSubmit}>
            <h1>
                <FormattedMessage
                    id="ModifyBooking.Title"
                />
            </h1>
            <div>
                <FormattedMessage
                    id="ModifyBooking.Description"
                />
            </div>
            <br/>
            {
                members?.map((member) => (
                    member?.id && <StyledLiturgyBookingMembers key={member?.id}>
                        <CheckboxField
                            defaultChecked={preparedBookedMembers?.some(
                                bookedMember => bookedMember &&  bookedMember?.toString() === member.id?.toString()
                            )}
                            disabled={!availability[member?.id]?.canBook}
                            name={`preparedBookedMembers."${member?.id}"`}
                        >
                            {member?.firstName}
                        </CheckboxField>

                        <StyledLiturgyBookingReason>
                            { availability[member?.id]?.[getLocalizedKey('reason')] }
                        </StyledLiturgyBookingReason>

                    </StyledLiturgyBookingMembers>
                ))
            }

            <StyledModalFooter>
                <StyledModalButtonClose onClick={() => setModalStatus(null)}>
                    <FormattedMessage
                        id="ModifyBooking.CancelButton"
                    />
                </StyledModalButtonClose>

                <StyledModalButtonBook
                    htmlType="submit"
                >
                    <FormattedMessage
                        id="ModifyBooking.ModifyButton"
                    />
                </StyledModalButtonBook>

            </StyledModalFooter>
        </form>
        </Styled.Content>
    </StyledModalForm>
)
};

export default compose(
    connect(
        createStructuredSelector({
            availability: eventAvailabilitySelector,
        }),
        {
            setModalStatus: setModalStatusAction
        }
    ),
    reduxForm({
        form: "bookingModifyForm",
        enableReinitialize: true,
        onSubmit: ({ preparedBookedMembers = {} }, dispatch, { eventId, initialValues }) => {
            !isEmpty(prepareMemberIds({...initialValues, ...preparedBookedMembers })) ?
            dispatch(modifyBookingAction({
                event: eventId,
                bookedMembers: prepareMemberIds({...initialValues, ...preparedBookedMembers })
            })) : notification({
                type: "error",
                message: "ModifyBooking.Notification.Error",
                description: "ModifyBooking.Notification.ErrorMessage"
            })
        }
    })
)
(BookingPageModifyForm)