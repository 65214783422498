import styled from 'styled-components'

const StyledLogo = styled.div`
	width: 140px;
	height: 40px;
	
	img {
		display: block;
	}
`;

export default StyledLogo;