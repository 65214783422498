import styled from 'styled-components';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
      
	  border-radius: 10px;
	  box-shadow: 0 0 10px 0 rgba(139, 155, 163, 0.1);
	  border: solid 1px var(--lightGrey);
	  background-color: var(--white);
	  position: relative;
	  margin-bottom: 8px;
	  
	 

`
export default StyledPanel;