import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { history, urlLocations } from "../../routes/urlLocations";
import StyledHomePage from './styled/StyledHomePage';
import StyledHomePageCol from './styled/StyledHomePageCol';
import StyledDashboardList from './styled/StyledDashboardList';
import StyledDashboardItem from './styled/StyledDashboardItem';
import StyledDashboardItemTitle from './styled/StyledDashboardItemTitle';
import StyledDashboardItemDescription from './styled/StyledDashboardItemDescription';
import StyledHomePageBottom from './styled/StyledHomePageBottom';
import StyledHomePageBottomTitle from './styled/StyledHomePageBottomTitle';
import iconFirst from "../../assets/icons/EditMyfamilyinfo.svg";
import iconSecond from "../../assets/icons/LiturgyBookings.svg";
import iconThird from "../../assets/icons/MyBookings.svg";
import StyledDashboardIcon from "./styled/StyledDashboardIcon";
import StyledDashboardItemContent from "./styled/StyledDashboardItemContent";
import StyledHomePageText from "./styled/StyledHomePageText";
import StyledDashboardContainer from './styled/StyledDashboardContainer';
import Header from "../../components/Header/Header.jsx";
import {createStructuredSelector} from "reselect";
import {familySelector} from "../../services/family/family.selector";
import { isFamilyComplete } from '../../services/family/family.utils';

const HomePage = ({ family }) => {
    useEffect(() => {
        family.id && !isFamilyComplete(family) && history.push(urlLocations.personalInfo)
    }, [family]);
    return(
    <StyledHomePage>

        <Header logo={true} />

        <StyledDashboardContainer>
            <StyledDashboardList>

                <StyledDashboardItem color="dashboardItemBg1">
                    <Link to={ urlLocations.personalInfo }>
                        <StyledDashboardIcon color="dashboardIconBg1"><img alt={""} src={iconFirst}/></StyledDashboardIcon>

                        <StyledDashboardItemContent>
                            <StyledDashboardItemTitle>
                                <FormattedMessage id="HomePage.Family.Title" />
                            </StyledDashboardItemTitle>

                            <StyledDashboardItemDescription>
                                <FormattedMessage id="HomePage.Family.Desc" />
                            </StyledDashboardItemDescription>
                        </StyledDashboardItemContent>
                    </Link>
                </StyledDashboardItem>

                <StyledDashboardItem color='dashboardItemBg2'>
                    <Link to={urlLocations.liturgy}>
                        <StyledDashboardIcon color="dashboardIconBg2">
                            <img alt={""} src={iconSecond}/>
                        </StyledDashboardIcon>
                        <StyledDashboardItemContent>
                            <StyledDashboardItemTitle>
                                <FormattedMessage id="HomePage.Liturgy.Title"/>
                            </StyledDashboardItemTitle>

                            <StyledDashboardItemDescription>
                                <FormattedMessage id="HomePage.Liturgy.Desc"/>
                            </StyledDashboardItemDescription>
                        </StyledDashboardItemContent>
                    </Link>
                </StyledDashboardItem>

                <StyledDashboardItem color='dashboardItemBg3'>
                    <Link to={ urlLocations.booking }>
                        <StyledDashboardIcon color="dashboardIconBg3"><img  alt={""} src={iconThird}/></StyledDashboardIcon>

                        <StyledDashboardItemContent>
                            <StyledDashboardItemTitle>
                                <FormattedMessage id="HomePage.Booking.Title" />
                            </StyledDashboardItemTitle>
                            <StyledDashboardItemDescription>
                                <FormattedMessage id="HomePage.Booking.Desc" />
                            </StyledDashboardItemDescription>
                        </StyledDashboardItemContent>
                    </Link>
                </StyledDashboardItem>

                <StyledDashboardItem color='dashboardItemBg4'>
                    <Link to={ urlLocations.marriageNoc }>
                        <StyledDashboardIcon color="dashboardIconBg4"><img  alt={""} src={iconThird}/></StyledDashboardIcon>

                        <StyledDashboardItemContent>
                            <StyledDashboardItemTitle>
                                <FormattedMessage id="HomePage.Others.Title" />
                            </StyledDashboardItemTitle>
                            <StyledDashboardItemDescription>
                                <FormattedMessage id="HomePage.Others.Desc" />
                            </StyledDashboardItemDescription>
                        </StyledDashboardItemContent>
                    </Link>
                </StyledDashboardItem>

            </StyledDashboardList>

            {/*<StyledHomePageBottomTitle>*/}
            {/*    <FormattedMessage id="HomePage.Bottom.Title" />*/}
            {/*</StyledHomePageBottomTitle>*/}

            {/*<StyledHomePageBottom>*/}
            {/*    <StyledHomePageCol>*/}
            {/*        <StyledHomePageText>*/}
            {/*            <FormattedMessage id="HomePage.Bottom.Desc" />*/}
            {/*        </StyledHomePageText>*/}
            {/*    </StyledHomePageCol>*/}

            {/*    <StyledHomePageCol>*/}
            {/*        <StyledHomePageText>*/}
            {/*            <FormattedMessage id="HomePage.Bottom.Desc2" defaultMessage="  "/>*/}
            {/*        </StyledHomePageText>*/}
            {/*    </StyledHomePageCol>*/}

            {/*</StyledHomePageBottom>*/}
        </StyledDashboardContainer>

    </StyledHomePage>)
};

export default connect(
    createStructuredSelector({
        family: familySelector
    })
)(HomePage);
