import styled from 'styled-components';

const StyledFormBottom = styled.div`
	border-top: 1px solid var(--lightGrey);
	padding-top: 28px;
	font-size: 13px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	color: var(--gray);
	margin-top: 28px;
	text-align: center;
	
	a {
		font-size: 13px;
		color: var(--hrefColorSecondary);
	}
`
export default StyledFormBottom;