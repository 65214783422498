import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { compose } from 'redux'
import { createStructuredSelector } from "reselect";
import { withRouter } from 'react-router'
import groupBy from 'lodash/groupBy';

import { appInfoChurchesSelector } from "../../../services/appInfo/appInfo.selector";
import { history, urlLocations } from "../../../routes/urlLocations";
import {getLocalizedKey} from "../../../utils/utils";
import {getChurchLiturgySelector, getLiturgiesSelector} from "../../../services/events/events.selector";
import {FormattedMessage} from "react-intl";
import StyledAsideItem from '../../styled/StyledAsideItem';
import StyledLiturgyAside from './styled/StyledLiturgyAside';
import StyledLiturgyAsideImg from './styled/StyledLiturgyAsideImg';
import StyledLiturgyAsideName from './styled/StyledLiturgyAsideName';
import StyledLiturgyAsideAmount from './styled/StyledLiturgyAsideAmount';

const LiturgyAside = ({
    churches,
    liturgy,
    liturgies,
	onCollapse,
    match: { params: { id } }
}) => {

    const sortedChurches = churches?.sort((a, b) => a.id - b.id);

    useEffect(() => {
        const firstChurchId = churches?.[0]?.id;
        !!firstChurchId && history.push(
            `${urlLocations.liturgy}/${firstChurchId}`
        )
    }, [churches]);

    return (
        <StyledLiturgyAside>
            {
                sortedChurches && sortedChurches?.map(church =>
                {

                    const churchLiturgiesCount = groupBy(liturgies, 'church.id')[church.id]?.length || 0;
                    return (
                        <StyledAsideItem
	                        onClick={()=> onCollapse()}
                            type="secondary"
	                        isactive={String(church?.id?.toString() === id?.toString())}
                            key={church?.id}
                            to={`${urlLocations.liturgy}/${church.id}`} >
                            <StyledLiturgyAsideImg width={100} src={church?.logo?.url} alt=""/>

                                <div>
                                    <StyledLiturgyAsideName>
                                        {church[getLocalizedKey('name')] }
                                    </StyledLiturgyAsideName>

                                    <StyledLiturgyAsideAmount>
                                        { church.emirate[getLocalizedKey('name')] } - {" "}
                                        {churchLiturgiesCount} <FormattedMessage
                                        id={"LiturgyPage.Aside.Count"}
                                        values={{ count:  churchLiturgiesCount}}
                                        />
                                    </StyledLiturgyAsideAmount>
                                </div>
                        </StyledAsideItem>
                )})
            }
        </StyledLiturgyAside>
    )
};

export default compose(
    withRouter,
    connect(
        createStructuredSelector({
            churches: appInfoChurchesSelector,
            liturgy: (state, {  match : { params: { id } }}) =>
                getChurchLiturgySelector(state, id),
            liturgies: getLiturgiesSelector
        })
    )
)(LiturgyAside)