import styled from 'styled-components';
import goBack from '../../../assets/icons/goback.svg';

const StyledGoBackBtn = styled.div`
	width: 144px;
	height: 32px;
	border-radius: 10px;
	border: 1px solid var(--lightGrey);
	font-size: 13px;
	text-align: center;
	color: #4164da;
	padding-top: 6px;
	position: relative;
	margin-bottom: 30px;
	text-align:  center;
	margin-top: 40px;
	a {
		font-size: 13px;
		font-family: var(--fontProximaRegular);
		color: var(--uploadBtn);
		&:hover {
		    color: var(--uploadBtn);
		}
	}
	
	&:before {
		content: '';
		transform: rotate(90deg);
		width: 24px;
		height: 24px;
		left: 8px;
		top: 3px;
		position: absolute;
		background: url(${goBack}) center center no-repeat;
		
	}

`

export default StyledGoBackBtn;


