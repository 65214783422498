import React from 'react';
import {Spin} from "antd"
import {FormattedMessage} from "react-intl";
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";

import {selectFile} from '../../../services/file/file.selector';
import FileUploader from "../FieldsSections/FileUploadSection";
import {removeFileAction} from "../../../services/file/file.action";
import StyledPhotoSectionImg from './styled/StyledPhotoSectionImg';
import StyledPhotoSectionCol from './styled/StyledPhotoSectionCol';
import StyledPhotoSection from './styled/StyledPhotoSection';
import StyledPhotoSectionImgContent from './styled/StyledPhotoSectionImgContent';
import {isFileUploadingSelector} from "../../../services/filesUploader/filesUploader.selector";

const PhotoSection = ({photo, name, formName, isUploading, isEditable = true}) => (
  <Spin spinning={!!isUploading}>
    <StyledPhotoSection>
      <StyledPhotoSectionImg>
        <StyledPhotoSectionImgContent>
          {photo ? <img src={photo?.[0]?.url} alt=""/> : <div></div>}
        </StyledPhotoSectionImgContent>
      </StyledPhotoSectionImg>

      {isEditable &&
        <StyledPhotoSectionCol>
          <FileUploader formName={formName} name={name} description={"FamilyPage.Form.PhotoInfo"}>
            <FormattedMessage id="FamilyPage.Form.UploadPhoto"/>
          </FileUploader>
        </StyledPhotoSectionCol>
      }
    </StyledPhotoSection>
  </Spin>
);


export default connect(
  createStructuredSelector({
    isUploading: (state, {name, formName}) => isFileUploadingSelector(state, formName, name),
    photo: (state, {name, formName}) => selectFile(state, name, formName),
  }),
  {removeFile: removeFileAction}
)(PhotoSection)
