import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import isString from "lodash/isString";

import { setFamilyAddressRequest } from "../family/family.api";
import {
  SET_FAMILY_ADDRESS,
  SET_FAMILY_ADDRESS_FAILURE,
  SET_FAMILY_ADDRESS_SUCCESS
} from "../address/address.action";
import openNotification from "../../utils/notifications";
import { SET_ERRORS } from "../errors/errors.action";
import { GET_FAMILY_INFO } from "../family/family.action";
import { history, urlLocations } from "../../routes/urlLocations";

function* setFamilyAddress({ payload }) {
  try {
    const geoLocation = yield select(state => state.geoLocation);


    const { response } = yield setFamilyAddressRequest({
      ...payload,
      address: {
        addressLine: payload.addressLine,
        areaInfo: payload?.areaInfo
      },
      geoLocation: {
        ...geoLocation
      }
    });
    if (response.status === 200) {
      yield put({ type: SET_FAMILY_ADDRESS_SUCCESS });
      yield put({ type: GET_FAMILY_INFO });
      yield delay(500);
      history.push(urlLocations.home);
      yield call(openNotification, {
        message: "FamilyPage.Address.Success",
        description: "FamilyPage.Address.SuccessMessage",
        type: "success"
      });
    } else {
      yield put({
        type: SET_ERRORS,
        payload: response.data.data
      });
      yield call(openNotification, {
        message: "FamilyPage.Address.Error",
        description: "FamilyPage.Address.ErrorMessage",
        type: "error"
      });
      yield put({ type: SET_FAMILY_ADDRESS_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* addressSaga() {
  yield takeLatest(SET_FAMILY_ADDRESS, setFamilyAddress);
}
