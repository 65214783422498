import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";
import { matchPath } from "react-router";

import { CLEAR_ERRORS, SET_ERRORS } from "../errors/errors.action";
import { prepareFilesDataForSave } from "../file/file.utils";
import { setFamilyParentRequest } from "../parents/parents.api";
import {
  SET_FAMILY_PARENT,
  SET_FAMILY_PARENT_FAILURE,
  SET_FAMILY_PARENT_SUCCESS
} from "../parents/parents.action";
import openNotification from "../../utils/notifications";
import { isUrlMatch } from "../router/router.utils";
import { history, urlLocations } from "../../routes/urlLocations";
import { uploadFilesUtil } from "../family/family.utils";
import { CLEAR_FILES } from "../file/file.action";
import { LOCATION_CHANGE } from "connected-react-router";
import { GET_FAMILY_INFO } from "../family/family.action";
import {
  SET_NO_PARENTS,
  SET_NO_PARENTS_FAILURE,
  SET_NO_PARENTS_SUCCESS
} from "./parents.action";
import { setNoParentsRequest } from "./parents.api";

function* setFamilyParent({ payload }) {
  try {
    yield put({ type: CLEAR_ERRORS });
    const files = yield select(state => state.files);
    const prepareFiles = prepareFilesDataForSave(files.grandParents);

    const { response } = yield setFamilyParentRequest({
      parent: { ...payload, ...prepareFiles }
    });
    if (response.status === 200) {
      yield put({ type: SET_FAMILY_PARENT_SUCCESS });
      yield put({ type: GET_FAMILY_INFO });
      history.push(urlLocations.parentsList);
      yield call(openNotification, {
        message: "FamilyPage.Parent.Success",
        description: "FamilyPage.Parent.SuccessMessage",
        type: "success"
      });
    } else {
      yield put({
        type: SET_ERRORS,
        payload: response.data.data
      });
      yield call(openNotification, {
        message: "FamilyPage.Parent.Error",
        description: "FamilyPage.Parent.ErrorMessage",
        type: "error"
      });
      yield put({ type: SET_FAMILY_PARENT_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

function* setParentFilesOnLocationChange({ payload }) {
  try {
    yield delay(500);
    const parents = yield select(state => state.family.grandParents);

    if (isUrlMatch(payload, urlLocations.parentInfo) && !isEmpty(parents)) {
      const id = matchPath(payload.location.pathname, {
        path: urlLocations.parentInfo,
        exact: true
      }).params?.id;

      const foundParent = parents.find(parent => parent.id.toString() === id);
      yield uploadFilesUtil({ grandParents: foundParent }, "grandParents");
    } else {
      yield put({
        type: CLEAR_FILES,
        payload: {
          formName: "grandParents"
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* setNoParents() {
  try {
    const { response } = yield setNoParentsRequest({
      grandParents: null,
      numberOfGrantParents: 0
    });
    if (response.status === 200) {
      yield put({ type: SET_NO_PARENTS_SUCCESS });
      yield put({ type: GET_FAMILY_INFO });
    } else {
      yield put({ type: SET_NO_PARENTS_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* parentsSaga() {
  yield takeEvery(SET_FAMILY_PARENT, setFamilyParent);
  yield takeEvery(SET_NO_PARENTS, setNoParents);
  yield takeEvery(LOCATION_CHANGE, setParentFilesOnLocationChange);
}
