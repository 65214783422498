import styled from 'styled-components'


const StyledFamilyAside = styled.div`
    
`

export default StyledFamilyAside;


