import styled from 'styled-components'

const StyledAgreement= styled.p`
	  font-family: var(--fontProximaBold);
	  font-size: 15px;
	  font-weight: bold;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.6;
	  letter-spacing: normal;
	  color: var(--dark);
	  display: inline-block;
	  a {
	        font-family: inherit;
	   }
`;

export default StyledAgreement;