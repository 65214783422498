import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import isEmpty from "lodash/isEmpty";
import { LoadingOutlined } from "@ant-design/icons";

import CheckboxField from "../../../../components/CheckboxField/CheckboxField";
import { familyMembersSelector } from "../../../../services/family/family.selector";
import { prepareMemberIds } from "../../../../services/events/events.utils";
import { eventAvailabilitySelector } from "../../../../services/eventAvailability/eventAvailability.selector";
import { getLocalizedKey } from "../../../../utils/utils";
import { bookEventAction } from "../../../../services/bookings/bookings.action";
import { getBookingByEventIdSelector } from "../../../../services/bookings/bookings.selector";
import { FormattedMessage } from "react-intl";
import StyledModalFooter from "./styled/StyledModalFooter";
import StyledModalButtonBook from "./styled/StyledModalButtonBook";
import StyledModalButtonClose from "./styled/StyledModalButtonClose";
import StyledLiturgyBookingMembers from "./styled/StyledLiturgyBookingMembers";
import StyledModalForm from "./styled/StyledModalForm";
import StyledLiturgyBookingReason from "./styled/StyledLiturgyBookingReason";
import { setModalStatusAction } from "../../../../services/modals/modals.action";
import { isLoadingSelector } from "../../../../services/loader/loader.selector";
import { LOADERS } from "../../../../constants/constants";
import {Spin} from "antd";
import StyledModalLoading from "./styled/StyledModalLoading";

const LiturgyModalForm = ({
  handleSubmit,
  setModalStatus,
  members,
  availability,
  booking,
  isLoading,
  liturgy,
  isPrivate,
  isLoadingAvailability,
}) => {
  const isButtonDisabled =
    !Object.values(availability)?.some((member) => member && member?.canBook) ||
    isLoading || isLoadingAvailability ||
    liturgy.bookedCount >= liturgy.maxCapacity;

  const bookedMembers = booking?.reduce(
    (acc, bookingItem) => [
      ...acc,
      ...(bookingItem?.bookedMembers ? bookingItem?.bookedMembers : []),
    ],
    []
  );

  const setButtonName = ({ bookedCount, maxCapacity }) => {
    if (bookedCount >= maxCapacity) {
      return "LiturgyPage.Button.FullyBooked";
    } else if (!isEmpty(booking)) {
      return "LiturgyPage.Button.Booked";
    }
    return "LiturgyPage.Button.Book";
  };

  return (
    <StyledModalForm>

      <form onSubmit={handleSubmit}>
          {isLoadingAvailability ?
              <StyledModalLoading>
                  <Spin spinning={isLoadingAvailability}/>
                  <FormattedMessage id="LiturgyPage.Modal.Loading" />
              </StyledModalLoading>

              : members?.map(
              (member) =>
                  member?.id && (
                      <StyledLiturgyBookingMembers key={member?.id}>
                          <CheckboxField
                              defaultChecked={bookedMembers?.some(
                                  (bookedMember) =>
                                      bookedMember &&
                                      bookedMember?.toString() === member.id?.toString()
                              )}
                              disabled={!availability[member?.id]?.canBook}
                              name={`bookedMembers."${member?.id}"`}
                          >
                              {member?.firstName}
                          </CheckboxField>

                          <StyledLiturgyBookingReason>
                              {availability[member?.id]?.[getLocalizedKey("reason")]}
                          </StyledLiturgyBookingReason>
                      </StyledLiturgyBookingMembers>
                  )
          )}

        <StyledModalFooter isPrivate={isPrivate}>
          {!isPrivate && (
            <StyledModalButtonClose onClick={() => setModalStatus(null)}>
              <FormattedMessage id="LiturgyPage.Modal.Close" />
            </StyledModalButtonClose>
          )}

          <StyledModalButtonBook disabled={isButtonDisabled} htmlType="submit">
            {isLoading && (
              <>
                <LoadingOutlined />{" "}
              </>
            )}
            <FormattedMessage id={setButtonName(liturgy)} />
          </StyledModalButtonBook>
        </StyledModalFooter>
      </form>
    </StyledModalForm>
  );
};

export default compose(
  connect(
    createStructuredSelector({
      members: familyMembersSelector,
      availability: eventAvailabilitySelector,
      booking: (state, { eventId }) =>
        getBookingByEventIdSelector(state, eventId),
      isLoading: (state) => isLoadingSelector(state, LOADERS.bookingEvent),
      isLoadingAvailability: (state) => isLoadingSelector(state, LOADERS.availability),
    }),
    {
      setModalStatus: setModalStatusAction,
    }
  ),
  reduxForm({
    form: "liturgyForm",
    destroyOnUnmount: true,
    onSubmit: (value, dispatch, { eventId }) =>
      dispatch(
        bookEventAction({
          event: eventId,
          bookedMembers: prepareMemberIds(value.bookedMembers),
        })
      ),
  })
)(LiturgyModalForm);
