import { SET_LOADER_STATUS } from "./loader.action";
import initialState from "../../store/initialState";

export default (state = initialState.loaders, { type, payload }) => {
  switch (type) {
    case SET_LOADER_STATUS:
      return {
        ...state,
        [payload.name]: payload.status
      };
    default:
      return state;
  }
};
