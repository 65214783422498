import React from 'react'
import StyledLogo from './styled/StyledLogo';
import { injectIntl } from "react-intl";
import LogoMain from '../../assets/img/logo/logoLightBG.svg'

const Logo = ({intl}) => (
	<StyledLogo>
		<img src={LogoMain} alt={intl.formatMessage({id: 'Logo.Main.Alt'})} />
	</StyledLogo>
);

export default injectIntl(Logo);


