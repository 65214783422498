import initialState from "../../store/initialState";
import { GET_MY_BOOKINGS_SUCCESS } from "./bookings.action";

export default (state = initialState.bookings, action) => {
  switch (action.type) {
    case GET_MY_BOOKINGS_SUCCESS:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
