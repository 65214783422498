import styled from 'styled-components'

const StyledCardItem = styled.div`
  width: 256px;
  height: 248px;
  background-image: linear-gradient(to top, #4164da, #587cf4);
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
  box-shadow: 0 0 10px 0 rgba(139, 155, 163, 0.1);
  
  &:hover {
     box-shadow: 0 10px 20px 0 rgba(139, 155, 163, 0.2);
  }
  
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
`
export default StyledCardItem;


