import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const StyledHeader = styled.div`
  display: flex;
  justify-content: ${({ logoProp }) =>
    logoProp === true ? "space-between;" : "flex-end;"};
  width: 100%;
  padding: ${({ logoProp }) =>
    logoProp === true ? "35px 35px 20px 40px;" : "35px 35px 44px 40px;"};
  position: relative;

  @media ${DEVICE.tabletDevices1200} {
    padding: ${({ logoProp }) =>
      logoProp === true ? "35px 15px 20px 20px;" : "35px 0 44px 0;"};
    align-items: center;
  }
`;

export default StyledHeader;
