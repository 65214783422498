import React from "react";
import { FormattedMessage } from "react-intl";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  fileUploadAction,
  removeFileAction,
} from "../../services/file/file.action";
import { isFileUploadingSelector } from "../../services/filesUploader/filesUploader.selector";
import Styled from "./styled";
import { selectFile } from "../../services/file/file.selector";

const FileUploader = ({ formName, name, isFormDisabled, children }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) =>
    isFileUploadingSelector(state, formName, name)
  );
  const attachments = useSelector((state) => selectFile(state, name, formName));

  return (
    <Spin spinning={!!isLoading}>
      {!isFormDisabled && (
        <Styled.Upload
          disabled={isFormDisabled}
          showUploadList={false}
          customRequest={({ file }) => {
            dispatch(
              fileUploadAction({
                file,
                formName,
                name,
              })
            );
          }}
        >
          <Styled.UploadButton>{children}</Styled.UploadButton>
        </Styled.Upload>
      )}

      <Styled.FilesList>
        {attachments?.map((attachment) => (
          <Styled.File
            key={attachment?.url}
            href={attachment?.url}
            target={"_blank"}
            download={attachment?.url}
          >
            <div>
              <Styled.FileIcon />
              {attachment?.name}
            </div>
            {!isFormDisabled && <Styled.RemoveIcon
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                dispatch(
                  removeFileAction({
                    formName,
                    name,
                    fileId: attachment?.id,
                  })
                );
              }}
            />}
          </Styled.File>
        ))}
      </Styled.FilesList>
    </Spin>
  );
};

export default FileUploader;
