import styled from 'styled-components';

const StyledDashboardIcon = styled.div`
	position: absolute;
    width: 112px;
    height: 112px;
    top: 50px;
    left: 50%;
    margin-left: -56px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f2f6ff;
    z-index: 2; 
    background-color: var(--${(props) => props.color});
    
   
`
export default StyledDashboardIcon;