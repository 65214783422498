import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledFormCol67 = styled.div`
	  width: 67%;
	  	   	  
	  @media ${DEVICE.mobileDevices} {
			width: 100%;
			margin-left: 0;
			margin-bottom: 24px;
	  } 
`
export default StyledFormCol67 ;


