import React from 'react';
import {connect} from 'react-redux'
import {submit} from 'redux-form'

import {FormattedMessage} from "react-intl";
import Styled from './LoginDesclaimer.styled';
import {createStructuredSelector} from "reselect";
import {isLoadingSelector} from "../../../../services/loader/loader.selector";
import {LOADERS} from "../../../../constants/constants";
import {LoadingOutlined} from "@ant-design/icons";

const LoginDisclaimerContent = ({setPopupStatus, dispatch, isLoading}) => {
    return (
        <Styled.Content>
            <h1><FormattedMessage id="Disclaimer.Title"/></h1>
            <Styled.Desc>
                <FormattedMessage id="Disclaimer.Desc"/>
            </Styled.Desc>
            <Styled.Button disabled={isLoading} onClick={() => {dispatch(submit("loginForm"))}}>
                {isLoading && (
                    <>
                        <LoadingOutlined style={{marginRight: 8, marginLeft: 8}}/>{" "}
                    </>
                )}
                Accept
            </Styled.Button>
            <Styled.Button uiType="secondary" onClick={() => setPopupStatus(null)}>Close</Styled.Button>
        </Styled.Content>
    );
};

export default connect(
    createStructuredSelector(
        {isLoading: (state) => isLoadingSelector(state, LOADERS.login)}
    )
)(LoginDisclaimerContent);
