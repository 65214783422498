import { createAction } from "redux-actions";

export const CHECK_EVENT_AVAILABILITY = "CHECK_EVENT_AVAILABILITY";
export const CHECK_EVENT_AVAILABILITY_SUCCESS =
  "CHECK_EVENT_AVAILABILITY_SUCCESS";
export const CHECK_EVENT_AVAILABILITY_FAILURE =
  "CHECK_EVENT_AVAILABILITY_FAILURE";

export const checkEventAvailabilityAction = createAction(
  CHECK_EVENT_AVAILABILITY
);
