import styled from 'styled-components'
import { Radio } from 'antd';

const StyledLangRadioGroup = styled(Radio.Group)`
    {
        width: 100%;
        display: flex;
        border: none;
        color: var(--hrefColor);
    }
`

export default StyledLangRadioGroup;


