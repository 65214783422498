import styled from "styled-components";

const StyledIconNotification = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default StyledIconNotification;
