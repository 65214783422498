import { put } from "redux-saga/effects";
import isNull from "lodash/isNull";
import { FILES_UPLOAD } from "../file/file.action";

export const uploadFilesUtil = (family, formName) => {
  const field = family[formName];
  if (field) {
    const { nationalIDCopy, emiratesIDCopy, profilePic } = field;
    return put({
      type: FILES_UPLOAD,
      payload: {
        files: {
          nationalIDCopy,
          emiratesIDCopy,
          profilePic: [profilePic]
        },
        formName
      }
    });
  }
};

export const isFamilyComplete = family => {
  if (family?.type === "alone") {
    return (
      !!family.mainMember &&
      (!!family.country?.isMain ? !!family.address : true) &&
      !isNull(family.numberOfGrantParents)
    );
  }
  return (
    !!family.mainMember &&
    (!!family.country?.isMain ? !!family.address : true) &&
    !!family.spouseStatus &&
    !isNull(family.numberOfGrantParents) &&
    !isNull(family.numberOfChildren)
  );
};
