import React from 'react';
import { FormattedMessage } from "react-intl";
import { Select } from 'antd'

import InputField from "../../../components/InputField/InputField";
import SelectField from "../../../components/SelectField/SelectField";
import DatePickerField from "../../../components/DatePickerField/DatePickerField";
import {disableFutureDate} from "../../../utils/utils";

import StyledPageWrapSubTitle from '../styled/StyledPageWrapSubTitle';
import StyledPageSubTitle from '../styled/StyledPageSubTitle';
import StyledFormRow from '../styled/StyledFormRow';
import StyledFormCol33 from '../styled/StyledFormCol33';

const { Option } = Select;

const MainSection = ({disabledDOB}) => (
    <>
        <StyledPageWrapSubTitle>
            <StyledPageSubTitle>
                <FormattedMessage
                    id="FamilyPage.Section.Main"
                />
            </StyledPageSubTitle>
        </StyledPageWrapSubTitle>

        <StyledFormRow>
            <StyledFormCol33>
                <InputField
                    label="FamilyPage.Form.FirstName"
                    name="firstName"
                />
            </StyledFormCol33>

            <StyledFormCol33>
                <InputField
                    label="FamilyPage.Form.SecondName"
                    name="secondName"
                />
            </StyledFormCol33>

            <StyledFormCol33>
                <InputField
                    label="FamilyPage.Form.LastName"
                    name="lastName"
                />
            </StyledFormCol33>

        </StyledFormRow>

        <StyledFormRow>
            <StyledFormCol33>
                <SelectField
                    label="FamilyPage.Form.Gender"
                    name="gender"
                >
                    <Option value="male" >
                        <FormattedMessage id="FamilyPage.Form.Male"/>
                    </Option>
                    <Option value="female" >
                        <FormattedMessage id="FamilyPage.Form.Female"/>
                    </Option>
                </SelectField>
            </StyledFormCol33>

            <StyledFormCol33>
                <DatePickerField
                    disabledDate={disableFutureDate}
                    label="FamilyPage.Form.DateOfBirth"
                    name="dateOfBirth"
                    disabledFiled={disabledDOB}
                />
            </StyledFormCol33>
            <StyledFormCol33>
            </StyledFormCol33>
        </StyledFormRow>
    </>
);

export default MainSection;
