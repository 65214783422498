import styled from 'styled-components';
import { DatePicker } from 'antd';
import { DEVICE } from '../../../constants/media';

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #d6e1e9;
  background-color: #ffffff;
  
  @media ${DEVICE.tablet} {
		    max-width: 208px;
	    }
  
  .ant-picker-input >  input {
    color: var(--dark);
    font-size: 13px;
  }
  
  &.ant-picker-focused {

        background-color: #ffffff;  
        box-shadow: none;
        border: solid 1px #9a9fb0;
        
	}
	&:hover{
        border: solid 1px #9a9fb0;
        background-color: #ffffff; 
        box-shadow: none;
	}
	
	&:disabled {
	    border: solid 1px #d6e1e9;
        background-color: #f3f7fa;
	}
	&.ant-picker-disabled {
		border: solid 1px #d6e1e9;
        background-color: #f3f7fa;
        &:hover {
		    border: solid 1px #d6e1e9;
            background-color: #f3f7fa;
		}
	}
	
	.ant-picker-clear {
		background: transparent;
		&:hover {
		    color: rgba(0, 0, 0, 0.75);
		    right: -10px;
		    top: 13px;
		}
	}
  
  
`
export default StyledDatePicker;
