import React from 'react';
import { FormattedMessage } from "react-intl";

import InputField from "../../../components/InputField/InputField";
import InputPhoneField from "../../../components/InputPhoneField/InputPhoneField";
import StyledPageWrapSubTitle from '../styled/StyledPageWrapSubTitle';
import StyledPageSubTitle from '../styled/StyledPageSubTitle';
import StyledFormRow from '../styled/StyledFormRow';
import StyledFormCol33 from '../styled/StyledFormCol33';

const ContactSection = () => (
    <>

        <StyledPageWrapSubTitle>
            <StyledPageSubTitle>
                <FormattedMessage id="FamilyPage.Section.Contact" />
            </StyledPageSubTitle>
        </StyledPageWrapSubTitle>


        <StyledFormRow>
            <StyledFormCol33>
                <InputField label="FamilyPage.Form.Email"
                            name="email"
                />
            </StyledFormCol33>

            <StyledFormCol33>
                <InputPhoneField
                        country="ae"
                        label="FamilyPage.Form.Phone"
                        name="mobile"
                />
            </StyledFormCol33>

            <StyledFormCol33>
                <InputField
                        label="FamilyPage.Form.Whatsapp"
                        name="whatsapp"
                />
            </StyledFormCol33>

        </StyledFormRow>




    </>
);

export default ContactSection;