import styled from 'styled-components';
import {DEVICE} from '../../../constants/media';

const StyledDashboardItem = styled.div`
  width: 400px;
  margin-bottom: 45px;
  max-width: 360px;
  max-height: 368px;
  height: 300px;
  border-radius: 10px;
  margin-left: 22px;
  margin-right: 22px;
  border: solid 1px var(--lightGrey);
  box-shadow: 0 0 10px 0 rgba(139, 155, 163, 0.1);
  overflow: hidden;
  position: relative;
  background-image: var(--${(props) => props.color});
  z-index: 1;

  &:hover {
    box-shadow: 0 10px 20px 0 rgba(139, 155, 163, 0.2);
  }

  @media ${DEVICE.mobileDevicesS} {
    width: 290px;
    margin-bottom: 45px;
  }

  & > a {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`
export default StyledDashboardItem;
