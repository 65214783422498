import styled from 'styled-components'


const StyledUploaderPreviewBlock = styled.div`
    position: relative;
    width: 80px;
	height: 80px;
	border-radius: 10px;
	cursor: pointer;
	background: #fff;
	margin-bottom: 5px;
	
	img {
		position: relative;
	    width: 80px;
	    height: 80px;
	    border-radius: 10px;
	    object-fit: contain;
	}
`

export default StyledUploaderPreviewBlock;


