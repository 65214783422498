import styled from 'styled-components';
import { DEVICE } from '../../../../../constants/media';

const StyledModalForm = styled.div`
  margin: 0 40px;
  
  @media ${DEVICE.mobileDevicesS} {
		margin: 0 15px;
	}
   @media ${DEVICE.mobileDevices} {
		margin: 0 20px;
	}
`
export default StyledModalForm;