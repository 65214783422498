import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledFormCol33 = styled.div`
	  width: 33%;
	  margin-left: 30px;
	  
	  &:first-child {
	     margin-left: 0;
	  }
	  
	  @media ${DEVICE.mobileDevices} {
			width: 100%;
			margin-left: 0;
			margin-bottom: ${({ typeEmpty }) => typeEmpty === true ? '0;' : '24px'  }	  	  
`
export default StyledFormCol33 ;


