import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledColMainLayout = styled.div`
	width: 100%;
	background: ${({ typeCol }) => (typeCol && typeCol === 'left') ? 'var(--bgGrayCol)' : 'var(--bgLight)'};
	padding-bottom: ${({ typeCol }) => (typeCol && typeCol === 'right') ? '165px;' : '0'};
	position: relative;
	z-index: ${({ typeCol }) => (typeCol && typeCol === 'left') ? '3' : '1'};
	display: flex;
    // align-items: center;
    justify-content: center;
    
    @media ${DEVICE.mobileDevices} {
		 order: ${({ typeCol }) => (typeCol && typeCol === 'left') ? '2' : '1'};
		 
		
	}
    
    @media ${DEVICE.tablet} {
		width: 50%;
	}
	
	&:after {
		content: 'UAE Copts';
		position: absolute;
		width: 900px;
        height: 149px;
		bottom: 0;
		left: -68px;
        z-index: 2;
		font-family: var(--fontProximaBold);
		display: ${({ typeCol }) => (typeCol === 'right') ? 'block' : 'none'};
		font-size: 170px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: var(--bgGrayCol);
		overflow: hidden;
		
		@media ${DEVICE.mobileDevices} {
			display: none;
		}
	}
`
export default StyledColMainLayout;