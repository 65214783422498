import instance from "../root.api";

export const setFamilyChildRequest = data =>
  instance
    .put("/families/me/child", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const setNoChildrenRequest = data =>
  instance
    .put("/families/me/children", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));
