import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledUploaderBlock= styled.div`
	display: flex;
	margin-right: 24px;
	
	@media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;	
	}	
`
export default StyledUploaderBlock;


