import styled from 'styled-components'


const StyledChildrenInfo = styled.div`
    
`

export default StyledChildrenInfo;


