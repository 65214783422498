import { createAction } from "redux-actions";

export const GET_MY_BOOKINGS = "GET_MY_BOOKINGS";
export const GET_MY_BOOKINGS_SUCCESS = "GET_MY_BOOKINGS_SUCCESS";
export const GET_MY_BOOKINGS_FAILURE = "GET_MY_BOOKINGS_FAILURE";

export const BOOK_EVENT = "BOOK_EVENT";
export const BOOK_EVENT_SUCCESS = "BOOK_EVENT_SUCCESS";
export const BOOK_EVENT_FAILURE = "BOOK_EVENT_FAILURE";

export const SET_BOOKING_LOAD_REQUEST_STATUS =
  "SET_BOOKING_LOAD_REQUEST_STATUS";

export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_FAILURE = "CANCEL_BOOKING_FAILURE";

export const MODIFY_BOOKING = "MODIFY_BOOKING";
export const MODIFY_BOOKING_SUCCESS = "MODIFY_BOOKING_SUCCESS";
export const MODIFY_BOOKING_FAILURE = "MODIFY_BOOKING_FAILURE";

export const bookEventAction = createAction(BOOK_EVENT);
export const cancelBookingAction = createAction(CANCEL_BOOKING);
export const modifyBookingAction = createAction(MODIFY_BOOKING);
