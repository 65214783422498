import initialState from "../../store/initialState";
import { SET_GEOLOCATION } from "./geolocation.action";

export default (state = initialState.geoLocation, action) => {
  switch (action.type) {
    case SET_GEOLOCATION:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
