import styled from 'styled-components'

const StyledPhotoSection = styled.div`
    display: flex;
    margin-top: 32px;
    margin-bottom: 40px;
    
`

export default StyledPhotoSection;


