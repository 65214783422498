import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_FAMILY_INFO,
  GET_FAMILY_INFO_FAILURE,
  GET_FAMILY_INFO_SUCCESS
} from "./family.action";

import { familyInfoRequest } from "./family.api";
import { uploadFilesUtil } from "./family.utils";
import { SET_GEOLOCATION } from "../geolocation/geolocation.action";
import { SET_GENERAL_LOADER_STATUS } from "../generalLoader/generalLoader.action";

function* getFamilyInfoOnLocationChangeData() {
  try {
    yield delay(500);

    const family = yield select(state => state.family);
    const storage = localStorage.getItem("storageTyp");

    if (!family.id && window[storage].getItem("jwt"))
      yield put({ type: GET_FAMILY_INFO });
  } catch (error) {
    console.log(error);
  }
}

function* fetchFamilyInfo() {
  try {
    yield put({
      type: SET_GENERAL_LOADER_STATUS,
      payload: 1
    });
    const { response } = yield call(familyInfoRequest);

    if (response.status === 200) {
      yield put({
        type: GET_FAMILY_INFO_SUCCESS,
        payload: response.data
      });
      yield put({
        type: SET_GEOLOCATION,
        payload: response.data?.geoLocation
      });
      yield uploadFilesUtil(response.data, "mainMember");
      yield uploadFilesUtil(response.data, "spouse");
    } else {
      yield put({
        type: GET_FAMILY_INFO_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: GET_FAMILY_INFO_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: SET_GENERAL_LOADER_STATUS,
      payload: -1
    });
  }
}

export default function* appInfoSaga() {
  yield takeLatest(GET_FAMILY_INFO, fetchFamilyInfo);
  yield takeLatest(LOCATION_CHANGE, getFamilyInfoOnLocationChangeData);
}
