import styled from 'styled-components';

const StyledFamilyAsideMenu = styled.div`
    display: flex;
	flex-wrap: wrap;
	margin-top: 88px;
`

export default StyledFamilyAsideMenu;


