import { createAction } from "redux-actions";

export const SET_FAMILY_PARENT = "SET_FAMILY_PARENT";
export const SET_FAMILY_PARENT_SUCCESS = "SET_FAMILY_PARENT_SUCCESS";
export const SET_FAMILY_PARENT_FAILURE = "SET_FAMILY_PARENT_FAILURE";
export const setFamilyParentAction = createAction(SET_FAMILY_PARENT);

export const SET_NO_PARENTS = "SET_NO_PARENTS";
export const SET_NO_PARENTS_SUCCESS = "SET_NO_PARENTS_SUCCESS";
export const SET_NO_PARENTS_FAILURE = "SET_NO_PARENTS_FAILURE";
export const setNoParentsAction = createAction(SET_NO_PARENTS);
