import {SET_LOADER_STATUS} from "../services/loader/loader.action";
import {LOADERS, STATUS} from "../constants/constants";

const lang = localStorage.getItem("lang");

export const getLocalizedKey = filedName =>
  lang === "en" ? filedName : `${filedName}Ar`;

export const disableFutureDate = current =>
  current && current.valueOf() > Date.now();

export const isNOCEditable = (marriageNOC) => {
  return marriageNOC?.status === STATUS.DRAFT || marriageNOC?.status === STATUS.NEED_MORE_INFO;
}

export const loadingPayload = (loaderName, isLoading) => {
  return {
    type: SET_LOADER_STATUS,
    payload: {
      name: loaderName,
      status: isLoading,
    }
  }
}
