import styled, {css} from 'styled-components';

const StyledFormRow = styled.div`
    margin: 0 0 8px 0;
    padding: 0;
    vertical-align: top;
    
    ${({align}) => {
		if (align && (align === 'space-between')) {
			return css`
						display: flex;
						justify-content: space-between
			         `}
	}}
}
`
export default StyledFormRow;