import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";

import { history } from "../routes/urlLocations";
import { LOGOUT } from "./logout/logout.action";
import initialState from "../store/initialState";
import appInfoReducer from "./appInfo/appInfo.reducer";
import familyReducer from "./family/family.reducer";
import errorsReducer from "./errors/errors.reducer";
import filesReducer from "./file/file.reducer";
import areasReducer from "./area/area.reducer";
import geolocationReducer from "./geolocation/geolocation.reducer";
import eventsReducer from "./events/events.reducer";
import bookingsReducer from "./bookings/bookings.reducer";
import modalsReducer from "./modals/modals.reducer";
import eventAvailabilityReducer from "./eventAvailability/eventAvailability.reducer";
import fileUploaderReducer from "./filesUploader/filesUploader.reducer";
import loaderReducer from "./loader/loader.reducer";
import generalLoaderReducer from "./generalLoader/generalLoader.reducer";
import marriageNocReducer from "./marriageNoc/marriageNoc.reducer";

const appReducer = combineReducers({
  appInfo: appInfoReducer,
  generalLoader: generalLoaderReducer,
  family: familyReducer,
  errors: errorsReducer,
  files: filesReducer,
  areas: areasReducer,
  geoLocation: geolocationReducer,
  events: eventsReducer,
  bookings: bookingsReducer,
  modals: modalsReducer,
  eventAvailability: eventAvailabilityReducer,
  fileUploader: fileUploaderReducer,
  loaders: loaderReducer,
  marriageNoc: marriageNocReducer,
  form: formReducer,
  router: connectRouter(history)
});

const rootReducers = (state, action) => {
  if (action.type === LOGOUT) {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducers;
