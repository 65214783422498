import styled from 'styled-components'

const StyledCardItemTxt = styled.div`
  width: 100%;
  padding: 0 20px;
  text-align: center;
  margin: 0;
  
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: var(--dark);
  font-family: var(--fontProximaRegular); 
`

export default StyledCardItemTxt;


