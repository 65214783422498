import { put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import {
  SET_GENERAL_LOADER_STATUS,
  FINISH_GENERAL_LOADER,
  START_GENERAL_LOADER
} from "./generalLoader.action";

function* startLoader() {
  yield put({ type: SET_GENERAL_LOADER_STATUS, payload: 1 });
}
function* finishLoader() {
  yield delay(500);
  yield put({ type: SET_GENERAL_LOADER_STATUS, payload: -1 });
}

export default function* generalLoaderSaga() {
  yield takeEvery(START_GENERAL_LOADER, startLoader);
  yield takeLatest(FINISH_GENERAL_LOADER, finishLoader);
}
