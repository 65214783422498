import styled from "styled-components";

const StyledLiturgyContentBooking = styled.div`
  padding: 0 0 30px 50px;
  margin-top: -30px;
  font-family: var(--fontProximaBold);
  font-size: 14px;
  text-transform: uppercase;
  i {
    vertical-align: bottom;
    margin-left: -5px;
  }
  span {
    color: var(--gray);
    font-weight: normal;
    font-family: var(--fontProximaRegular);
  }
`;

export default StyledLiturgyContentBooking;
