import React from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {urlLocations} from "../../routes/urlLocations";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import StyledRegistrationPage from './styled/StyledRegistrationPage';
import StyledFormBottom from "../styled/StyledFormBottom";
import StyledFormBottomText from "../styled/StyledFormBottomText";
import StyledFormTitle from "../styled/StyledFormTitle";

const Registration = () =>  (
	<StyledRegistrationPage>
		<StyledFormTitle>
			<FormattedMessage
				id="Registration.Title"
			/>
		</StyledFormTitle>
		<RegistrationForm initialValues={{ familyType: "family", country: 1 }} />

		<StyledFormBottom>
			<StyledFormBottomText>
				<FormattedMessage id="Registration.Bottom" />
			</StyledFormBottomText>

			<Link to={urlLocations.login} >
				<FormattedMessage
					id="Registration.Bottom.SignIn"
				/>
			</Link>

		</StyledFormBottom>

	</StyledRegistrationPage>
)

export default Registration;
