import styled from 'styled-components'

const StyledBookingTextQr = styled.div`
    width: 89px;
    font-size: 15px;
    text-transform: uppercase;
    padding-bottom: 5px;
    text-align: center;
`

export default StyledBookingTextQr;


