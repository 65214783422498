import styled from 'styled-components';

const StyledInfoBoxIcon = styled.i`
  display: inline-block;
  width: 24px;
  height: 24px; 
  margin-right: 8px;
  background: #ffffff;
  background: url(${({bg}) => bg }) center center no-repeat;
`
export default StyledInfoBoxIcon;