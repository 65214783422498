import React from 'react';
import {reduxForm} from "redux-form";
import InputField from "../../../../components/InputField/InputField";
import Button from "../../../../components/Button/Button";
import Styled from './styled'
import StyledFormCol67 from "../../../FamilyPage/styled/StyledFormCol67";
import {resendEmailAction} from "../../../../services/resendEmail/resendEmail.action";
import {FormattedMessage} from "react-intl";

const ResendEmailForm = ({handleSubmit, setPopupStatus}) => (
    <Styled.Content>
        <form onSubmit={handleSubmit}>
            <h1><FormattedMessage id="ResendEmail.Title"/></h1>
            <p>
                <FormattedMessage id="ResendEmail.Description"/>
            </p>
            <InputField
                name="email"
                placeholder="Login.Email.Placeholder"
                label="Login.Email.Placeholder"
            />
            <Styled.ButtonRow>
            <Button htmlType="submit"><FormattedMessage id="ResendEmail.Resend" /></Button>
            <Button uiType="secondary" onClick={setPopupStatus.bind(null, null)} >
                <FormattedMessage id="ResendEmail.Cancel" />
            </Button>
        </Styled.ButtonRow>

        </form>
    </Styled.Content>
);

export default reduxForm({
    form: "resendEmailForm",
    onSubmit: (value, dispatch) => dispatch(resendEmailAction(value))
})(ResendEmailForm)