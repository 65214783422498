import styled from 'styled-components'

const StyledPhotoSectionImg = styled.div`
    width: 140px;
    position: relative;
    
`

export default StyledPhotoSectionImg;


