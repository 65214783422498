import instance from "../root.api";

export const getMarriageNocsRequest = () =>
  instance.get("/marriage-nocs/me").then((response) => ({ response }));

export const updateMarriageNocRequest = ({ id, ...data }) =>
  instance.put(`/marriage-nocs/${id}`, data).then((response) => ({ response }));

export const createMarriageNocDraftRequest = ({ id, ...data }) =>
  instance
    .post(`/marriage-nocs/draft`, data)
    .then((response) => ({ response }))
    .catch(response => ({ ...response }));

export const updateMarriageNocDraftRequest = ({ uuid, ...data }) =>
  instance
    .put(`/marriage-nocs/draft/${uuid}`, data)
    .then((response) => ({ response }));

export const submitMarriageNocDraftRequest = ({uuid, ...data}) =>
  instance
    .put(`/marriage-nocs/draft/${uuid}/submit`, data)
    .then((response) => ({response}));
