import styled from 'styled-components'

const StyledFamilyAsideLeftIcon = styled.i`
	margin-right: 17px;
    position: relative;
    top: 0;
    display: inline-block;
    width: 24px;
    height: 24px; 
`
export default StyledFamilyAsideLeftIcon;


