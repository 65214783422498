import { createAction } from "redux-actions";

export const GET_MARRIAGE_NOC = "GET_MARRIAGE_NOC";
export const GET_MARRIAGE_NOC_SUCCESS = "GET_MARRIAGE_NOC_SUCCESS";
export const GET_MARRIAGE_NOC_FAILURE = "GET_MARRIAGE_NOC_FAILURE";

export const CREATE_MARRIAGE_NOC_DRAFT = "CREATE_MARRIAGE_NOC_DRAFT";
export const CREATE_MARRIAGE_NOC_DRAFT_SUCCESS =
  "CREATE_MARRIAGE_NOC_DRAFT_SUCCESS";
export const CREATE_MARRIAGE_NOC_DRAFT_FAILURE =
  "CREATE_MARRIAGE_NOC_DRAFT_FAILURE";

export const UPDATE_MARRIAGE_NOC = "UPDATE_MARRIAGE_NOC";
export const UPDATE_MARRIAGE_NOC_SUCCESS = "UPDATE_MARRIAGE_NOC_SUCCESS";
export const UPDATE_MARRIAGE_NOC_FAILURE = "UPDATE_MARRIAGE_NOC_FAILURE";

export const UPDATE_MARRIAGE_NOC_DRAFT = "UPDATE_MARRIAGE_NOC_DRAFT";
export const UPDATE_MARRIAGE_NOC_DRAFT_SUCCESS =
  "UPDATE_MARRIAGE_NOC_DRAFT_SUCCESS";
export const UPDATE_MARRIAGE_NOC_DRAFT_FAILURE =
  "UPDATE_MARRIAGE_NOC_DRAFT_FAILURE";

export const SUBMIT_MARRIAGE_NOC_DRAFT = "SUBMIT_MARRIAGE_NOC_DRAFT";
export const SUBMIT_MARRIAGE_NOC_DRAFT_SUCCESS =
  "SUBMIT_MARRIAGE_NOC_DRAFT_SUCCESS";
export const SUBMIT_MARRIAGE_NOC_DRAFT_FAILURE =
  "SUBMIT_MARRIAGE_NOC_DRAFT_FAILURE";

export const updateMarriageNocAction = createAction(UPDATE_MARRIAGE_NOC);
export const createMarriageNocDraftAction = createAction(
  CREATE_MARRIAGE_NOC_DRAFT
);
export const updateMarriageNocDraftAction = createAction(
  UPDATE_MARRIAGE_NOC_DRAFT
);

export const submitMarriageNocDraftAction = createAction(
  SUBMIT_MARRIAGE_NOC_DRAFT
);
