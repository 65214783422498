import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledHomePageBottomTitle = styled.div`
	margin-top: 88px;
	font-size: 16px;
	font-family: var(--fontProximaBold);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--dark);
    position: relative;
	z-index: 2;
	
	@media ${DEVICE.mobileDevices} {
		padding-left: 15px;
		padding-right: 15px;
	}
	
	@media ${DEVICE.tabletDevices1200} {
		padding-left: 20px;
		padding-right: 20px;
	}
`
export default StyledHomePageBottomTitle;