import React from 'react';
import { FormattedMessage } from "react-intl";
import { Select } from "antd";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import SelectField from "../../../components/SelectField/SelectField";
import InputField from "../../../components/InputField/InputField";
import RadioField from "../../../components/RadioField/RadioField";

import {
        appInfoFathersSelector,
        appInfoWorkingFieldsSelector
} from "../../../services/appInfo/appInfo.selector";
import { getLocalizedKey } from "../../../utils/utils";
import { DEACON_RADIO, FAMILY_STATUSES } from '../../../constants/constants'

import StyledPageWrapSubTitle from '../styled/StyledPageWrapSubTitle';
import StyledPageSubTitle from '../styled/StyledPageSubTitle';
import StyledFormRow from '../styled/StyledFormRow';
import StyledFormCol33 from '../styled/StyledFormCol33';
import StyledAdditionalWrapCheckbox from './styled/StyledAdditionalWrapCheckbox';
const { Option } = Select;

const AdditionalSection = ({ fathers, workingFields }) => (
    <>

        <StyledPageWrapSubTitle>
            <StyledPageSubTitle>
                <FormattedMessage id="FamilyPage.Section.Additional" />
            </StyledPageSubTitle>
        </StyledPageWrapSubTitle>

        <StyledFormRow>
            <StyledFormCol33>
                    <SelectField
                        label="FamilyPage.Form.SocialStatus"
                        name="status"
                    >
			            {
				            FAMILY_STATUSES.map(status => (
                                <Option key={status.value} value={status.value} >
                                        <FormattedMessage id={status.label} />
                                </Option>
				            ))
			            }
                    </SelectField>
            </StyledFormCol33>

            <StyledFormCol33>
                    <SelectField
                        label="FamilyPage.Form.FatherOfConfession"
                        name="fatherOfConfession"
                    >
			            {
				            fathers && fathers.map(father =>
                                <Option key={father.id} value={father.id} >
						            { father[getLocalizedKey('name')] }
                                </Option>)
			            }
                    </SelectField>
                
            </StyledFormCol33>

            <StyledFormCol33>

            </StyledFormCol33>

        </StyledFormRow>

        <StyledFormRow>

            <StyledFormCol33>
                    <InputField
                        label="FamilyPage.Form.Service"
                        name="churchService"
                    />
            </StyledFormCol33>

            <StyledFormCol33>

                    <SelectField
                        label="FamilyPage.Form.WorkingField"
                        name="workingField"
                    >
					    {
						    workingFields && workingFields.map(field =>
                                <Option key={field.id} value={field.id} >
								    { field[getLocalizedKey('name')] }
                                </Option>)
					    }
                    </SelectField>

            </StyledFormCol33>

            <StyledFormCol33>

                <StyledAdditionalWrapCheckbox>
                    <p><FormattedMessage id="FamilyPage.Form.Deacon" />: </p>

                    <RadioField
                        name="deacon"
                        radioSet={DEACON_RADIO}
                    />

                </StyledAdditionalWrapCheckbox>

            </StyledFormCol33>

        </StyledFormRow>

    </>
);

export default connect(
    createStructuredSelector({
        fathers: appInfoFathersSelector,
        workingFields: appInfoWorkingFieldsSelector
    }),
    {}
)(AdditionalSection);