export const passwordValidate = values => {
  const passwordValidation = /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/;
  const passwordErrorMessage = "Password.Validation";
  const errors = {};
  if (values.password && !passwordValidation.test(values.password)) {
    errors.password = passwordErrorMessage;
  }
  if (
    values.passwordConfirmation &&
    !passwordValidation.test(values.passwordConfirmation)
  ) {
    errors.passwordConfirmation = passwordErrorMessage;
  }

  return errors;
};
