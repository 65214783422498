import React from "react";
import { Switch } from "react-router";

import { urlLocations } from "./urlLocations";
import RouteWrapper from "./RouterWrapper";
import MainLayout from "../layouts/MainLayout/MainLayout";
import HomeLayout from "../layouts/AuthorizeLayouts/HomeLayout/HomeLayout";
import LoginPage from "../containers/LoginPage/LoginPage";
import RegistrationPage from "../containers/RegistrationPage/RegistrationPage";
import HomePage from "../containers/HomePage/HomePage";
import FamilyLayout from "../layouts/AuthorizeLayouts/FamilyLayout/FamilyLayout";
import FamilyPage from "../containers/FamilyPage/FamilyPage";
import LiturgyLayout from "../layouts/AuthorizeLayouts/LiturgyLayout/LiturgyLayout";
import LiturgyPage from "../containers/LiturgyPage/LiturgyPage";
import BookingLayout from "../layouts/AuthorizeLayouts/BookingLayout/BookingLayout";
import BookingPage from "../containers/BookingPage/BookingPage";
import PrivacyPolicyPage from "../containers/PrivacyPolicyPage/PrivacyPolicyPage";
import BasicLayout from "../layouts/BasicLayout/BasicLayout";
import ResetPasswordPage from "../containers/ResetPasswordPage/ResetPasswordPage";
import PrivateEvent from "../containers/PrivateEvent/PrivateEvent";
import MarriageNoc from "../containers/MarriageNoc/MarriageNoc";
import MarriageNocDetails from '../containers/MarriageNOCDetails/MarriageNOCDetails';

export default () => (
  <>
    <Switch>
      <RouteWrapper
        exact
        path={urlLocations.privacy}
        layout={BasicLayout}
        component={PrivacyPolicyPage}
      />
      <RouteWrapper
        exact
        path={urlLocations.marriageNoc}
        layout={HomeLayout}
        component={MarriageNoc}
      />
      <RouteWrapper
          exact
          path={urlLocations.marriageNOCDetails}
          layout={HomeLayout}
          component={MarriageNocDetails}
      />
      <RouteWrapper
        exact
        path={urlLocations.booking}
        layout={BookingLayout}
        component={BookingPage}
      />
      <RouteWrapper
        exact
        path={urlLocations.privateEvent}
        layout={HomeLayout}
        component={PrivateEvent}
      />
      <RouteWrapper
        exact
        path={urlLocations.liturgyInfo}
        layout={LiturgyLayout}
        component={LiturgyPage}
      />
      <RouteWrapper
        path={urlLocations.family}
        layout={FamilyLayout}
        component={FamilyPage}
      />
      <RouteWrapper
        exact
        path={urlLocations.home}
        layout={HomeLayout}
        component={HomePage}
      />
      <RouteWrapper
        exact
        path={urlLocations.resetPassword}
        layout={MainLayout}
        component={ResetPasswordPage}
      />
      <RouteWrapper
        exact
        path={urlLocations.registration}
        layout={MainLayout}
        component={RegistrationPage}
      />
      <RouteWrapper
        exact
        path={urlLocations.login}
        layout={MainLayout}
        component={LoginPage}
      />
    </Switch>
  </>
);
