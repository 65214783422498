import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import {
  GET_EVENTS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_SUCCESS,
  GET_PRIVATE_EVENT,
  GET_PRIVATE_EVENT_FAILURE,
  GET_PRIVATE_EVENT_SUCCESS,
} from "./events.action";

import { eventsRequest, getPrivateEventRequest } from "./events.api";
import { isUrlMatch } from "../router/router.utils";
import { urlLocations } from "../../routes/urlLocations";
import { SET_GENERAL_LOADER_STATUS } from "../generalLoader/generalLoader.action";
import { matchPath } from "react-router";
import { CHECK_EVENT_AVAILABILITY } from "../eventAvailability/eventAvailability.action";

function* getEventsOnLocationChangeData({ payload }) {
  try {
    yield delay(500);
    const events = yield select((state) => state.events.list);

    if (
      !!events &&
      isEmpty(events) &&
      isUrlMatch(payload, urlLocations.liturgy)
    )
      yield put({ type: GET_EVENTS });
  } catch (error) {
    console.log(error);
  }
}

function* getPrivateEventOnLocationChangeData({ payload }) {
  try {
    yield delay(500);

    if (isUrlMatch(payload, urlLocations.privateEvent)) {
      const id = matchPath(payload.location.pathname, {
        path: urlLocations.privateEvent,
      }).params?.id;

      yield id && put({ type: GET_PRIVATE_EVENT, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchEvents() {
  try {
    yield put({
      type: SET_GENERAL_LOADER_STATUS,
      payload: 1,
    });
    const { response } = yield call(eventsRequest);
    if (response.status === 200) {
      yield put({
        type: GET_EVENTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_EVENTS_FAILURE,
      });
    }
  } catch (error) {
    yield put({ type: GET_EVENTS_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: SET_GENERAL_LOADER_STATUS,
      payload: -1,
    });
  }
}

function* fetchPrivateEvent({ payload }) {
  try {
    yield put({
      type: SET_GENERAL_LOADER_STATUS,
      payload: 1,
    });
    const { response } = yield call(getPrivateEventRequest, payload);
    yield put({
      type: GET_PRIVATE_EVENT_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: CHECK_EVENT_AVAILABILITY,
      payload: {
        id: response.data.id,
      },
    });
  } catch (error) {
    yield put({ type: GET_PRIVATE_EVENT_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: SET_GENERAL_LOADER_STATUS,
      payload: -1,
    });
  }
}

export default function* eventsSaga() {
  yield takeLatest(LOCATION_CHANGE, getEventsOnLocationChangeData);
  yield takeLatest(LOCATION_CHANGE, getPrivateEventOnLocationChangeData);
  yield takeLatest(GET_EVENTS, fetchEvents);
  yield takeLatest(GET_PRIVATE_EVENT, fetchPrivateEvent);
}
