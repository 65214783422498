import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledMainLayout = styled.div`
	display: flex;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	
	@media ${DEVICE.tablet} {
		flex-wrap: nowrap;
		overflow: hidden;
	}
	
	 @media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;
		padding-bottom: 100px;
		background: var(--bgLight);
	 }
	 
	 
`
export default StyledMainLayout;