import { put, takeEvery, call } from "redux-saga/effects";

import {
  REGISTRATION,
  REGISTRATION_FAILURE,
  REGISTRATION_SUCCESS
} from "./registration.action";
import { registrationRequest } from "./registration.api";
import { SET_ERRORS } from "../errors/errors.action";
import openNotification from "../../utils/notifications";
import { urlLocations, history } from "../../routes/urlLocations";

function* registration({ payload }) {
  try {
    const lowerCaseEmail = payload.email.trim().toLowerCase();
    const { response } = yield call(registrationRequest, {
      ...payload,
      username: lowerCaseEmail,
      email: lowerCaseEmail
    });

    if (response.status === 200) {
      yield put({
        type: REGISTRATION_SUCCESS
      });
      yield call(openNotification, {
        type: "success",
        message: "Registration.Notification.Success",
        description: "Registration.Notification.SuccessMessage"
      });
      history.push(urlLocations.login);
    } else {
      yield put({
        type: REGISTRATION_FAILURE
      });
      yield call(openNotification, {
        type: "error",
        message: "Registration.Notification.Error",
        description: "Registration.Notification.ErrorMessage"
      });
      yield put({
        type: SET_ERRORS,
        payload: response.data.data
      });
    }
  } catch (error) {
    yield put({ type: REGISTRATION_FAILURE });
    console.log("error", error);
  }
}

export default function* registrationSaga() {
  yield takeEvery(REGISTRATION, registration);
}
