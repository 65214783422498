import styled from 'styled-components'
import { Radio } from 'antd';

const StyledLangRadioBtn = styled(Radio.Button)`
    {
        width: 89px;
        height: 38px;
        border-radius: 10px;
        color: #27282b;
        border: none;
        line-height: 30px;
		font-size: 11px;
		display: flex;
		justify-content: center;
		line-height: 35px;
		padding: 0;
		vertical-align: center;
		border: 2px solid   transparent;
        
        &:first-child {
            border-radius: 10px;
            border-left:  2px solid transparent;
        }
        
        &.ant-radio-button-wrapper:hover {
            color: #27282b;    
        }
        
        &.ant-radio-button-wrapper:focus-within {
            box-shadow: none;
        }
       
        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                color: #27282a;
                border: 2px solid var(--hrefColor);
                border-radius: 10px;
        }
        &.ant-radio-button-wrapper-checked {
             border: 2px solid var(--white);   
        }
        &.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
            border-right-color: var(--hrefColor) !important;
        }
        &.ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }
        &.ant-radio-button-wrapper:last-child {
            border-radius: 10px;
        }
        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            display: none;
        }
        img {
            margin-right: 7px;
        }
    }
`

export default StyledLangRadioBtn;


