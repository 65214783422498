import styled from 'styled-components';
import { DEVICE } from '../../constants/media';

const StyledFormBottomText = styled.div`
	font-size: 13px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	color: var(--gray);
	display: inline-block;
	margin-right: 4px;
	
	 @media ${DEVICE.mobileDevices} {
		margin-right: 6px;
	}
`
export default StyledFormBottomText;