import React from 'react';
import {useSelector} from "react-redux";

import Styled from "../../containers/MarriageNOCDetails/styled";
import InputField from "../../components/InputField/InputField";
import DatePickerField from "../../components/DatePickerField/DatePickerField";
import SelectField from "../../components/SelectField/SelectField";
import {appInfoChurchesSelector} from "../../services/appInfo/appInfo.selector";
import {Select} from "antd";
import {getLocalizedKey} from "../../utils/utils";
import RadioField from "../../components/RadioField/RadioField";
import {IS_COPTIC_ORTHODOX_RADIO, MILITARY_STATUS} from "../../constants/constants";
import InputPhoneField from "../../components/InputPhoneField/InputPhoneField";
import MarriageNocDetailsSection from "../../containers/MarriageNOCDetails/MarriageNOCDetails.Section";
import {FormattedMessage} from "react-intl";

const {Option} = Select;

const MarriageNocDetailsPersonalInfo = ({disabled} = {disabled: false}) => {
  const churchs = useSelector(appInfoChurchesSelector);
  return (
    <MarriageNocDetailsSection title={"MarriageNOCDetails.PersonalInfo"}>
      <Styled.FieldsWrap>
        <Styled.Field>
          <InputField
            name={'fullName'}
            label={"MarriageNOCDetails.FullName"}
            placeholder={"MarriageNOCDetails.FullName"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <DatePickerField
            name={'dateOfBirth'}
            label={"MarriageNOCDetails.DateOfBirth"}
            placeholder={"MarriageNOCDetails.DateOfBirth"}
            disabledFiled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <SelectField
            name={'church'}
            label={"MarriageNOCDetails.Church"}
            placeholder={"MarriageNOCDetails.Church"}
            disabled={disabled}>
            {
              churchs?.map(church => (
                <Option
                  value={church?.id}
                  key={church?.id}
                  children={church[getLocalizedKey('name')]}
                />
              ))
            }
          </SelectField>
        </Styled.Field>
        <Styled.Field>
          <RadioField
            radioSet={IS_COPTIC_ORTHODOX_RADIO}
            label={"MarriageNOCDetails.IsCopticOrthodox"}
            name={"isCopticOrthodox"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'placeOfBirth'}
            label={"MarriageNOCDetails.PlaceOfBirth"}
            placeholder={"MarriageNOCDetails.PlaceOfBirth"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <DatePickerField
            name={'baptismDate'}
            label={"MarriageNOCDetails.DateOfBaptism"}
            placeholder={"MarriageNOCDetails.DateOfBaptism"}
            disabledFiled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'baptismPlace'}
            label={"MarriageNOCDetails.PlaceOfBaptism"}
            placeholder={"MarriageNOCDetails.PlaceOfBaptism"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'egyptNationalID'}
            type={'number'}
            label={"MarriageNOCDetails.EgyptNationalID"}
            placeholder={"MarriageNOCDetails.EgyptNationalID"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'emiratesID'}
            label={"MarriageNOCDetails.EmiratesID"}
            placeholder={"MarriageNOCDetails.EmiratesID"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputPhoneField
            name={'mobileNumber'}
            label={"MarriageNOCDetails.MobileNumber"}
            placeholder={"MarriageNOCDetails.MobileNumber"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'emailAddress'}
            label={"MarriageNOCDetails.EmailAddress"}
            placeholder={"MarriageNOCDetails.EmailAddress"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'passportNumber'}
            label={"MarriageNOCDetails.PassportNumber"}
            placeholder={"MarriageNOCDetails.PassportNumber"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'educationDegree'}
            label={"MarriageNOCDetails.EducationDegree"}
            placeholder={"MarriageNOCDetails.PassportNumber"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'addressInEgypt'}
            label={"MarriageNOCDetails.AddressInEgypt"}
            placeholder={"MarriageNOCDetails.AddressInEgypt"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'addressInUAE'}
            label={"MarriageNOCDetails.AddressInUAE"}
            placeholder={"MarriageNOCDetails.AddressInUAE"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'jobInfo'}
            label={"MarriageNOCDetails.CurrentJob"}
            placeholder={"MarriageNOCDetails.CurrentJob"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'fatherOfConfession'}
            label={"MarriageNOCDetails.FatherOfConfession"}
            placeholder={"MarriageNOCDetails.FatherOfConfession"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'fatherOfConfessionChurch'}
            label={"MarriageNOCDetails.FatherOfConfessionChurch"}
            placeholder={"MarriageNOCDetails.FatherOfConfessionChurch"}
            disabled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <InputField
            name={'churchInEgypt'}
            label={"MarriageNOCDetails.ChurchInEgypt"}
            placeholder={"MarriageNOCDetails.ChurchInEgypt"}
            disabled={disabled}
          />
        </Styled.Field>
        {/*<Styled.Field>*/}
        {/*    <InputField*/}
        {/*        name={'confessionFrequency'}*/}
        {/*        label={"MarriageNOCDetails.ConfessionFrequency"}*/}
        {/*        placeholder={"MarriageNOCDetails.ConfessionFrequency"}*/}
        {/*        // disabled={disabled}*/}
        {/*    />*/}
        {/*</Styled.Field>*/}
        {/*<Styled.Field>*/}
        {/*    <InputField*/}
        {/*        name={'communionFrequency'}*/}
        {/*        label={"MarriageNOCDetails.CommunionFrequency"}*/}
        {/*        placeholder={"MarriageNOCDetails.CommunionFrequency"}*/}
        {/*        // disabled={disabled}*/}
        {/*    />*/}
        {/*</Styled.Field>*/}
        <Styled.Field>
          <DatePickerField
            name={'uaeArrivalDate'}
            label={"MarriageNOCDetails.UAEArrivalDate"}
            placeholder={"MarriageNOCDetails.UAEArrivalDate"}
            disabledFiled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <DatePickerField
            name={'uaeDepartDate'}
            label={"MarriageNOCDetails.UAEDepartDate"}
            placeholder={"MarriageNOCDetails.UAEDepartDateHint"}
            disabledFiled={disabled}
          />
        </Styled.Field>
        <Styled.Field>
          <SelectField
            label={"MarriageNOCDetails.MilitaryStatus"}
            placeholder={"MarriageNOCDetails.MilitaryStatus"}
            name={"militaryStatus"}
            disabled={disabled}>
            {
              MILITARY_STATUS.map(({value, label}) => (
                <Option value={value} key={label}>
                  <FormattedMessage id={label}/>
                </Option>
              ))
            }
          </SelectField>
        </Styled.Field>
      </Styled.FieldsWrap>
    </MarriageNocDetailsSection>);
};

export default MarriageNocDetailsPersonalInfo;
