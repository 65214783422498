import moment from "moment";
import includes from "lodash/includes";

export const isBookingPast = ({ event: { date } }) => moment().diff(date) > 0;

export const isBookingCanModify = ({ event: { date } }) =>
  moment().diff(moment(date).startOf("day")) > 0;

export const isMemberAttend = (booking, memberId) =>
  includes(booking?.attendedMembers, memberId);
