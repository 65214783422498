import React from 'react';

import ModalWrapper from "../../../../hoc/withModal";
import {MODALS_ID} from "../../../../constants/constants";
import ForgotPasswordForm from '../ForgotPassword.Form/ForgotPassword.Form'

const ResendEmailModal = () => (
    <ModalWrapper
        component={ForgotPasswordForm}
        modalId={MODALS_ID.FORGOT_PASSWORD_POPUP}
    />
);

export default ResendEmailModal;

