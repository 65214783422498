import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { familySelector } from "../../services/family/family.selector";
import Styled from "./styled";
import { createMarriageNocDraftAction } from "../../services/marriageNoc/marriageNoc.actions";
import {FormattedMessage} from "react-intl";
import StyledPageTitle from "../styled/StyledPageTitle";

const MarriageNocModalContent = () => {
  const family = useSelector(familySelector);
  const members = [
    family.mainMember,
    ...(family.spouse ? [family.spouse] : []),
    ...(family.grandParents.length ? family.grandParents : []),
    ...(family.children.length ? family.children : []),
  ];

  const dispatch = useDispatch();

  const handleClick = (id) => {
    dispatch(createMarriageNocDraftAction({ member: id }));
  };

  return (
    <Styled.ModalWrapper>
      <Styled.ModalHeader>
        <FormattedMessage id={"MarriageNOC.ModalHeader"} />
      </Styled.ModalHeader>
      <Styled.MembersList>
        {members.map((member) => (
          <Styled.MemberRow onClick={() => handleClick(member?.id)}>
            <img src={member?.profilePic?.url} />
            {member.firstName} {member.lastName}
          </Styled.MemberRow>
        ))}
      </Styled.MembersList>
    </Styled.ModalWrapper>

  );
};

export default MarriageNocModalContent;
