import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";

import { setNoChildrenAction } from '../../../services/children/children.action';
import { familyChildrenSelector } from "../../../services/children/children.selector";
import { urlLocations } from "../../../routes/urlLocations";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Button from "../../../components/Button/Button";
import StyledPageTitle from '../../styled/StyledPageTitle';
import StyledFormLastCol from '../styled/StyledFormLastCol';
import StyledCardsList from '../styled/StyledCardsList';
import StyledCardItem from '../styled/StyledCardItem';
import StyledCardItemContent from '../styled/StyledCardItemContent';
import StyledCardItemTxt from '../styled/StyledCardItemTxt';
import StyledCardItemImg from '../styled/StyledCardItemImg';
import StyledChildrenList from './styled/StyledChildrenList';
import StyledAddCardItem from '../styled/StyledAddCardItem';
import {AVATAR_URL} from "../../../constants/constants";

const ChildrenList  = ({ children, setNoChildren, numberOfChildren }) => {
    return (
        <StyledChildrenList>

            <StyledPageTitle>
                <FormattedMessage
                    id="FamilyPage.Child.ListTitle"
                />
            </StyledPageTitle>

            { !children.length && <Checkbox
                checked={numberOfChildren === 0}
                disabled={numberOfChildren === 0}
                onChange={event => {
                    event.target.checked && setNoChildren()
                }}
            >
                <FormattedMessage
                    id="FamilyPage.Child.NoChildren"
                />
            </Checkbox> }

            <StyledCardsList>

            { children?.map(child =>
                (
	            <StyledCardItem key={child.id}>
                    <Link to={`${urlLocations.child}/${child.id}`} >
                    <StyledCardItemImg><img src={child.profilePic?.url ?? AVATAR_URL} alt=""/></StyledCardItemImg>

                    <StyledCardItemContent>
                        <StyledCardItemTxt>{ child.firstName  }</StyledCardItemTxt>
                        <StyledCardItemTxt>{ child.secondName  }</StyledCardItemTxt>
                        <StyledCardItemTxt>{ child.lastName }</StyledCardItemTxt>
                    </StyledCardItemContent>

                    </Link>
	            </StyledCardItem>
                )) }

                <StyledAddCardItem>
                    <Link to={urlLocations.child}>
                        <FormattedMessage
                            id="FamilyPage.Child.AddChild"
                        />
                    </Link>
                </StyledAddCardItem>
            </StyledCardsList>

                <StyledFormLastCol>

                    <Link to={urlLocations.parentsList}>
                        <Button size="small">
                            <FormattedMessage
                                id="FamilyPage.Child.Next"
                            />
                        </Button>
                    </Link>
                </StyledFormLastCol>
        </StyledChildrenList>
    )
};


export default connect(
    createStructuredSelector({
        children: familyChildrenSelector,
        numberOfChildren: state => state.family?.numberOfChildren
    }),
    { setNoChildren : setNoChildrenAction }
)(ChildrenList)

