import React from 'react';
import StyledAside from './styled/StyledAside';
import StyledAsideContent from './styled/StyledAsideContent';
import StyledLogoDescription from './styled/StyledLogoDescription';
import StyledLogoDescription2 from './styled/StyledLogoDescription2';
import { FormattedMessage } from "react-intl";
import Logo from "../../../components/Logo/Logo";

import AsideAccordion from "./AsideAccordion/AsideAccordion";

const Aside = () => {
    return(
        <StyledAside>
            <StyledAsideContent>
                <Logo />
                <StyledLogoDescription><FormattedMessage id="MainPage.Description" /></StyledLogoDescription>
                <StyledLogoDescription2><FormattedMessage id="MainPage.Description2" /></StyledLogoDescription2>
                <AsideAccordion/>
            </StyledAsideContent>
        </StyledAside>
    )
};


export default Aside;