import Input from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';

const StyledInputPhoneField = styled(Input)`
  width: 100%;
  max-width: 208px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #d6e1e9 !important;
  background-color: #ffffff;
 
`
export default StyledInputPhoneField;
