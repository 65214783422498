import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledBookingCardContent = styled.div`
    display: inline-block;
    width: calc(100% - 207px);
    position: relative;
     
    @media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;
		width: 100%;
	}
`

export default StyledBookingCardContent;


