import React from 'react';
import PhotoSection from "../FieldsSections/PhotoSection";
import { Link } from 'react-router-dom';
import { PARENT_MODEL } from "../../../constants/models";
import {compose} from "redux";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import {urlLocations} from "../../../routes/urlLocations";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {familyParentSelector} from "../../../services/parents/parents.selector";
import ParentInfoForm from "./ParentInfoForm/ParentInfoForm";
import { FILES_TYPE, FORMS_NAME } from "../../../constants/constants";
import StyledGoBackBtn from '../styled/StyledGoBackBtn';
import StyledPageTitle from '../../styled/StyledPageTitle';
import {familyCountrySelector} from "../../../services/family/family.selector";

const ParentInfo = ({ parent, country }) => {
    return (
        <div>

            <StyledPageTitle>
                <FormattedMessage
                    id="FamilyPage.Parent.Title"
                />
            </StyledPageTitle>

            <StyledGoBackBtn>
                <Link to={urlLocations.childrenList} >
                    <FormattedMessage
                        id="FamilyPage.Child.BackToList"
                    />
                </Link>
            </StyledGoBackBtn>

            {!!country?.isMain &&
                <PhotoSection
                    name={FILES_TYPE.PROFILE_PIC}
                    formName={FORMS_NAME.PARENTS}
                />
            }
            <ParentInfoForm
                formName={FORMS_NAME.PARENTS}
                initialValues={{ ...( parent ? parent : PARENT_MODEL) }}
                visaType={parent ? parent.visaType : PARENT_MODEL.visaType}
                nationality={parent ? parent.nationality : PARENT_MODEL.nationality}
                country={country}
            />
        </div>
    )
};

export default compose(
    withRouter,
    connect(
        createStructuredSelector(
            {
                parent: (state, { match: { params: { id } } }) => familyParentSelector(state, id),
                country: familyCountrySelector
            }
        )
    )
)(ParentInfo);
