// export const API_PATH = "https://api.uaecopts.com";
// export const API_PATH = "https://api2.uaecopts.com";
// export const API_PATH = "http://localhost:1338";

// export const API_PATH = process.env.BASE_URL || "http://54.228.176.110:1339";
export const API_PATH = process.env.REACT_APP_BASE_URL || "https://api2.uaecopts.com";

export const AVATAR_URL =
  "https://uae-copts.s3.eu-west-1.amazonaws.com/user_19317be6da.png";

export const MODALS_ID = {
  LITURGY_BOOKING_MODAL: "LITURGY_BOOKING_MODAL",
  RESEND_EMAIL_POPUP: "RESEND_EMAIL_POPUP",
  FORGOT_PASSWORD_POPUP: "FORGOT_PASSWORD_POPUP",
  BOOKING_MODIFY_MODAL: "BOOKING_MODIFY_MODAL",
  LITURGY_BOOKED_MODAL: "LITURGY_BOOKED_MODAL",
  DISCLAIMER_MODAL: "DISCLAIMER_MODAL",
  BOOKING_CANCEL_MODAL: "BOOKING_CANCEL_MODAL",
  MARRIAGE_NOC_MODAL: "MARRIAGE_NOC_MODAL",
};

export const STORAGE_TYPE = {
  LOCAL_STORAGE: "localStorage",
  SESSION_STORAGE: "sessionStorage",
};

export const FAMILY_TYPE = {
  family: "family",
  alone: "alone",
};

export const DEACON_RADIO = [
  { value: true, label: "FamilyPage.Form.Deacon.Yes" },
  { value: false, label: "FamilyPage.Form.Deacon.No" },
];

export const FAMILY_STATUSES = [
  { value: "single", label: "FamilyPage.Form.SocialStatus.Single" },
  { value: "married", label: "FamilyPage.Form.SocialStatus.Married" },
  { value: "widow", label: "FamilyPage.Form.SocialStatus.Widow" },
  { value: "other", label: "FamilyPage.Form.SocialStatus.Other" },
];

export const VISA_TYPES = [
  {
    value: "residence",
    label: "FamilyPage.Form.VisaType.Residence",
  },
  {
    value: "visit",
    label: "FamilyPage.Form.VisaType.Visit",
  },
  {
    value: "other",
    label: "FamilyPage.Form.VisaType.Other",
  },
];

export const VISA_TYPES_VALUES = {
  residence: "residence",
  visit: "visit",
  other: "other",
};

export const NATIONALITIES = [
  { value: "other", label: "FamilyPage.Form.Nationality.Other" },
  { value: "egyptian", label: "FamilyPage.Form.Nationality.Egyptian" },
];

export const NATIONALITIES_VALUES = {
  other: "other",
  egyptian: "egyptian",
};

export const SPOUSE_STATUS = [
  {
    value: "available",
    label: "FamilyPage.Spouse.SpouseStatus.Available",
  },
  {
    value: "passedAway",
    label: "FamilyPage.Spouse.SpouseStatus.PassedAway",
  },
  {
    value: "notAvailable",
    label: "FamilyPage.Spouse.SpouseStatus.NotAvailable",
  },
];

export const SPOUSE_STATUS_VALUES = {
  available: "available",
  passedAway: "passedAway",
  notAvailable: "notAvailable",
};

export const FORMS_NAME = {
  MAIN_MEMBER: "mainMember",
  SPOUSE: "spouse",
  CHILDREN: "children",
  PARENTS: "grandParents",
  MARRIAGE_NOC: "marriageNOC",
};

export const FILES_TYPE = {
  PROFILE_PIC: "profilePic",
  NATIONAL_ID: "nationalIDCopy",
  EMIRATES_ID: "emiratesIDCopy",
  MARRIAGE_NOC_DOCUMENTS: "documents",
  MARRIAGE_NOC_PHOTO: "photo",
};

export const LOADERS = {
  bookingEvent: "bookingEvent",
  login: "login",
  availability: "availability",
  marriageNOC: "marriageNOC",
};

export const STATUS = {
  PENDING: "PENDING",
  DRAFT: "DRAFT",
  NEED_MORE_INFO: "NEED_MORE_INFO",
  REJECTED: "REJECTED",
  READY: "READY",
  HANDED_OVER: "HANDED_OVER",
  CANCELED: "CANCELED",
};

export const IS_COPTIC_ORTHODOX_RADIO = [
  { value: true, label: "MarriageNOCDetails.IsCopticOrthodox.Yes" },
  { value: false, label: "MarriageNOCDetails.IsCopticOrthodox.No" },
];

export const PREVIOUS_ENGAGEMENT_RADIO = [
  { value: false, label: "MarriageNOCDetails.PreviousEngagement.No" },
  { value: true, label: "MarriageNOCDetails.PreviousEngagement.Yes" },
];

export const PREVIOUS_MARRIAGE_RADIO = [
  { value: false, label: "MarriageNOCDetails.PreviousMarriage.No" },
  { value: true, label: "MarriageNOCDetails.PreviousMarriage.Yes" },
];

export const ANOTHER_MARRIAGE_RADIO = [
  { value: false, label: "MarriageNOCDetails.AnotherMarriageBeforeWidowed.No" },
  { value: true, label: "MarriageNOCDetails.AnotherMarriageBeforeWidowed.Yes" },
];

export const IS_DIVORCED_RADIO = [
  { value: false, label: "MarriageNOCDetails.IsDivorced.No" },
  { value: true, label: "MarriageNOCDetails.IsDivorced.Yes" },
];

export const CURRENT_STATUS = [
  { value: "WIDOW", label: "MarriageNOCDetails.CurrentStatus.Widow" },
  { value: "DIVORCED", label: "MarriageNOCDetails.CurrentStatus.Divorced" },
];

export const PREV_MARRIAGE_LOCATION = [
  {
    value: "INSIDE_EGYPT",
    label: "MarriageNOCDetails.PrevMarriageLocation.Inside",
  },
  {
    value: "OUTSIDE_EGYPT",
    label: "MarriageNOCDetails.PrevMarriageLocation.Outside",
  },
];

export const MILITARY_STATUS = [
  {
    value: "NA",
    label: "MarriageNOCDetails.MilitaryStatus.NA",
  },
  {
    value: "NOT_NEEDED",
    label: "MarriageNOCDetails.MilitaryStatus.NotNeeded",
  },
  {
    value: "DONE",
    label: "MarriageNOCDetails.MilitaryStatus.Done",
  },
  {
    value: "TEMP_EXEMPTION",
    label: "MarriageNOCDetails.MilitaryStatus.TempExemption",
  },
];

export const STATUS_LOCALIZATION = {
  [STATUS.PENDING]: "Status.Pending",
  [STATUS.DRAFT]: "Status.Draft",
  [STATUS.NEED_MORE_INFO]: "Status.NeedMoreInfo",
  [STATUS.REJECTED]: "Status.Rejected",
  [STATUS.READY]: "Status.Ready",
  [STATUS.HANDED_OVER]: "Status.HeadedOver",
  [STATUS.CANCELED]: "Status.Canceled",
};
