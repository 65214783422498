export default {
  "Success": "تم",
  "Logo.Page.Alt": "UAE Copts",
  "Logo.Main.Alt": "UAE Copts",
  "LOGIN.SUCCESS": "We send you email in ar :)",
  "MainPage.Description":
    "هذه هي المنصة الإلكترونية التي ستتمكن من خلالها من حجز حضور القداسات في ظل الإجرائات الإحترازية الحالية." +
    "\nلكي تتمكن من الحجز، يجب أولا كتابة بينات افراد الأسرة المقيمين معك في الإمارات",
  "MainPage.Description2":
    "" +
    "برجاء تجهيز البيانات والصور الآتية لكل فرد في الأسرة قبل البدء:" +
    "\nصورة شخصية • " +
    "\nرقم الهوية الإماراتية وصوره امامية وخلفيه منها • " +
    "\nالرقم القومي المصري وصوره امامية وخلفية منه • " +
    "\nرقم جواز السفر لغير المصريين •",

  "MainPage.ContactPerson": "لمزيد من المعلومات اتصل ب",
  //Header
  "Header.Logout": "تسجيل الخروج",
  "Header.PrivacyPolicy": "سياسة الخصوصية",
  //Registration
  "Registration.Title": "إنشاء حساب جديد",
  "Registration.Email.Placeholder": "بريدك الإلكتروني",
  "Registration.Password.Placeholder": "كلمة المرور",
  "Registration.Status.Placeholder": "الحالة الإجتماعية",
  "Registration.Country": "الدولة",
  "Registration.Button": "تسجيل",
  "Registration.Agreement": "أقر وأوافق على ",
  "Registration.TermsAndConditions": " الشروط والأحكام",
  "Registration.Bottom": "لديك حساب معنا؟ ",
  "Registration.Bottom.SignIn": "تسجيل الدخول",
  "Registration.FamilyStatus.Alone": "أعزب",
  "Registration.FamilyStatus.Family": "متزوج",

  //REGISTRATION NOTIFICATIONS
  "Registration.Notification.SuccessMessage":
    "Registration successfully complete. We send you an email to confirm your email.",
  "Registration.Notification.Success": "Email Confirmation needed",
  "Registration.Notification.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",
  "Registration.Notification.Error": "Error",

  //Login
  "Login.Title": "مرحبا بك",
  "Login.Email.Placeholder": "بريدك الإلكتروني",
  "Login.Password.Placeholder": "كلمة المرور",
  "Login.Button": "تسجيل الدخول",
  "Login.success": "We send you email :)",
  "Login.CTA": "ليس لديك حساب ؟",
  "Login.CTA.Register": " إنشاء حساب جديد",
  "Login.Forgot": "هل نسيت كلمة المرور؟",

  "Disclaimer.Title": "رجاء عدم الحجز في الحالات التالية:",
  "Disclaimer.Desc": ` 1- إذا كنت تشعر بأي أعراض مرضية أو سخونة أو أي مرض في الجهاز التنفسي مثل صعوبة التنفس أو أي من أعراض الكورونا
2-  اذا كنت قد خالطت أي أشخاص مصابين بمرض الكورونا 
3- اذا كنت تعاني من أي امراض مزمنة أو مناعة منخفضة  
  `,

  //LOGIN  NOTIFICATIONS
  "Login.Notification.SuccessMessage": "تم تسجيل الدخول بنجاح",
  "Login.Notification.Success": "تسجيل الدخول",
  "Login.Notification.ErrorMessage":
    "There is something wrong with the data you have entered, please check all fields in red",
  "Login.Notification.Error": "حدث خطأ",

  //Home page
  "HomePage.Family.Title": "تعديل بيانات الأسرة",
  "HomePage.Family.Desc":
    "هنا يمكنك تعديل البيانات الخاصة بأفراد الأسرة في أي وقت",
  "HomePage.Liturgy.Title": "حجز القداسات",
  "HomePage.Liturgy.Desc":
    "هنا يمكنك عرض القداسات المتاحة في كنائس الإمارات وحجز حضور أي منها",
  "HomePage.Bottom.Title": "قواعد الحجز",
  "HomePage.Bottom.Desc":
    "كل فرد من حقة حجز حضور قداس واحد فقط." +
    "\nالأطفال آقل من ١٢ سنه غير مسموح لهم بالحضور" +
    "\nغير ممكن حضور اكثر من قداس في كنائس مختلفة في الإمارات" +
    "\nحجز العشية منفصل عن حجز القداسات ولا يؤثر عليه",
  "HomePage.Bottom.Desc2": null,
  "HomePage.Booking.Title": "تعديل الحجوزات",
  "HomePage.Booking.Desc":
    "هنا يمكنك عرض الحجوزات التي قمت بها، وتعديل أو إلغاء اي منها",
  "HomePage.Others.Title": "خدمات أخرى",
  "HomePage.Others.Desc":
    "هنا يمكنك عرض خدمات اخرى مثل طلب شهادات خلو الموانع",

  //Family Page
  "FamilyPage.Aside.PersonalInfo": "بياناتك الشخصية",
  "FamilyPage.Aside.SpouseInfo": "بيانات شريك الحياة",
  "FamilyPage.Aside.ChildrenInfo": "بيانات الأبناء",
  "FamilyPage.Aside.ParentsInfo": "بيانات الآباء",
  "FamilyPage.Aside.AddressInfo": "العنوان",
  "FamilyPage.Aside.BackButton": "الصفحة الرئيسية",

  "FamilyPage.Section.Main": "البيانات الرئيسية",
  "FamilyPage.Section.Contact": "بيانات التواصل",
  "FamilyPage.Section.Identification": "الوثائق الرسمية",
  "FamilyPage.Section.Additional": "بيانات أخرى",

  //Main section
  "FamilyPage.Personal.Title": "بياناتك الشخصية",
  "FamilyPage.Personal.YourPhoto": "الصورة الشخصية",
  "FamilyPage.Form.Additional": "بيانات أخرى",
  "FamilyPage.Form.FirstName": "الاسم الأول",
  "FamilyPage.Form.SecondName": "الاسم الثاني",
  "FamilyPage.Form.LastName": "اسم العائلة",
  "FamilyPage.Form.Gender": "النوع",
  "FamilyPage.Form.DateOfBirth": "تاريخ الميلاد",
  "FamilyPage.Form.Male": "ذكر",
  "FamilyPage.Form.Female": "أنثى",
  //Contact
  "FamilyPage.Form.Email": "البريد الإلكتروني",
  "FamilyPage.Form.Phone": "الموبايل",
  "FamilyPage.Form.Whatsapp": "الواتساب (لو مختلف)",

  "FamilyPage.Form.SocialStatus": "الحالة الإجتماعية",
  "FamilyPage.Form.SocialStatus.Single": "أعزب",
  "FamilyPage.Form.SocialStatus.Widow": "أرمل",
  "FamilyPage.Form.SocialStatus.Married": "متزوج",
  "FamilyPage.Form.SocialStatus.Other": "أخرى",

  "FamilyPage.Form.VisaType": "نوع التأشيرة",
  "FamilyPage.Form.VisaType.Residence": "مقيم",
  "FamilyPage.Form.VisaType.Visit": "زائر",
  "FamilyPage.Form.VisaType.Other": "أخرى",
  "FamilyPage.Form.EmiratesID": "الهوية الإماراتية",
  "FamilyPage.Form.NationalID": "الرقم القومي المصري",

  "FamilyPage.Form.FatherOfConfession": "أب الإعتراف",
  "FamilyPage.Form.Service": "الخدمة الكنيسة",
  "FamilyPage.Form.WorkingField": "مجال العمل",
  "FamilyPage.Form.Nationality": "الجنسية",
  "FamilyPage.Form.Nationality.Egyptian": "مصري",
  "FamilyPage.Form.Nationality.Other": "أخرى",
  "FamilyPage.Form.PassportNumber": "رقم جواز السفر",
  "FamilyPage.Form.Deacon": "شماس",
  "FamilyPage.Form.Deacon.Yes": "نعم",
  "FamilyPage.Form.Deacon.No": "لا",
  "FamilyPage.Form.SaveButton": "حفظ البيانات",
  "FamilyPage.Form.UploadPhoto": "الصورة الشخصية",
  "FamilyPage.Form.PhotoInfo": "الحد الأقصى 2 ميجا بايت للصوره",
  "FamilyPage.Form.DocumentsInfo":
    "الحد الأقصى 2 ميجا بايت للصوره (JPG, PNG, أو PDF)",
  "FamilyPage.Form.UploadNationalID": "صورة الرقم القومي المصري",
  "FamilyPage.Form.UploadEmirateID": "صورة الهوية الإماراتية",

  //PERSONAL NOTIFICATIONS
  "FamilyPage.Personal.Success": "تم",
  "FamilyPage.Personal.SuccessMessage": "تم حفظ البيانات بناجاح",
  "FamilyPage.Personal.Error": "خطأ",
  "FamilyPage.Personal.ErrorMessage":
    "يوجد خطأ في البيانات، براجاء مراجعة البيانات باللون الأحمر",

  //SPOUSE
  "FamilyPage.Spouse.Title": "بيانات شريك الحياة",
  "FamilyPage.Spouse.YourPhoto": "الصورة الشخصية",
  "FamilyPage.Spouse.SpouseStatus": "حالة شريك الحياة",
  "FamilyPage.Spouse.SpouseStatus.Available": "متاح",
  "FamilyPage.Spouse.SpouseStatus.PassedAway": "متوفي",
  "FamilyPage.Spouse.SpouseStatus.NotAvailable": "غير موجود",

  //SPOUSE NOTIFICATIONS
  "FamilyPage.Spouse.Success": "تم",
  "FamilyPage.Spouse.SuccessMessage": "تم حفظ البيانات بناجاح",
  "FamilyPage.Spouse.Error": "خطأ",
  "FamilyPage.Spouse.ErrorMessage":
    "يوجد خطأ في البيانات، براجاء مراجعة البيانات باللون الأحمر",

  //CHILDREN
  "FamilyPage.Child.ListTitle": "بيانات الأبناء",
  "FamilyPage.Child.Title": "بيانات الأبناء",
  "FamilyPage.Child.YourPhoto": "الصورة الشخصية",
  "FamilyPage.Child.NoChildren": "لا يوجد أطفال",
  "FamilyPage.Child.AddChild": "إضافة طفل",
  "FamilyPage.Child.Next": "التالي",
  "FamilyPage.Child.BackToList": "العودة إلى القائمة",

  //CHILDREN NOTIFICATIONS
  "FamilyPage.Child.Success": "تم",
  "FamilyPage.Child.SuccessMessage": "تم حفظ البيانات بناجاح",
  "FamilyPage.Child.Error": "خطأ",
  "FamilyPage.Child.ErrorMessage":
    "يوجد خطأ في البيانات، براجاء مراجعة البيانات باللون الأحمر",

  //PARENTS
  "FamilyPage.Parent.ListTitle": "بيانات الآباء",
  "FamilyPage.Parent.Title": "بيانات الآباء",
  "FamilyPage.Parent.NoParents": "لا يوجد آباء معنا في في نفس البيت",
  "FamilyPage.Parent.AddParent": "إضافة أب/أم",
  "FamilyPage.Parent.Next": "التالي",
  "FamilyPage.Parent.Finish": "إنهاء",

  //PARENTS NOTIFICATIONS
  "FamilyPage.Parent.Success": "تم",
  "FamilyPage.Parent.SuccessMessage": "تم حفظ البيانات بناجاح",
  "FamilyPage.Parent.Error": "خطأ",
  "FamilyPage.Parent.ErrorMessage":
    "يوجد خطأ في البيانات، براجاء مراجعة البيانات باللون الأحمر",

  //ADDRESS
  "FamilyPage.Address.Title": "بيانات العنوان",
  "FamilyPage.Address.CurrentLocation": "العنوان الحالي",
  "FamilyPage.Address.AddCurrentLocation":
    "في المنزل الآن؟ سجل الموقع الجغرافي",
  "FamilyPage.Address.CurrentLocationAdded": "تم حفظ الموقع الجغرافي",
  "FamilyPage.Address.Additional": "بيانات أخرى",

  "FamilyPage.Address.Emirates": "الإمارة",
  "FamilyPage.Address.Address": "العنوان",
  "FamilyPage.Address.Area": "المنطقة",
  "FamilyPage.Address.Church": "الكنيسة",
  "FamilyPage.Address.Save": "حفظ",

  //ADDRESS NOTIFICATIONS
  "FamilyPage.Address.Success": "تم",
  "FamilyPage.Address.SuccessMessage": "تم حفظ البيانات بناجاح",
  "FamilyPage.Address.Error": "خطأ",
  "FamilyPage.Address.ErrorMessage":
    "يوجد خطأ في البيانات، براجاء مراجعة البيانات باللون الأحمر",

  //Liturgy
  "LiturgyPage.Title": "إختر الصلاه التي يناسبك",
  "LiturgyPage.Exclusive": "متاح لشعب { churchName } فقط",
  "LiturgyPage.NotRestricted": "حجز مفتوح",
  "LiturgyPage.Booked": "محجوز",
  "LiturgyPage.Button.Book": "إحجز",
  "LiturgyPage.Button.Booked": "قمت بالحجز",
  "LiturgyPage.Button.FullyBooked": "محجوز بالكامل",
  "LiturgyPage.Modal.Loading": "جاري التحميل",
  "LiturgyPage.Modal.Church": "الكنيسة",
  "LiturgyPage.Modal.Date": "التاريخ",
  "LiturgyPage.Modal.Time": "الوقت",
  "LiturgyPage.Modal.Attend": "من سيحضر",
  "LiturgyPage.Modal.BookNow": "احجز الآن",
  "LiturgyPage.Modal.Close": "اغلاق",
  "LiturgyPage.Aside.Back": "العودة",
  "LiturgyPage.Aside.Count": "{ count, plural, one { صلاه } other { صلوات }  }",
  "LiturgyPage.NoLiturgy": "لا يوجد صلوات متاحة في هذه الكنيسة",
  "LiturgyPage.Filter.Liturgies": "قداسات وصلوات",
  "LiturgyPage.Filter.Meetings": "اجتماعات وخدمات",
  "LiturgyPage.Filter.SundaySchools": "التربية الكنسية",

  //Liturgy NOTIFICATIONS
  "LiturgyPage.Notification.Success": "تم",
  "LiturgyPage.Notification.SuccessMessage":
    "تم الحجز بنجاح. سوف يتم ارسال ايميل تاكيد للحجز ويوجد به رقم الحجز الخاص بك",
  "LiturgyPage.Notification.Error": "عذرا",
  "LiturgyPage.Notification.ErrorMessage":
    "يوجد خطأ اثناء الحجز. برجاء المحاولة مرة أخرى او طلب المساعدة من الخادم المسؤل",
  "Event.Booking.noAvailSpace":
    "يوجد ضغط كبير على حجز هذا القداس وتم حجزه بالكامل قبل ان يتم تأكيد حجزك، برجاء تحديث الصفحة إختيار قداس آخر",
  "Event.ModifyBooking.noAvailSpace":
    "هذا القداس محجوز بالكامل، ولا يمكن اضافة افراد جديدة",
  "LiturgyPage.BookedModal.Title": "يوجد حجز",
  "LiturgyPage.BookedModal.CloseButton": "إغلاق",
  "LiturgyPage.BookedModal.Text":
    "لقد قمت بحجز هذا القداس من قبل، يمكنك إضافة/إلغاء أفراد من صفحة { link }",
  "LiturgyPage.BookedModal.Link": "تعديل الحجوزات",

  //Bookings
  "BookingsPage.Title": "حجوزاتك",
  "BookingsPage.Back": "العودة",
  "BookingsPage.Canceled": "ملغي",
  "BookingsPage.Church": "الكنيسة",
  "BookingsPage.Date": "التاريخ",
  "BookingsPage.Time": "الوقت",
  "BookingsPage.Attend": "الحضور",
  "BookingsPage.Cancel": "إلغاء الحجز",
  "BookingsPage.Modify": "تعديل الحجز",
  "BookingsPage.Empty":
    "لم تقم بأي حجوزات. يمكنك حجز حضور الصلوات من القائمة الرئيسية",

  //
  "BookingsPage.Notification.Cancel.Success": "تم",
  "BookingsPage.Notification.Cancel.SuccessMessage": "تم إلغاء الحجز بنجاح",

  //Errors
  "Auth.form.error.email.taken": "يوجد حساب بنفس البريد الإلكتروني",
  "Auth.form.error.password.provide": "برجاء كتابة كلمة المرور",
  "Auth.form.error.username.taken": "يوجد حساب بنفس البريد الإلكتروني",
  "Auth.form.error.email.provide": "برجاء كتابة البريد الإلكتروني",
  "Auth.form.error.invalid": "البريد الألكتروني أو كلمة المرور غير صحيحة",
  "Family.member.dateOfBirth.required": "مطلوب",
  "Family.member.gender.required": "مطلوب",
  "Family.member.mobile.required": "مطلوب",
  "Family.member.mobile.exists":
    "الموبايل موجود من قبل. برجاء التاكد من تسجيل مره واحدة فقط",
  "Family.member.nationality.required": "مطلوب",
  "Family.member.passportNumber.required": "مطلوب",
  "Family.member.profilePic.required": "مطلوب",
  "Family.member.visaType.required": "مطلوب",
  "Family.member.email.required": "مطلوب",
  "Family.member.email.exists":
    "البريد الإلكتروني موجود من قبل. برجاء التاكد من تسجيل مره واحدة فقط",
  "Family.member.nationalIDNumber.required":
    "الرقم القومي موجود من قبل. برجاء التاكد من تسجيل مره واحدة فقط",
  "Family.member.firstName.required": "مطلوب",
  "Family.member.lastName.required": "مطلوب",
  "Family.member.secondName.required": "مطلوب",
  "Family.member.email.notValid": "بريد إلكتروني غير صحيح",
  "Family.member.emiratesID.required": "مطلوب",
  "Family.member.emiratesIDCopy.required": "مطلوب",
  "Family.member.nationalIDCopy.required": "مطلوب",
  "Family.member.emiratesID.exists":
    "رقم الهوية الإمراتي موجود من قبل. برجاء التاكد من تسجيل مره واحدة فقط",
  "Family.member.fullName.exists":
    "الاسم موجود من قبل. برجاء التاكد من تسجيل مره واحدة فقط",
  "Family.address.emirate.required": "مطلوب",
  "Family.address.addressLine.required": "مطلوب",
  "Family.address.area.required": "مطلوب",
  "Family.address.church.required": "مطلوب",
  "Password.Validation":
    "كلمة المرور ضعيفة، يجب ان تكون باللغة الانجليزيه، تحتوي علي الاقل ٥ حروف وارقام ويجب ان تحتوي على حروف كبيره وصغيره",

  //RESEND EMAIL
  "ResendEmail.Title": "تأكيد عنوان بريدك الإلكتروني",
  "ResendEmail.Description":
    "هذا البريد الإلكتروني غير مؤكد. يتوجب عليك تأكيد بريدك الإلكتروني لتتمكن من استخدام المنصة" +
    "\nلقد تم إرسال رابط التأكيد لبريدك الإلكتروني بعد التسجيل، برجاء مراجعة الرسائل في بريدك الإلكتروني والضغط على زر التأكيد" +
    "\nإذا لم تجد رسالتنا البريدية؟ يرجى التحقق من ملف رسائل Spam أو Junk" +
    "\n\nيمكنك طلب إعادة إرسال ايميل التأكيد مره اخرى عن طريق كتابة بريدك الإلكتروني",
  "ResendEmail.Resend": "إعادة الإرسال",
  "ResendEmail.Cancel": "إغلاق",
  "ResendEmail.Notification.Success": "تم",
  "ResendEmail.Notification.SuccessMessage": "تم إرسال بريد التأكيد بنجاح",
  "ResendEmail.Notification.Error": "خطأ",
  "ResendEmail.Notification.ErrorMessage":
    "يوجد خطأ اثناء الإرسال. برجاء المحاولة مرة أخرى او طلب المساعدة من الخادم المسؤل",
  //Forgot password
  "ForgotPassword.Title": "نسيت كلمة المرور",
  "ForgotPassword.Description":
    "برجاء كتابة بريدك الإلكتروني، وسوف نقوم بإرسال إيميل لتتمكن من تغير كلمة المرور",
  "ForgotPassword.Send": "ارسال",
  "ForgotPassword.Close": "إغلاق",
  "ForgotPassword.Notification.Success": "تم",
  "ForgotPassword.Notification.SuccessMessage":
    "تم إرسال البريد الإلكتروني بنجاح",
  "ForgotPassword.Notification.Error": "خطأ",
  "ForgotPassword.Notification.ErrorMessage":
    "يوجد خطأ اثناء الإرسال. برجاء المحاولة مرة أخرى او طلب المساعدة من الخادم المسؤل",
  "Auth.form.error.email.format":
    "بريد إلكتروني غير صحيح، برجاء كتابتة بطريقة صحيحة",
  "Auth.form.error.user.not-exist":
    "لا يوجد حساب لهذا البريد الإلكتروني، برجاء المراجعة او إنشاء حساب جديد",

  //Reset password
  "ResetPassword.Title": "تغير كلمة المرور",
  "ResetPassword.Description": "برجاء إختيار كلمة مرور جديدة",

  "ResetPassword.Button": "تغير",
  "ResetPassword.Notification.Success": "تم",
  "ResetPassword.Notification.SuccessMessage": "تم تغير كلمة المرور بنجاح",
  "ResetPassword.Notification.Error": "خطأ",
  "ResetPassword.Notification.ErrorMessage":
    "يوجد خطأ اثناء التأكيد. برجاء المحاولة مرة أخرى او طلب المساعدة من الخادم المسؤل",
  "ResetPassword.Password": "كلمة المرور الجديدة",
  "ResetPassword.ConfirmPassword": "تأكيد كلمة المرور الجديدة",

  //MODIFY BOOKING
  "ModifyBooking.Title": "تعديل الحجز",
  "ModifyBooking.Description":
    "يمكنك تعديل إختيار أفراد اسرتك الذين سيحضرون القداس عن طريق إختيار الاسماء التي ستحضر.",
  "ModifyBooking.ModifyButton": "تعديل",
  "ModifyBooking.CancelButton": "إغلاق",
  "ModifyBooking.Notification.Success": "تم",
  "ModifyBooking.Notification.SuccessMessage": "تم تعديل الحجز بنجاح",
  "ModifyBooking.Notification.Error": "خطأ",
  "ModifyBooking.Notification.ErrorMessage":
    "حدث خطأ اثناء تعديل الحجز، برجاء المحاولة مرة أخرى",

  //CANCEL BOOKING
  "CancelBooking.Message": "هل تريد إلغاء الحجز؟",
  "CancelBooking.Warning": "عدم الحضور او إلغاد الحجز قبلها باقل من ٢٤ ساعة سوف يمنع الحجز الاسبوع القادم",
  "CancelBooking.Yes": "نعم، إلغاء",
  "CancelBooking.No": "لا",
  "Aside.Copyright": "© 2020 UAE Copts",

  "MarriageNOC.Header": "طلبات شهادات خلو الموانع للزواج",
  "MarriageNOC.NoPrevious": "ليس لديك أي طلبات سابقة لشهادات خلو الموانع للزواج",
  "MarriageNOC.DraftsSection": "تحت الإصدار",
  "MarriageNOC.OthersSection": "الشهادات السابقة",
  "MarriageNOC.ModalHeader": "إختر فرد العائلة",

  // الحالة
  "Status.Pending": "قيد المراجعة",
  "Status.Draft": "مسودة",
  "Status.NeedMoreInfo": "معلومات مطلوبة",
  "Status.Rejected": "مرفوض",
  "Status.Ready": "جاهز",
  "Status.HeadedOver": "تم التسليم",
  "Status.Canceled": "ملغى",

  // تفاصيل شهادة خلو الموانع للزواج
  "MarriageNOCDetails.Title": "تفاصيل شهادة خلو الموانع للزواج",
  "MarriageNOCDetails.ArabicOnly": "جميع المعلومات يجب أن تكون باللغة العربية فقط",
  "MarriageNOCDetails.UpdateStatus": "تحديث حالة الطلب",
  "MarriageNOCDetails.Church": "الكنيسة",
  "MarriageNOCDetails.Status": "حالة الطلب",
  "MarriageNOCDetails.PersonalInfo": "معلومات شخصية",
  "MarriageNOCDetails.FullName": "الاسم الكامل",
  "MarriageNOCDetails.DateOfBirth": "تاريخ الميلاد",
  "MarriageNOCDetails.IsCopticOrthodox": "هل أنت مسيحي اورثوذكسي؟",
  "MarriageNOCDetails.IsCopticOrthodox.Yes": "نعم",
  "MarriageNOCDetails.IsCopticOrthodox.No": "لا",
  "MarriageNOCDetails.PlaceOfBirth": "مكان الميلاد",
  "MarriageNOCDetails.DateOfBaptism": "تاريخ التعميد",
  "MarriageNOCDetails.PlaceOfBaptism": "مكان التعميد",
  "MarriageNOCDetails.EgyptNationalID": "الرقم القومي المصري",
  "MarriageNOCDetails.EmiratesID": "الهوية الإماراتية",
  "MarriageNOCDetails.MobileNumber": "رقم الموبايل",
  "MarriageNOCDetails.EmailAddress": "عنوان البريد الإلكتروني",
  "MarriageNOCDetails.PassportNumber": "رقم الجواز",
  "MarriageNOCDetails.EducationDegree": "المؤهل العلمي",
  "MarriageNOCDetails.AddressInEgypt": "العنوان في مصر",
  "MarriageNOCDetails.AddressInUAE": "العنوان في الإمارات",
  "MarriageNOCDetails.CurrentJob": "الوظيفة الحالية واسم الشركة وعنوانها",
  "MarriageNOCDetails.ChurchInEgypt": "الكنيسة في مصر",
  "MarriageNOCDetails.FatherOfConfession": "أب الاعتراف",
  "MarriageNOCDetails.FatherOfConfessionChurch": "كنيسة أب الاعتراف",
  "MarriageNOCDetails.ConfessionFrequency": "المواظبة على الاعتراف",
  "MarriageNOCDetails.CommunionFrequency": "المواظبة على التناول",
  "MarriageNOCDetails.UAEArrivalDate": "تاريخ وصولك إلى الإمارات",
  "MarriageNOCDetails.UAEDepartDate": "تاريخ مغادرة الإمارات النهائي",
  "MarriageNOCDetails.UAEDepartDateHint": "اتركه فارغا ان كنت لا تزال مقيم بالإمارات",

  "MarriageNOCDetails.PreviousEngagement": "خطوبة سابقة",
  "MarriageNOCDetails.HowManyTimes": "كم مرة",
  "MarriageNOCDetails.PreviousEngagement.Yes": "نعم",
  "MarriageNOCDetails.PreviousEngagement.No": "لا",

  "MarriageNOCDetails.PreviousMarriage": "زواج سابق",
  "MarriageNOCDetails.PreviousMarriage.Yes": "نعم",
  "MarriageNOCDetails.PreviousMarriage.No": "لا",
  "MarriageNOCDetails.PreviousMarriageField": "زواج سابق",
  "MarriageNOCDetails.PreviousMarriageLocation": "مكان الزواج السابق",
  "MarriageNOCDetails.PreviousMarriageDate": "تاريخ الزواج السابق",
  "MarriageNOCDetails.PreviousMarriageChurch": "كنيسة الزواج السابق",
  "MarriageNOCDetails.PreviousMarriageFather": "ألاب الكاهن متمم الزواج السابق",
  "MarriageNOCDetails.CurrentStatus": "الحالة الحالية",
  "MarriageNOCDetails.WidowedDate": "تاريخ الترمل",
  "MarriageNOCDetails.AnotherMarriageBeforeWidowed": "زواج آخر قبل الترمل",
  "MarriageNOCDetails.AnotherMarriageBeforeWidowed.Yes": "نعم",
  "MarriageNOCDetails.AnotherMarriageBeforeWidowed.No": "لا",
  "MarriageNOCDetails.PreviousSpouseName": "معلومات عن الزواج السابق (الاسم، الكنيسة، التاريخ)",
  "MarriageNOCDetails.Date": "التاريخ",
  "MarriageNOCDetails.Location": "المكان",

  "MarriageNOCDetails.Divorcement": "الطلاق",
  "MarriageNOCDetails.IsDivorced": "هل أنت مطلق؟",
  "MarriageNOCDetails.IsDivorced.Yes": "نعم",
  "MarriageNOCDetails.IsDivorced.No": "لا",
  "MarriageNOCDetails.Divorcement.Court": "اسم المحكمة",
  "MarriageNOCDetails.Divorcement.CaseNumber": "رقم القضية",
  "MarriageNOCDetails.Divorcement.Date": "تاريخ الطلاق",
  "MarriageNOCDetails.Divorcement.ReMarriageCertificate": "مصدر شهادة الزواج مرة أخرى وتاريخها",
  "MarriageNOCDetails.Divorcement.NumberOfChildren": "عدد الأطفال",
  "MarriageNOCDetails.Divorcement.NameAgeEtc": "الأسماء، الأعمار، هل معمدون",
  "MarriageNOCDetails.Divorcement.RelevantKindToChildren": "نوع القرابة أو النسب",

  "MarriageNOCDetails.Witnesses": "الشهود",
  "MarriageNOCDetails.FirstWitnessNationalID": "الشاهد الأول - الرقم القومي",
  "MarriageNOCDetails.FirstWitnessName": "الشاهد الأول - الاسم",
  "MarriageNOCDetails.SecondWitnessName": "الشاهد الثاني - الاسم",
  "MarriageNOCDetails.SecondWitnessNationalID": "الشاهد الثاني - الرقم القومي",

  "MarriageNOCDetails.HardCopy": "نسخة ورقية",
  "MarriageNOCDetails.CancellationProof": "إثبات الإلغاء",
  "MarriageNOCDetails.Photo": "صورة",

  "MarriageNOCDetails.CurrentStatus.Single": "أعزب",
  "MarriageNOCDetails.CurrentStatus.Divorced": "مطلق",
  "MarriageNOCDetails.CurrentStatus.Widow": "أرمل",

  "MarriageNOCDetails.UpdateInformation": "تحديث المعلومات",

  "MarriageNOCDetails.StatusUpdateSuccess": "تم تحديث الحالة بنجاح",
  "MarriageNOCDetails.DetailsUpdateSuccess": "تم تحديث التفاصيل بنجاح",

  "MarriageNOCDetails.PrevMarriageLocation.Inside": "داخل مصر",
  "MarriageNOCDetails.PrevMarriageLocation.Outside": "خارج مصر",

  "MarriageNOCDetails.MilitaryStatus": "الموقف من التجنيد",
  "MarriageNOCDetails.MilitaryStatus.NA": "أنثى",
  "MarriageNOCDetails.MilitaryStatus.NotNeeded": "غير مطلوب للتجنيد",
  "MarriageNOCDetails.MilitaryStatus.Done": "أتم الخدمة",
  "MarriageNOCDetails.MilitaryStatus.TempExemption": "إعفاء مؤقت",

  "MarriageNOCDetails.SaveDraft": "حفظ مؤقتا",
  "MarriageNOCDetails.Submit": "إرسال",

  "MarriageNOCDetails.SuccessfullyUpdate": "تم التحديث بنجاح",
  "MarriageNOCDetails.SuccessfullySubmitted": "تم الإرسال بنجاح",

  "MarriageNOCDetails.UploadDocuments": "رفع المستندات",
  "MarriageNOCDetails.UploadDocumentsHint":
    "نسخة من جواز السفر" +
    "\n نسخة من الإقامة" +
    "\n نسخة من الرقم القومي (للمصريين)" +
    "\n نسخة من الهوية الإماراتية" +
    "\n نسخة من جواز سفر الشهود",
  "MarriageNOCDetails.Documents": "المستندات",

  // صفحة قائمة شهادات خلو الموانع للزواج
  "MarriageNOC.Create": "إنشاء طلب جديد",

  // الأخطاء
  "MarriageNOC.Notification.Error": "خطأ",
  "MarriageNOC.Notification.ErrorMessage": "حدث خطأ ما، يرجى المحاولة مرة أخرى أو الاتصال بأمين الكنيسة",
  "marriage-noc.draft.member-already-has-draft": "هذا العضو لديه بالفعل لشهادة خلو الموانع للزواج قيد الإجراء، يرجى متابعة الطلب بدلاً من إنشاء واحدة جديدة"
};
