import React from 'react';

import Styled from './styled';
import {pathBuilder} from "../../routes/urlLocations";
import {FormattedMessage} from "react-intl";
import {STATUS_LOCALIZATION} from "../../constants/constants";
import StatusTag from "../../components/StatusTag/StatusTag";

const MarriageNOCCard = ({uuid, fullName, photo, status}) => {
    return (
        <Styled.Card to={pathBuilder.marriageNOCDetails(uuid)}>
            <Styled.CardImage>
                <img src={photo?.url} alt=""/>
            </Styled.CardImage>
            <Styled.CardName>
                {fullName}
            </Styled.CardName>
            <Styled.CardStatusWrapper>
              <StatusTag status={status}/>
            </Styled.CardStatusWrapper>
        </Styled.Card>
    );
};

export default MarriageNOCCard;
