import moment from "moment";
import instance from "../root.api";

export const eventsRequest = () =>
  instance
    .get(`/events?_limit=-1&date_gt=${moment().toISOString()}`)
    .then(response => ({ response }))
    .catch(response => ({ ...response }));


export const getPrivateEventRequest = (uuid) =>
    instance
        .get(`/events/uuid/${uuid}`)
        .then(response => ({ response }))
        .catch(response => ({ ...response }));
