import React from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Button from '../../../components/Button/Button';
import ModalWrapper from "../../../hoc/withModal";
import { urlLocations } from "../../../routes/urlLocations";
import {MODALS_ID} from "../../../constants/constants";
import Styled from './styled'

const ModalContent = ({ setPopupStatus }) => (
    <Styled.Content>
        <h1>
            <FormattedMessage
                id={"LiturgyPage.BookedModal.Title"}
            />
        </h1>
        <Styled.Description>
            <FormattedMessage
                id={"LiturgyPage.BookedModal.Text"}
                values={{
                    link: <Link
                        onClick={setPopupStatus.bind(null,null)}
                        to={ urlLocations.booking }>
                        <FormattedMessage
                            id="LiturgyPage.BookedModal.Link"
                        />
                    </Link>
                }}
            />
        </Styled.Description>
        <Button
            uiType={"secondary"}
            onClick={setPopupStatus.bind(null,null)}
        >
            <FormattedMessage
                id={"LiturgyPage.BookedModal.CloseButton"}
            />
        </Button>
    </Styled.Content>
);

const LiturgyBookedModal = () => (
    <ModalWrapper
        modalId={MODALS_ID.LITURGY_BOOKED_MODAL}
        component={ModalContent}
    />
);

export default LiturgyBookedModal;