import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledFormRow = styled.div`
	  width: 100%;
	  display: flex;
	  margin-bottom: 15px;
	  
	  @media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;
		margin-bottom: 24px;
	  } 
`
export default StyledFormRow ;


