import styled from "styled-components";

const Content = styled.div`
  padding: 25px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    &:first-child {
      margin-right: 15px;
    }
  }
`;

export default {
  Content,
  ButtonRow
};
