import {
    GET_MARRIAGE_NOC_SUCCESS,
} from "./marriageNoc.actions";
import initialState from "../../store/initialState";

export default (state = initialState.marriageNoc, action) => {
    switch (action.type) {
        case GET_MARRIAGE_NOC_SUCCESS:
            return action.payload
        default:
            return state;
    }
};
