import React, {useRef} from 'react';
import {reduxForm} from "redux-form";
import {compose} from "redux";
import {connect} from "react-redux";

import InputField from '../../../components/InputField/InputField';
import {loginAction} from "../../../services/login/login.action"
import {FormattedMessage} from "react-intl";
import Button from "../../../components/Button/Button";
import StyledFormRow from "../../styled/StyledFormRow";
import StyledForgot from "./styled/StyledForgot";
import {setModalStatusAction} from "../../../services/modals/modals.action";
import {MODALS_ID} from "../../../constants/constants";
import LoginDisclaimer from "./LoginDisclaimer/LoginDisclaimer";


const LoginForm = ({handleSubmit, setModalStatus}) => {
  const formRef = useRef(null);

  return (
    <form ref={formRef} onSubmit={handleSubmit}>

      <StyledFormRow>
        <label><FormattedMessage id="Login.Email.Placeholder"/></label>
        <InputField name="email"/>
      </StyledFormRow>

      <StyledFormRow>
        <label><FormattedMessage id="Login.Password.Placeholder"/></label>
        <InputField type="password" name="password"/>
      </StyledFormRow>

      <StyledFormRow align="space-between">
        <StyledForgot to="#" onClick={setModalStatus.bind(null, MODALS_ID.FORGOT_PASSWORD_POPUP)}>
          <FormattedMessage id="Login.Forgot"/>
        </StyledForgot>

        {/*<Button onClick={setModalStatus.bind(null, MODALS_ID.DISCLAIMER_MODAL)} >*/}
        <Button htmlType="submit">
          <FormattedMessage id="Login.Button"/>
        </Button>
      </StyledFormRow>
      <LoginDisclaimer formRef={formRef}/>
    </form>
  )
};

export default compose(
  connect(() => ({}), {
    setModalStatus: setModalStatusAction
  }),
  reduxForm({
    form: "loginForm",
    onSubmit: (value, dispatch) => dispatch(loginAction({...value}))
  })
)
(LoginForm);
