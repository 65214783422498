import styled from 'styled-components';

const StyledLiturgyAsideAmount = styled.p`
  font-size: 13px;
  line-height: 1.5;
  color: #97aede;
  position: relative;
  top: -3px;
  width: 100%;
  font-family: var(--fontProximaRegular);
  padding-bottom: 0;
  margin-bottom: 2px;

`
export default StyledLiturgyAsideAmount;