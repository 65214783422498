import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledLiturgyTag = styled.div`
  border-radius: 20px;
  margin-top: 8px;
  background-color:${({bg}) => bg };
  padding: 5px 10px;
  font-size: 14px;
  font-weight: normal;
  margin-right: 10px;
  font-family: var(--fontProximaRegular);
  color: ${({textColor}) => textColor };
`
export default StyledLiturgyTag ;