import { call, delay, put, takeEvery, select } from "redux-saga/effects";
import { matchPath } from "react-router";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  CHECK_EVENT_AVAILABILITY,
  CHECK_EVENT_AVAILABILITY_FAILURE,
  CHECK_EVENT_AVAILABILITY_SUCCESS,
} from "./eventAvailability.action";
import { checkAvailabilityRequest } from "./eventAvailability.api";
import {SET_LOADER_STATUS} from "../loader/loader.action";
import {LOADERS} from "../../constants/constants";

function* checkEventAvailability({ payload }) {
  try {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.availability,
        status: true,
      },
    });
    const { response } = yield call(checkAvailabilityRequest, { ...payload });

    if (response.status === 200) {
      yield put({
        type: CHECK_EVENT_AVAILABILITY_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: CHECK_EVENT_AVAILABILITY_FAILURE,
      });
    }
  } catch (error) {
    yield put({ type: CHECK_EVENT_AVAILABILITY_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.availability,
        status: false,
      },
    });
  }
}

export default function* eventAvailabilitySaga() {
  yield takeEvery(CHECK_EVENT_AVAILABILITY, checkEventAvailability);
}
