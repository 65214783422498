import instance from "../root.api";

export const familyInfoRequest = () =>
  instance
    .get("/families/me")
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const setFamilyAddressRequest = data =>
  instance
    .put("/families/me/address", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));
