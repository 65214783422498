import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useLocation } from "react-router";

import Header from "../../components/Header/Header";
import { getPrivateLiturgySelector } from "../../services/events/events.selector";
import { ModalContent } from "../../containers/LiturgyPage/LiturgyModal/LiturgyModal";
import Styled from "./styled";

const PrivateEvent = () => {
  const event = useSelector(getPrivateLiturgySelector);
  const { pathname } = useLocation();

  useEffect(() => {
    localStorage.setItem("redirectAfterLogin", pathname);
  }, []);
  return (
    <>
      <Header logo={true} />
      <Styled.Page>
        {!isEmpty(event) && <ModalContent isPrivate liturgy={event} />}
      </Styled.Page>
    </>
  );
};

export default PrivateEvent;
