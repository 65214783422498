import React from 'react';
import { Avatar, Badge, Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import { createStructuredSelector } from "reselect";
import isEmpty from 'lodash/isEmpty'

import { logoutAction } from '../../services/logout/logout.action';
import StyledUserBlock from './styled/StyledUserBlock';
import LogoPage from '../../components/LogoPage/LogoPage';
import StyledHeader from './styled/StyledHeader';
import StyledIconNotification from './styled/StyledIconNotification'
import DropDownIcon from '../../assets/icons/Arrow.svg'
import NotificationIcon from '../../assets/icons/Notification.svg'
import { familyMainMemberSelector } from "../../services/mainMember/mainMember.selector";
import { urlLocations } from "../../routes/urlLocations";
import { Link } from "react-router-dom";
import LangControl from "../LangControl/LangControl";

const DropdownMenu = ({ logout }) => {
	return(
		<Menu>
			<Menu.Item key="0">
				<Link to={urlLocations.privacy}>
					<FormattedMessage id="Header.PrivacyPolicy" />
				</Link>
			</Menu.Item>
			<Menu.Item key="1">
				<Link to={'#'} onClick={logout}>
					<FormattedMessage id="Header.Logout" />
				</Link>
			</Menu.Item>
		</Menu>
	)
};


const Header = ({
	logout,
	intl,
	logo,
	mainMember,
}) => {

	return(
        <StyledHeader logoProp={logo}>
	        {
		        (logo === true) && <LogoPage />
          }

	        <StyledUserBlock>
		        {/*<Badge dot>*/}
			      {/*  <StyledIconNotification>*/}
				    {/*    <img src={NotificationIcon} alt={intl.formatMessage({id: 'Header.Notification.Alt'})}/>*/}
			      {/*  </StyledIconNotification>*/}
		        {/*</Badge>*/}
            <LangControl posType={'flex'}/>
		        <Avatar src={mainMember?.profilePic?.url} icon={<UserOutlined />} shape="circle"/>

		        <Dropdown overlay={<DropdownMenu logout={logout} />}>

	                <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
						{ !isEmpty(mainMember) &&
							<p>
								{mainMember?.firstName}{" "}
								{mainMember?.secondName}{" "}
								{mainMember?.lastName}
							</p>
						}
		                <img src={DropDownIcon} alt="" />
	                </span>
		        </Dropdown>
	        </StyledUserBlock>

        </StyledHeader>
	    )
};

export default injectIntl(
	connect(
		createStructuredSelector({
			mainMember: familyMainMemberSelector
		}),
		{ logout: logoutAction }
	)
	(Header))
