import styled from 'styled-components';
import { DEVICE } from '../../constants/media';

const StyledInfoBox = styled.div`
  font-size: 14px;
  line-height: 1.45;
  color: var(--gray);
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  margin-right: 32px;
  margin-bottom:  ${({ marginbottom })  => (marginbottom === 'true') ? '0;' : '15px;'};
  width: ${({ fullwidth })  => (fullwidth) && '100%;'};
  
  @media ${DEVICE.mobileDevices} {
		margin-bottom: 20px; 
	}
`
export default StyledInfoBox;