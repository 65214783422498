import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { familyMainMemberSelector } from '../../../services/mainMember/mainMember.selector'
import PersonalInfoForm from './PersonalInfoForm/PersonalInfoForm'
import PhotoSection from "../FieldsSections/PhotoSection";
import initialState from "../../../store/initialState";
import { FORMS_NAME, FILES_TYPE } from "../../../constants/constants";
import StyledPageTitle from '../../styled/StyledPageTitle';
import StyledPageSubTitle from '../styled/StyledPageSubTitle';
import StyledPageWrapSubTitle from '../styled/StyledPageWrapSubTitle';
import {familyCountrySelector} from "../../../services/family/family.selector";

const PersonalInfo = ({ mainMember, country }) => {

    const initialMainMember = initialState.family.mainMember;

    return(
        <>
            <StyledPageTitle>
                <FormattedMessage
                    id="FamilyPage.Personal.Title"
                />
            </StyledPageTitle>

            {!!country?.isMain && <>
                <StyledPageWrapSubTitle>
                    <StyledPageSubTitle>
                        <FormattedMessage id="FamilyPage.Personal.YourPhoto"/>
                    </StyledPageSubTitle>
                </StyledPageWrapSubTitle>

                <PhotoSection
                    formName={FORMS_NAME.MAIN_MEMBER}
                    name={FILES_TYPE.PROFILE_PIC}
                />
            </>
            }

            <PersonalInfoForm
                formName={FORMS_NAME.MAIN_MEMBER}
                visaType={mainMember ? mainMember?.visaType : initialMainMember.visaType}
                nationality={mainMember ? mainMember.nationality : initialMainMember.nationality}
                country={country}
                initialValues={{...(mainMember ? mainMember : initialMainMember)}}
            />
        </>
    )
};

export default connect(
    createStructuredSelector({
        mainMember:familyMainMemberSelector,
        country:familyCountrySelector,
    }),
    {}
)(PersonalInfo);
