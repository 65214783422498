import React from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import { createStructuredSelector } from "reselect";

import { generalLoaderSelector } from "../services/generalLoader/generalLoader.selector";

const LoaderWrapper = ({ children, isLoading }) => {
  return <Spin spinning={isLoading}>{children}</Spin>;
};

export default connect(
  createStructuredSelector({
    isLoading: generalLoaderSelector,
  })
)(LoaderWrapper);
