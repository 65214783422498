import React from 'react';

import ModalWrapper from "../../../../hoc/withModal";
import {MODALS_ID} from "../../../../constants/constants";
import ResendEmailForm from '../ResendEmail.Form/ResendEmail.Form'

const ResendEmailModal = () => (
    <ModalWrapper
        component={ResendEmailForm}
        modalId={MODALS_ID.RESEND_EMAIL_POPUP}
    />
);

export default ResendEmailModal;

