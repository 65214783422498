import styled from 'styled-components'
import { Input } from 'antd'

const StyledInput = styled(Input)`
  && {
    border: 1px solid var(--borderInput);
    background-color: var(--white);
    position: relative;
    padding: 12px 16px;
    color: var(--dark);
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--lightGrey);
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;

    &:focus {
      border: solid 1px var(--gray);
    }

    &:hover {
      border: 1px solid var(--gray);
    }

    .ant-input-focused {
      border: solid 1px var(--gray);
    }

    &[disabled] {
      border: 1px solid var(--lightGrey);
      background-color: var(--disableBg);
    }

    .ant-input-prefix {
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }


  }
`

export default StyledInput;


