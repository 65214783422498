import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledBookingPageCard = styled.div`
    display: flex;
    max-width: 100%;
    border-radius: 10px;
	  box-shadow: 0 0 10px 0 rgba(139, 155, 163, 0.1);
	  border: solid 1px var(--lightGrey);
	  background-color: var(--white);
	  overflow: hidden;
	  margin-bottom: 17px;
	  
	 @media ${DEVICE.laptop} {
		height: ${({ hasTags }) => hasTags === true ? '260px;' : '228px'  };
	}
	
	@media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;
		margin-bottom: 45px;
	}
`

export default StyledBookingPageCard;


