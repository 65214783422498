import styled from 'styled-components';

const StyledDashboardItemDescription = styled.p`
	font-size: 13px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: var(--gray);
	max-width: 248px;
	text-align: center;
	margin: 0 auto 40px auto;
}
`
export default StyledDashboardItemDescription;
