import React, {useEffect} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

import Styled from "./styled";
import {getMarriageNOCSelector} from "../../services/marriageNoc/marriageNoc.selector";
import MarriageNocDetailsForm from "./MarriageNOCDetails.Form";
import Header from "../../components/Header/Header";
import {FILES_TYPE, FORMS_NAME} from "../../constants/constants";
import {addFilesAction} from "../../services/file/file.action";
import {selectFile} from "../../services/file/file.selector";
import StyledPageTitle from "../styled/StyledPageTitle";

const MarriageNocDetails = () => {
  const {id} = useParams();
  const marriageNOC = useSelector((state) => getMarriageNOCSelector(state, id));
  const dispatch = useDispatch();

  useEffect(() => {
    if (marriageNOC?.id) {
      dispatch(
        addFilesAction({
          files: marriageNOC?.documents || [],
          formName: FORMS_NAME.MARRIAGE_NOC,
          name: FILES_TYPE.MARRIAGE_NOC_DOCUMENTS,
        })
      );
      dispatch(
        addFilesAction({
          files: [marriageNOC?.photo] || [],
          formName: FORMS_NAME.MARRIAGE_NOC,
          name: FILES_TYPE.MARRIAGE_NOC_PHOTO,
        })
      );
    }
  }, [marriageNOC?.id]);

  useEffect(() => {
    if(localStorage.getItem('lang') !== 'ar') {
      localStorage.setItem("lang" , 'ar');
      document.location.reload();
    }
  }, []);

  return (
    <>
      <Header logo/>
      <Styled.Page>
        <Styled.Container>
          <StyledPageTitle>
            <FormattedMessage id={"MarriageNOCDetails.Title"}/>
          </StyledPageTitle>
          <Styled.ArabicOnlyHint>
            <FormattedMessage id={"MarriageNOCDetails.ArabicOnly"}/>
          </Styled.ArabicOnlyHint>
          <MarriageNocDetailsForm
            marriageNOC={marriageNOC}
            initialValues={{
              ...marriageNOC,
              church: marriageNOC?.church?.id,
            }}
          />
        </Styled.Container>
      </Styled.Page>
    </>
  );
};

export default MarriageNocDetails;
