import styled from 'styled-components';

const StyledLiturgyAsideName = styled.p`
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  color: #ffffff;
  font-family: var(--fontProximaBold);
  padding-right: 30px;
  padding-bottom: 0;
  margin-bottom: 2px;
`
export default StyledLiturgyAsideName;