import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { errorsSelector } from "../../../services/errors/errors.selector";
import { fileUploadAction } from "../../../services/file/file.action";
import StyledFileUploadSection from './styled/StyledFileUploadSection';
import StyledFileUploadButton from './styled/StyledFileUploadButton';
import UploadIcon from '../../../assets/icons/Upload.svg';
import StyledError from '../../styled/StyledError';

import StyledPhotoSectionText from './styled/StyledPhotoSectionText';


const FileUploadSection = ({ children, fileUpload, name, errors, formName, description }) => {
    const [ touched, setTouched ] = useState(false);
    useEffect(() => {
        setTouched(true)
    }, [errors]);

    return (
        <StyledFileUploadSection>
            <Upload
                onChange={setTouched.bind(null, false)}
                showUploadList={false}
                action={null}
                customRequest={({ file }) => {
                    fileUpload( { file, name, formName } );
                }}
            >
                <StyledFileUploadButton icon={<img src={UploadIcon} alt={"upload icon"}/>}>
                    { children }
                </StyledFileUploadButton>

                <StyledPhotoSectionText>
                    <FormattedMessage id={description || "FamilyPage.Form.DocumentsInfo"} />
                </StyledPhotoSectionText>

            </Upload>
            {
                touched && errors?.[name] && errors[name].map(error =>
                    <StyledError key={error}>
                        <FormattedMessage key={error} id={ error } />
                    </StyledError>
                )

            }
            {/*<StyledError> { touched && errors?.[name] && errors[name] } </StyledError>*/}
        </StyledFileUploadSection>
    );
}

export default connect(
createStructuredSelector({
    errors: errorsSelector
}),
{ fileUpload: fileUploadAction })
(FileUploadSection);