import React, { useState } from 'react';
import { Switch, Route } from "react-router";

import FamilyAside from "./FamilyAside/FamilyAside";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import SpouseInfo from "./SpouseInfo/SpouseInfo";
import {urlLocations} from "../../routes/urlLocations";
import ChildrenInfo from "./ChildrenInfo/ChildrenInfo";
import ParentInfo from "./ParentInfo/ParentInfo";
import AddressInfo from "./AddressInfo/AddressInfo";
import ChildrenList from './ChildrenList/ChildrenList';
import ParentsList from "./ParentsList/ParentsList";
import StyledPageContent from '../styled/StyledPageContent';
import StyledPageLayout from '../styled/StyledPageLayout';
import Aside from '../../components/Aside/Aside';
import Header from '../../components/Header/Header';
import StyledPageMainInfo from '../styled/StyledPageMainInfo';
import StyledCollapseIcon from '../styled/StyledCollapseIcon';
const FamilyPage = () => {

	const [ isCollapse, setCollapse ] = useState(false);
	const onCollapse = () => {
		setCollapse(!isCollapse);
	};

    return(
        <StyledPageLayout>

            <Aside
                trigger={true}
                collapsible
                collapsed={isCollapse}
                onCollapse={onCollapse}
            >

                <FamilyAside onCollapse={onCollapse}/>

            </Aside>

            <StyledCollapseIcon onClick={onCollapse}></StyledCollapseIcon>

            <StyledPageContent  collapsed={isCollapse}>
                <Header logo={false}/>
                <StyledPageMainInfo>
                    <Switch>
                        <Route exact path={urlLocations.personalInfo} component={PersonalInfo} />
                        <Route exact path={urlLocations.spouseInfo} component={SpouseInfo} />
                        <Route exact path={urlLocations.childrenList} component={ChildrenList} />
                        <Route exact path={urlLocations.childInfo} component={ChildrenInfo}/>
                        <Route exact path={urlLocations.parentsList} component={ParentsList} />
                        <Route exact path={urlLocations.parentInfo} component={ParentInfo}/>
                        <Route exact path={urlLocations.parentsInfo} component={ParentInfo} />
                        <Route exact path={urlLocations.addressInfo} component={AddressInfo} />
                    </Switch>
                </StyledPageMainInfo>

            </StyledPageContent>
        </StyledPageLayout>
    )
}


export default FamilyPage