import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledAside = styled.div`
    padding: 128px 20px 0 20px;
    display: flex;
    justify-content: center;
    
    @media ${DEVICE.mobileDevicesS} {
		padding: 128px 10px 0 10px;	
	}
`
export default StyledAside;