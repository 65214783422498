import styled from 'styled-components'
import { DEVICE } from '../../constants/media';

const StyledPageTitle = styled.h1`
{
  font-family: var(--fontProximaBold);
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding-bottom: 0;
  text-align: ${({align}) => (align === 'center') ? 'center' : 'left'};

  @media ${DEVICE.mobileDevices} {
    font-size: 25px;
  }
}
`

export default StyledPageTitle;


