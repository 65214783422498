import React, {useEffect, useState} from 'react';
import { Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import StyledSelect from "./styled/StyledSelect";
import Arrow from '../../assets/icons/Arrow.svg';

import ControlWrapper from "../ControlWrapper/ControlWrapper";

const CustomSelect = ({
    input: { value, onBlur, name, ...restInput },
    label,
    onSelect,
    children,
    defaultValue,
    formatMessage,
    customTouched,
    disabled,
    placeholder,
    setTouched,
    ...meta
}) => {
    return <ControlWrapper
        label={label}
        name={name}
        customTouched={customTouched}
    >
        <StyledSelect
            defaultValue={defaultValue}
            {...meta}
            suffixIcon={<img src={Arrow} alt={""} />}
            {...restInput}
            disabled={disabled}
            onFocus={setTouched.bind(null, true)}
            onSelect={onSelect}
            placeholder={placeholder && formatMessage({ id: placeholder })}
            { ...(value && { value })  }
        >
            {
                children
            }
        </StyledSelect>

    </ControlWrapper>
};

const SelectField = ({
     name,
     children,
     errors,
     intl: { formatMessage },
     placeholder,
     label,
     disabled,
     defaultValue,
     onSelect
}) => {
    const [ customTouched, setTouched ] = useState(false);
    useEffect(() => {
        setTouched(false)
    }, [errors]);
    return (
        <Field
            name={name}
            component={CustomSelect}
            customTouched={customTouched}
            setTouched={setTouched}
            formatMessage={formatMessage}
            label={label}
            disabled={disabled}
            defaultValue={defaultValue}
            onSelect={onSelect}
            placeholder={placeholder}
            children={children}
        />
    )};

export default compose(
    connect(({ errors }) => ({ errors }),
        {} ),
    injectIntl,
)(SelectField);
