import { call, put, select, takeEvery } from "redux-saga/effects";

import { CLEAR_ERRORS, SET_ERRORS } from "../errors/errors.action";
import { prepareFilesDataForSave } from "../file/file.utils";
import { setFamilyMainMemberRequest } from "./mainMember.api";
import { history, urlLocations } from "../../routes/urlLocations";
import {
  SET_FAMILY_MAIN_MEMBER,
  SET_FAMILY_MAIN_MEMBER_FAILURE,
  SET_FAMILY_MAIN_MEMBER_SUCCESS
} from "./mainMember.action";
import openNotification from "../../utils/notifications";
import { GET_FAMILY_INFO } from "../family/family.action";

function* setFamilyMainMember({ payload }) {
  try {
    yield put({ type: CLEAR_ERRORS });
    const files = yield select(state => state.files);
    const family = yield select(state => state.family);

    const prepareFiles = prepareFilesDataForSave(files.mainMember);
    const { response } = yield setFamilyMainMemberRequest({
      mainMember: { ...payload, ...prepareFiles }
    });
    if (response.status === 200) {
      yield put({ type: SET_FAMILY_MAIN_MEMBER_SUCCESS });
      yield put({ type: GET_FAMILY_INFO });
      history.push(
        family.type === "alone"
          ? urlLocations.parentsList
          : urlLocations.spouseInfo
      );
      yield call(openNotification, {
        message: "FamilyPage.Personal.Success",
        description: "FamilyPage.Personal.SuccessMessage",
        type: "success"
      });
    } else {
      yield put({
        type: SET_ERRORS,
        payload: response.data.data
      });
      yield call(openNotification, {
        message: "FamilyPage.Personal.Error",
        description: "FamilyPage.Personal.ErrorMessage",
        type: "error"
      });
      yield put({ type: SET_FAMILY_MAIN_MEMBER_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* mainMemberSaga() {
  yield takeEvery(SET_FAMILY_MAIN_MEMBER, setFamilyMainMember);
}
