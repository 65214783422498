import styled from 'styled-components'

const StyledBookingCardTitle = styled.h3`
  font-family: var(--fontProximaBold);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-bottom: 0px;
  padding-left: 40px;
  padding-top: 24px;
`

export default StyledBookingCardTitle;


