import styled from 'styled-components';
import arrowDown from '../../../assets/img/arrowDown.svg';

const StyledCardItemContent = styled.div`
  display: inline-block;
  width: 256px;
  height: 94px;
  background-image: linear-gradient(to top, #4164da, #587cf4);
  background: #fff;
  position: relative; 
  height: 160px;
  margin-top: 90px;
  padding-top: 85px;
  
  &:after {
    content: '';
    width: 256px;
    position: absolute;
    width: 100%;
    height: 20px;
    top: -16px;
    left: 0;
    z-index: 1;
    background: url(${arrowDown}) center center no-repeat;
    
  }
`

export default StyledCardItemContent;


