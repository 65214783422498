import styled from "styled-components";

const Content = styled.div`
  padding: 35px 25px 45px;
`;

const Description = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  a {
    font-size: 18px;
  }
`;

export default {
  Content,
  Description
};
