import React, {useState, useEffect} from 'react';
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";

import Button from "../../../../components/Button/Button";
import {setFamilySpouseAction} from "../../../../services/spouse/spouse.action"
import MainSection from "../../FieldsSections/MainSection";
import ContactSection from "../../FieldsSections/ContactSection";
import AdditionalSection from "../../FieldsSections/AdditionalSection";
import SpouseStatusSection from "../../FieldsSections/SpouseStatusSection";
import {FILES_TYPE, FORMS_NAME, SPOUSE_STATUS_VALUES} from "../../../../constants/constants";
import PhotoSection from "../../FieldsSections/PhotoSection";
import VisaSection from "../../FieldsSections/VisaSection";
import NationalitySection from "../../FieldsSections/NationalitySection";
import StyledPageWrapSubTitle from '../../styled/StyledPageWrapSubTitle';
import StyledPageSubTitle from '../../styled/StyledPageSubTitle';
import StyledSpouseInfoForm from './styled/StyledSpouseInfoForm';

const SpouseInfoForm = ({
    handleSubmit,
    visaType,
    nationality,
    formName,
    country,
    initialValues: {
        spouseStatus
    }
}) => {

    const [settedSpouseStatus, setSpouseStatus] = useState(spouseStatus);

    useEffect(() => {
        setSpouseStatus(spouseStatus)
    }, [spouseStatus]);

    return (
        <StyledSpouseInfoForm>
            <form onSubmit={handleSubmit}>

                <SpouseStatusSection
                    onStatusChange={setSpouseStatus}
                />

                {!settedSpouseStatus || (settedSpouseStatus === SPOUSE_STATUS_VALUES.available) && <>
                    {!!country?.isMain && <>
                        <StyledPageWrapSubTitle>
                            <StyledPageSubTitle>
                                <FormattedMessage
                                    id="FamilyPage.Spouse.YourPhoto"
                                />
                            </StyledPageSubTitle>
                        </StyledPageWrapSubTitle>

                        <PhotoSection
                            formName={FORMS_NAME.SPOUSE}
                            name={FILES_TYPE.PROFILE_PIC}
                        />
                    </>
                    }

                    <MainSection/>

                    <ContactSection/>
                    {!!country?.isMain && <>
                        <StyledPageWrapSubTitle>
                            <StyledPageSubTitle>
                                <FormattedMessage
                                    id="FamilyPage.Section.Identification"
                                />
                            </StyledPageSubTitle>
                        </StyledPageWrapSubTitle>

                        <NationalitySection
                            formName={formName}
                            nationality={nationality}
                        />
                        <VisaSection
                            formName={formName}
                            visaType={visaType}
                        />
                        <AdditionalSection
                            visaType={visaType}
                            formName={formName}
                            nationality={nationality}
                        />

                    </>
                    }
                    <br/>
                </>
                }
                <Button htmlType="submit">
                    <FormattedMessage
                        id="FamilyPage.Form.SaveButton"
                    />
                </Button>
            </form>
        </StyledSpouseInfoForm>
    )
};

export default reduxForm({
    form: 'spouseInfoForm',
    enableReinitialize: true,
    onSubmit: (value, dispatch) => dispatch(setFamilySpouseAction(value))
})(SpouseInfoForm)
