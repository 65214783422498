import React from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from 'react-intl'

import BookingsPageCard from "../BookingsPageCard/BookingsPageCard";
import { getBookingsSelector } from "../../../services/bookings/bookings.selector";
import StyledBookingPageList from './styled/StyledBookingPageList';

const BookingsPageList = ({ bookings }) => (
    <StyledBookingPageList>
        {
            !!bookings?.length ? bookings.map(booking => (
                <BookingsPageCard key={booking?.id}  booking={booking} />
            )) : <h1><FormattedMessage id="BookingsPage.Empty" /></h1>
        }
    </StyledBookingPageList>
);

export default compose(
    connect(
        createStructuredSelector({
            bookings: getBookingsSelector
        })
    )
)(BookingsPageList);