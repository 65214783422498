import styled from 'styled-components';
import ArrowDone from '../../../../assets/icons/Checkbox-done.svg';

const StyledAsideIconCheck = styled.i`
	position: absolute;
	width: 24px;
	height: 24px;
	right: 20px;
    top: 17px;
    display: block;
	background: url(${ArrowDone}) center center no-repeat;
`
export default StyledAsideIconCheck;


