import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledHomePageCol = styled.div`
	font-size: 13px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: var(--gray);
	padding-right: 40px;
	padding-bottom: 16px;
	
	@media ${DEVICE.mobileDevices} {
		padding-right: 10px;
	}
`
export default StyledHomePageCol;