import styled from 'styled-components'

const StyledBookingPage = styled.div`
    padding-bottom: 50px;
    max-width: 928px;
    margin: 55px auto 0 auto;
    position: relative;
`

export default StyledBookingPage;


