export default {
  appInfo: {},
  areas: null,
  events: {
    list: {},
    private: {},
  },
  bookings: null,
  generalLoader: 0,
  geoLocation: {},
  eventAvailability: {},
  fileUploader: {},
  loaders: {},
  modals: null,
  marriageNoc: null,
  family: {
    mainMember: {
      churchService: null,
      dateOfBirth: null,
      deacon: true,
      email: null,
      emiratesID: null,
      emiratesIDCopy: null,
      fatherOfConfession: null,
      firstName: null,
      gender: "male",
      lastName: null,
      mobile: null,
      nationalIDCopy: null,
      nationalIDNumber: null,
      nationality: "egyptian",
      passportNumber: null,
      profilePic: null,
      secondName: null,
      status: "married",
      visaType: "residence",
      whatsapp: null,
      workingField: null,
    },
    spouseStatus: "available",
    spouse: {
      churchService: null,
      dateOfBirth: null,
      deacon: true,
      email: null,
      emiratesID: null,
      emiratesIDCopy: null,
      fatherOfConfession: null,
      firstName: null,
      gender: "male",
      lastName: null,
      mobile: null,
      nationalIDCopy: null,
      nationalIDNumber: null,
      nationality: "egyptian",
      passportNumber: null,
      profilePic: null,
      secondName: null,
      status: "married",
      visaType: "residence",
      whatsapp: null,
      workingField: null,
    },
    children: [],
    grandParents: [],
  },
  files: {
    mainMember: {
      nationalIDCopy: [],
      emiratesIDCopy: [],
      profilePic: [],
    },
    spouse: {
      nationalIDCopy: [],
      emiratesIDCopy: [],
      profilePic: [],
    },
    children: {
      nationalIDCopy: [],
      emiratesIDCopy: [],
      profilePic: [],
    },
    grandParents: {
      nationalIDCopy: [],
      emiratesIDCopy: [],
      profilePic: [],
    },
    marriageNOC: {
      documents: [],
      photo: [],
    },
  },
  errors: {},
};
