import styled from 'styled-components';

const StyledLogoTitle = styled.p`
	  font-family: var(--fontProximaRegular);
	  color: var(--gray);
	  padding-bottom: 6px;
	  font-size: 13px;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.5;
	  letter-spacing: normal;
	  text-align: start;
	  padding: 0 10px;
	  padding-bottom: 0 10px;
`
export default StyledLogoTitle;