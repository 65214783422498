import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledBookingPageList = styled.div`
    margin-bottom: 155px;
    z-index: 3;
    position: relative;
    padding-top: 56px;
   
    @media ${DEVICE.mobileDevices} {
		padding-left: 15px;
		padding-right: 15px;
	}
			 
	@media ${DEVICE.tabletDevices} {
		padding-left: 20px;
		padding-right: 20px;
	}
    
`
export default StyledBookingPageList;


