import React from 'react';
import { Link } from 'react-router-dom';
import StyledAside from './styled/StyledAside';
import { urlLocations } from "../../routes/urlLocations";
import AsideLogo from '../../assets/img/logo/logoAside.svg';
import ArrowBack from '../../assets/icons/arrowWhite.svg';
import StyledLogo from './styled/StyledLogo';
import StyledBackBtn from './styled/StyledBackBtn';
import { FormattedMessage} from "react-intl";
import StyledAsideCopyright from './styled/StyledAsideCopyright';
import {connect} from "react-redux";
import { createStructuredSelector } from "reselect";
import { familySelector } from "../../services/family/family.selector";
import {isFamilyComplete} from "../../services/family/family.utils";

const Aside = ({ children, family, ...props }) => {

    return (
        <StyledAside
	        {...props}
        >

             <StyledLogo>
                <Link  to={urlLocations.home}>
                    <img src={AsideLogo} alt="" />
                </Link>
            </StyledLogo>

            { isFamilyComplete(family) && <StyledBackBtn to={urlLocations.home}>
                    <img src={ArrowBack} alt="Go back" />
                    <FormattedMessage id="FamilyPage.Aside.BackButton" />
            </StyledBackBtn> }

	        {children}

	        <StyledAsideCopyright>
                <FormattedMessage id="Aside.Copyright" />
            </StyledAsideCopyright>

        </StyledAside>
    )
};


export default connect(
    createStructuredSelector({
        family: familySelector
    })
)(Aside);


