import React from "react";
import { reduxForm } from "redux-form";

import MainSection from "../../FieldsSections/MainSection";
import ContactSection from "../../FieldsSections/ContactSection";
import AdditionalSection from "../../FieldsSections/AdditionalSection";
import { setFamilyChildAction } from "../../../../services/children/children.action";
import {FormattedMessage} from "react-intl";
import NationalitySection from "../../FieldsSections/NationalitySection";
import VisaSection from "../../FieldsSections/VisaSection";
import Button from "../../../../components/Button/Button";
import StyledFormLastCol from '../../styled/StyledFormLastCol';
import StyledPageWrapSubTitle from '../../styled/StyledPageWrapSubTitle';
import StyledPageSubTitle from '../../styled/StyledPageSubTitle';

const ChildrenForm = ({
    handleSubmit,
    visaType,
    nationality,
    country,
    formName,
  isNew,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <MainSection disabledDOB={!isNew}/>
            <ContactSection/>
            {!!country?.isMain && <>
                <StyledPageWrapSubTitle>
                    <StyledPageSubTitle>
                        <FormattedMessage
                            id="FamilyPage.Section.Identification"
                        />
                    </StyledPageSubTitle>
                </StyledPageWrapSubTitle>

                <NationalitySection
                    formName={formName}
                    nationality={nationality}
                />
                <VisaSection
                    formName={formName}
                    visaType={visaType}
                />
                <AdditionalSection
                    visaType={visaType}
                    nationality={nationality}
                    formName={formName}
                />
            </>
            }
            <StyledFormLastCol>
                <Button size="small" htmlType="submit">
                    <FormattedMessage
                        id="FamilyPage.Form.SaveButton"
                    />
                </Button>
            </StyledFormLastCol>

        </form>
    )
};


export default reduxForm({
    form: "childrenForm",
    enableReinitialize: true,
    onSubmit: (value, dispatch) => dispatch(setFamilyChildAction(value))
})(ChildrenForm)
