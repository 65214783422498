import styled from 'styled-components';

const StyledFormTitle = styled.div`
    text-align: center;
    color: var(--dark);
    padding-bottom: 30px;
    font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	font-family: var(--fontProximaBold);
	padding-bottom: 8px;
}
`
export default StyledFormTitle;