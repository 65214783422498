import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledDocumentsList = styled.div`
  display: flex;
  flex-direction: row;
 
  @media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;	
	}
`;

export default StyledDocumentsList