import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledPageBottom = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 82px;
	position: relative;
	z-index: 3;
	
	@media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;
		padding-left: 15px;
		padding-right: 15px;
	}
	
	@media ${DEVICE.tabletDevices1200} {
		flex-wrap: wrap;
		padding-left: 20px;
		padding-right: 20px;
	}
	
`
export default StyledPageBottom;