import styled from 'styled-components'

const StyledDocumentsUploader = styled.div`
     width: 100%;
	 min-height: 122px;
	 border-radius: 10px;
	 border: 1px dashed #d6e1e9;
	 background-color: #f8f9fb;
	 text-align: center;
	 padding: 32px 20px 18px 24px;
	 display: flex;
	 justify-content: center;
	 overflow: hidden;
	 margin-top: 2px;
	 flex-direction: column;
`

export default StyledDocumentsUploader;


