import React, {useEffect, useState} from "react";
import { FormattedMessage } from "react-intl";
import { Select } from 'antd';

import SelectField from "../../../components/SelectField/SelectField";
import InputField from "../../../components/InputField/InputField";
import {FILES_TYPE, VISA_TYPES, VISA_TYPES_VALUES} from "../../../constants/constants";
import DocumentsUploader from "../DocumentsUploader/DocumentsUploader";
import StyledFormRow from '../styled/StyledFormRow';
import StyledFormCol100 from '../styled/StyledFormCol100';
import StyledFormCol33 from '../styled/StyledFormCol33';

const { Option } = Select;

const VisaSection = ({ visaType, formName }) => {
    const [ selectedType, selectType ] = useState(VISA_TYPES_VALUES.residence);

    useEffect(() => {
        selectType(visaType);
    }, [visaType]);

    return(
        <>

        <StyledFormRow>
            <StyledFormCol33>
                <SelectField
                    onSelect={selectType}
                    label="FamilyPage.Form.VisaType"
                    name="visaType"
                >
		            {
			            VISA_TYPES.map(type => (
                            <Option key={type.value} value={type.value} >
                                <FormattedMessage id={ type.label } />
                            </Option>
			            ))
		            }
                </SelectField>

            </StyledFormCol33>

            <StyledFormCol33>

	        {
		        selectedType === VISA_TYPES_VALUES.residence &&  <>
                        <InputField
                            label="FamilyPage.Form.EmiratesID"
                            name="emiratesID"
                        />
                </>
	        }
            </StyledFormCol33>

            <StyledFormCol33>
            </StyledFormCol33>
        </StyledFormRow>
	        {
		        selectedType === VISA_TYPES_VALUES.residence &&
                <>
                <StyledFormRow>
                    <StyledFormCol100>
                        <DocumentsUploader
                            formName={formName}
                            name={FILES_TYPE.EMIRATES_ID}
                        >
                            <FormattedMessage
                                id="FamilyPage.Form.UploadEmirateID"
                            />
                        </DocumentsUploader>
                    </StyledFormCol100>
                </StyledFormRow>
                </>
	        }
        </>
    )
};

export default VisaSection;