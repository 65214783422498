import styled from 'styled-components'

const StyledCardItemImg = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 24px;
  margin-left: -64px;
  overflow: hidden;
  z-index: 2;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }  
`

export default StyledCardItemImg;


