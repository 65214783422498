import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'antd';

const StyledButton = styled(({ ...props }) => (
	<Button {...props} />
))`
   && {
		display: inline-block;
	    width: 147px;
	    height: 40px;
	    margin:  0;
	    border-radius: 8px;
	    box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.1);
	    background: var(--white);
	    font-family: var(--fontProximaRegular);
	    font-size: 13px;
	    display: flex;
        align-items: center;
        justify-content: center;
	    font-stretch: normal;
	    font-style: normal;
	    letter-spacing: normal;
	    transition: all 0.3 ease-in;
	    border: 1px solid var(--hrefColorSecondary);
	    color: var(--hrefColorSecondary);
	    margin-left: 14px;
	    
		&:hover {
			box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.2);
            border: 1px solid var(--hrefColorSecondary);
            color: var(--hrefColorSecondary);
		}
		&:disabled {
			color: var(--white);
            border: 1px solid var(--gray);
            background: (--gray);               
        }
		  
		&.ant-btn-sm {
		     width: 126px;
             height: 40px;
		}
		  
		i {
                display: inline-block;
                margin-right: 5px;
                width: 24px;
                height: 24px;
                
                img {
                    vertical-align: inherit;
                }
        }
		  
		    
    ${({ uitype }) => {
		if ( uitype && uitype ==='secondary-bordered') {
			return css`
						 box-shadow: 0 5px 20px 0 rgba(255, 158, 158, 0.1);
                         color: var(--redBtn);
                         border: 1px solid var(--redBtn);
                         display: flex;
                         justify-content: center;
                         align-items: center;
                         
                         &:hover {
                                box-shadow: 0 10px 30px 0 rgba(255, 158, 158, 0.2);
						       border: 1px solid var(--redBtn);
						       color: var(--redBtn);
		                 }                   
                    `}
	}}	 
    }
    
`
export default StyledButton;
