import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { IntlProvider } from "react-intl";

import Loader from "../src/hoc/withLoader";

import * as firebase from "firebase/app";
import "firebase/analytics";

import { firebaseConfig } from "./config";

import store from "./store";
import { history } from "./routes/urlLocations";
import Routes from "./routes/routes";
import arDictionary from "./locales/ar";
import enDictionary from "./locales/en";
import "./main.css";
import * as serviceWorker from "./serviceWorker";

const APPDictionary = {
  ar: arDictionary,
  en: enDictionary
};

let lang = localStorage.getItem("lang");
if (!lang) {
  localStorage.setItem("lang", "en");
  lang = localStorage.getItem("lang");
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProvider locale={lang} messages={APPDictionary[lang]}>
        <Loader>
          <Routes />
        </Loader>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

firebase.initializeApp(firebaseConfig);
firebase.analytics();
serviceWorker.unregister();
