import styled from 'styled-components'
import plusIcon from  '../../../assets/icons/add.svg';

const StyledAddCardItem = styled.div`
  width: 256px;
  height: 248px;
  border-radius: 10px;
  border: solid 1px #d6e1e9;
  background-color: #f8f9fb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -24px;
    background: url(${plusIcon}) center center no-repeat;
    cursor: pointer;
  }
  
  a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	font-family: var(--fontProximaSemi);
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	padding-top: 40px;
	position: relative;
    z-index: 2;
  } 
`
export default StyledAddCardItem;


