import styled from 'styled-components';

const StyledLiturgyNotRestricted = styled.div`
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--green);
  padding-top: 5px;
`
export default StyledLiturgyNotRestricted;