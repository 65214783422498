import instance from "../root.api";

export const myBookingsRequest = () =>
  instance
    .get("/event-bookings/me")
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const bookEventRequest = data =>
  instance
    .post("/event-bookings/me", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const cancelBookingRequest = id =>
  instance
    .delete(`/event-bookings/me/${id}`)
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const modifyBookingRequest = ({ event, bookedMembers }) =>
  instance
    .put(`/event-bookings/modify/${event}`, { bookedMembers })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));
