import styled from "styled-components";

const StyledModalFooter = styled.div`
  background-color: ${({ isPrivate }) => (isPrivate ? "" : "#f8f9fb")};
  border-radius: 0 0 10px 10px;
  position: ${({ isPrivate }) => (isPrivate ? "" : "absolute")};
  width: 100%;
  height: 71px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  margin-top: 32px;
`;
export default StyledModalFooter;
