import styled from 'styled-components';
import { Button } from 'antd';

const StyledLiturgyButton = styled(Button)`
   && {
          display: inline-block;
          width: 126px;
          height: 40px;
		  border-radius: 4px;
		  border: 1px solid var(--uploadBtn);
		  font-family: var(--fontProximaRegular);
		  font-size: 13px;
		  line-height: 1.23;
		  color: var(--uploadBtn);
		  background: var(--white);
		  outline: none;
		  box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.1);
		  transition: all 0.25 ease-in;
	
		  &:hover {
		     color: var(--white);
		     box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.3);
		     background-image: var(--gradientMain);
		  }
		  
		  &:disabled {
		    background-color: var(--gray);
		    border: 1px solid var(--gray);
		    color: var(--white);
		    background-image: none;
		   }
	 
    }
    
`
export default StyledLiturgyButton;
