import instance from "../root.api";

export const setFamilyParentRequest = data =>
  instance
    .put("/families/me/parent", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const setNoParentsRequest = data =>
  instance
    .put("/families/me/grand-parents", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));
