export const selectLiturgyById = (state, id) => {
  return Object.values(state.events.list)?.find(
    (event) => event.id.toString() === id?.toString()
  );
};

export const getBookingByEventIdSelector = (state, id) => {
  return (
    state?.bookings &&
    Object.values(state?.bookings)?.filter(
      (booking) => booking.event?.id.toString() === id?.toString() && !booking.canceled
    )
  );
};

export const getBookingsSelector = (state) =>
  state?.bookings && Object.values(state?.bookings);

export const getBookingChurch = (state, churchId) =>
  state.appInfo?.churches?.find(
    (church) => church.id.toString() === churchId.toString()
  );
