import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledBookingPageCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    background-color: var(--liturgyBottom);
    
    width: 100%;
    right:0;
    bottom: 0;
    padding: 15px 20px 15px 20px;
    margin-top: 10px;
    
    @media ${DEVICE.laptop} {
		height: 71px;
		position: absolute;
		padding-right: 40px;
		padding: 15px 40px 15px 32px;
	}
	
    @media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;
	}
`
export default StyledBookingPageCard;


