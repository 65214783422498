import React from 'react';
import {formValueSelector} from "redux-form";
import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Select} from "antd";

import Styled from "./styled";
import RadioField from "../../components/RadioField/RadioField";
import {
  ANOTHER_MARRIAGE_RADIO,
  CURRENT_STATUS, FORMS_NAME,
  PREV_MARRIAGE_LOCATION,
  PREVIOUS_MARRIAGE_RADIO
} from "../../constants/constants";
import InputField from "../../components/InputField/InputField";
import SelectField from "../../components/SelectField/SelectField";
import DatePickerField from "../../components/DatePickerField/DatePickerField";
import MarriageNocDetailsSection from "../../containers/MarriageNOCDetails/MarriageNOCDetails.Section";

const {Option} = Select;

const MarriageNocDetailsPreviousMarriage = ({disabled} = {disabled: false}) => {
  const formSelector = formValueSelector(FORMS_NAME.MARRIAGE_NOC);
  const prevMarriage = useSelector(state => formSelector(state, 'prevMarriage'));
  const currentStatus = useSelector(state => formSelector(state, 'currentStatus'));
  const marriageBeforeWidowed = useSelector(state => formSelector(state, 'marriageBeforeWidowed'))

  return (
    <MarriageNocDetailsSection title="MarriageNOCDetails.PreviousMarriage">
      <Styled.FieldsWrap>
        <Styled.Field>
          <RadioField
            radioSet={PREVIOUS_MARRIAGE_RADIO}
            label={"MarriageNOCDetails.PreviousMarriageField"}
            name={"prevMarriage"}
            disabled={disabled}
          />
        </Styled.Field>
        {prevMarriage && <>
          <Styled.Field>
            <SelectField
              label={"MarriageNOCDetails.PreviousMarriageLocation"}
              placeholder={"MarriageNOCDetails.PreviousMarriageLocation"}
              name={"prevMarriageLocation"}
              disabled={disabled}>
              {
                PREV_MARRIAGE_LOCATION.map(location => (
                  <Option value={location?.value} key={location.value}>
                    <FormattedMessage id={location?.label}/>
                  </Option>
                ))
              }
            </SelectField>
          </Styled.Field>
          <Styled.Field>
            <DatePickerField
              label={"MarriageNOCDetails.PreviousMarriageDate"}
              placeholder={"MarriageNOCDetails.PreviousMarriageDate"}
              name={"prevMarriageDate"}
              disabledFiled={disabled}
            />
          </Styled.Field>

          <Styled.Field>
            <InputField
              label={"MarriageNOCDetails.PreviousMarriageChurch"}
              placeholder={"MarriageNOCDetails.PreviousMarriageChurch"}
              name={"prevMarriageChurch"}
              disabled={disabled}
            />
          </Styled.Field>
          <Styled.Field>
            <InputField
              label={"MarriageNOCDetails.PreviousMarriageFather"}
              placeholder={"MarriageNOCDetails.PreviousMarriageFather"}
              name={"prevMarriageFather"}
              disabled={disabled}
            />
          </Styled.Field>
          <Styled.Field>
            <SelectField
              label={"MarriageNOCDetails.CurrentStatus"}
              placeholder={"MarriageNOCDetails.CurrentStatus"}
              name={"currentStatus"}
              disabled={disabled}
            >
              {
                CURRENT_STATUS.map(({value, label}) => (
                  <Option value={value} key={label}>
                    <FormattedMessage id={label}/>
                  </Option>
                ))
              }
            </SelectField>
          </Styled.Field>
          {currentStatus === "WIDOW" &&
            <>
              <Styled.Field>
                <DatePickerField
                  label={"MarriageNOCDetails.WidowedDate"}
                  placeholder={"MarriageNOCDetails.WidowedDate"}
                  name={"widowedDate"}
                  disabledFiled={disabled}
                />
              </Styled.Field>
              <Styled.Field>
                <RadioField
                  label={"MarriageNOCDetails.AnotherMarriageBeforeWidowed"}
                  name={"marriageBeforeWidowed"}
                  radioSet={ANOTHER_MARRIAGE_RADIO}
                  disabled={disabled}
                />
              </Styled.Field>
              {marriageBeforeWidowed && <>
                <Styled.Field>
                  <InputField
                    label={"MarriageNOCDetails.PreviousSpouseName"}
                    placeholder={"MarriageNOCDetails.PreviousSpouseName"}
                    name={"marriageBeforeWidowedInfo"}
                    disabled={disabled}
                  />
                </Styled.Field>
              </>}
            </>}
        </>
        }
      </Styled.FieldsWrap>
    </MarriageNocDetailsSection>
  );
};

export default MarriageNocDetailsPreviousMarriage;
