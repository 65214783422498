import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledDashboardContainer = styled.div`
	margin: 0 auto 0 auto;
  max-width: 1000px;
	
	// @media ${DEVICE.tabletDevices} {
	// 	width: 100%;
	// 	max-width: 740px;
	// }
	
	@media ${DEVICE.mobileDevices} {
		margin-left: 20px;
		margin-right: 20px;
		
	}
`
export default StyledDashboardContainer;
