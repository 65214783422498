import styled from 'styled-components'
import { Radio } from 'antd';
import { DEVICE } from '../../../constants/media';
const { Group } = Radio;


const StyledRadioGroup = styled(Group)`
  padding-top: 11px;

  @media ${DEVICE.mobileDevices} {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  label {
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: var(--dark);
    margin-right: 22px;
  }

  .ant-radio-wrapper {
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--gray);
  }

  .ant-radio-group {


  }

  .ant-radio-wrapper-checked {
    color: var(--dark);
  }

  .ant-radio-checked::after {
    border: 1px solid #4265da;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--gray);
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #4265da;
    width: 20px;
    height: 20px;
    color: var(--dark);
  }

  .ant-radio-checked .ant-radio-inner::after {
    top: 2px;
    left: 2px;
    display: table;
    width: 14px;
    height: 14px;
    background-color: #4265da;
    background-image: linear-gradient(to top, #4164da, #587cf4);
  }

  span.ant-radio + * {
    padding-right: 0;
    padding-left: 10px;
  }
`;

export default StyledRadioGroup;
