import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledRegistrationPage = styled.div`
	padding: 250px 0 0 0;
    margin: 0 auto;
    justify-content: center;
    width: 328px;
    flex-wrap: wrap;
    
    @media ${DEVICE.mobileDevicesS} {
		width: 300px;
	}
`
export default StyledRegistrationPage;