import styled from 'styled-components';

const StyledAccordionInfo = styled.div`
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--gray);
  max-width: 80%;
`
export default StyledAccordionInfo;