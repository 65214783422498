import { SET_GENERAL_LOADER_STATUS } from "./generalLoader.action";
import initialState from "../../store/initialState";

export default (state = initialState.generalLoader, { type, payload }) => {
  switch (type) {
    case SET_GENERAL_LOADER_STATUS:
      const newState = state + payload
      return newState < 0 ? 0 : newState;
    default:
      return state;
  }
};
