import styled from 'styled-components';
import iconPlus from "../../../../../assets/icons/open.svg";
import iconMinus from "../../../../../assets/icons/Minus.svg";
import { DEVICE } from '../../../../../constants/media';

const StyledAside = styled.div`
    margin-top: 90px;
    margin-bottom: 90px;
    min-width: 330px;
    
    @media ${DEVICE.mobileDevicesS} {
		min-width: 300px;
	}

    && {
          .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
				border-radius: 10px 10px 0 0 ;
				font-family: var(--fontProximaBold);
				color: var(--white);
				background-image: var(--gradientMain);
          }

          .ant-collapse {
              border: none;
              border-radius: 0;
              background: none;
              position: relative;
              z-index: 3;
          }
		  .ant-collapse > .ant-collapse-item:last-child {
		       border-radius: 10px;
		  }
		  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
		        display: none;
		  }
		  .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header::after {
					content: '';
					position: absolute;
					right: 16px;
					top: 11px;
					background: url(${iconMinus}) center center no-repeat;
		  }
		  .ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
					content: '';
					position: absolute;
					right: 17px;
					top: 12px;
					width: 24px;
					height: 24px;
					background: url(${iconPlus}) center center no-repeat;
		  }
		  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
			  font-family: var(--fontProximaRegular);
			  font-size: 16px;
			  height: 48px;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 1.5;
			  letter-spacing: normal;
			  color: var(--dark);
			  padding: 12px 40px 12px 23px;
			  overflow: hidden;
			  transition: all 0.22s;
		  }

		  .ant-collapse-content {
		        border-radius: 0 0 10px 10px;
		  }
}
	}
`
export default StyledAside;