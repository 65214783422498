import React from 'react';
import ModalWrapper from "../../../../hoc/withModal";
import {MODALS_ID} from "../../../../constants/constants";
import LoginDisclaimerContent from './LoginDisclaimer.Content'

const LoginDisclaimerModal = () => {
    return (
        <div>
            <ModalWrapper
                modalId={MODALS_ID.DISCLAIMER_MODAL}
                component={LoginDisclaimerContent}
            >
            </ModalWrapper>
        </div>
    );
};

export default LoginDisclaimerModal;