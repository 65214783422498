import styled from 'styled-components'
import { Select } from 'antd';

const StyledSelect = styled(Select)`
	width: 100%;
	background-color: #ffffff;
	height: 40px;

	&& {
		&:hover {
			.ant-select-selector {
				border: solid 1px var(--gray);
			}
		}
		.ant-select-selector {
			border: solid 1px var(--lightGrey);
			border-radius: 8px; 
			height: 40px;
			color: var(--dark);
			padding-left: 16px;
			font-size: 13px;
		}
		.ant-select-selection-search-input {
			height: 40px;
		}
		.ant-select-selection-placeholder {
			line-height: 38px;
		}
		.ant-select {
			color: var(--dark);
			font-size: 13px;
		}
	}
	
	&.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	    border-color: var(--gray);
	    border-right-width: 1px !important;
	    outline: 0;
	    box-shadow: none;
	}
	&.ant-select-focused {
		.ant-select-arrow {
			color: var(--gray);
		}
	}
	
	.ant-select-arrow {
		top: 14px;
        right: 20px;
	}
`;

export default StyledSelect;