import React from 'react';
import { connect } from "react-redux";
import {createStructuredSelector} from "reselect";

import SpouseInfoForm from "./SpouseInfoForm/SpouseInfoForm";
import { familySelector } from "../../../services/family/family.selector";
import initialState from "../../../store/initialState";
import { familySpouseSelector } from "../../../services/spouse/spouse.selector";
import {FormattedMessage} from "react-intl";
import {FORMS_NAME} from "../../../constants/constants";
import StyledPageTitle from '../../styled/StyledPageTitle';

const SpouseInfo = ({ spouse, family: { spouseStatus, country } }) => {
    const initialSpouseInfo =  initialState.family.spouse;
    return (
    <div>

        <StyledPageTitle>
            <FormattedMessage id="FamilyPage.Spouse.Title" />
        </StyledPageTitle>

        <SpouseInfoForm
            spouseStatus={spouseStatus}
            formName={FORMS_NAME.SPOUSE}
            visaType={spouse ? spouse?.visaType : initialSpouseInfo.visaType}
            nationality={spouse? spouse.nationality : initialSpouseInfo.nationality}
            country={country}
            initialValues={
                { ...( spouse ?
                        { ...spouse,
                            spouseStatus:
                                spouseStatus ? spouseStatus : "available"
                        } :
                        {
                            ...initialSpouseInfo,
                            spouseStatus:
                                spouseStatus ? spouseStatus : "available"
                        }
                    )
                }
            }
        />
    </div>
) };

export default connect(
    createStructuredSelector({
        spouse:familySpouseSelector,
        family: familySelector,
    }),
    {}
)(SpouseInfo);
