import styled from 'styled-components'

const StyledLogo = styled.div`
	margin-bottom: 56px;
	img {
		margin: 0 auto;
		display: block;
	}
`;

export default StyledLogo;