import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledModalContentTitle = styled.h3`
  font-family: var(--fontProximaBold);
  font-size: 16px;
  line-height: 2;
  color: #000000;
  padding: 0 0 17px 40px;
  
  @media ${DEVICE.mobileDevices} {
		margin-bottom: 20px;
		margin-top: 20px;
		padding: 0 0 17px 20px;
	}
`
export default StyledModalContentTitle;