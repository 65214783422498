import React from 'react';
import { FormattedMessage } from "react-intl";

import { urlLocations } from "../../routes/urlLocations";
import BookingsPageList from "./BookingsPageList/BookingsPageList";
import StyledBookingPage from './styled/StyledBookingPage';
import StyledPageTitle from '../styled/StyledPageTitle';
import StyledBackBtn from '../../components/Aside/styled/StyledBackBtn';
import ArrowBack from '../../assets/icons/Arrow.svg';
import StyledBookingContent from './styled/StyledBookingContent';
import Header from '../../components/Header/Header';
const BookingPage = () => (
    <>
        <Header logo={true} />

        <StyledBookingPage>
            <StyledPageTitle align="center">
                <FormattedMessage
                    id="BookingsPage.Title"
                />
            </StyledPageTitle>
            <StyledBackBtn type="secondary" to={urlLocations.home}>
                <img src={ArrowBack} alt="Go back" />
                <FormattedMessage id="FamilyPage.Aside.BackButton" />
            </StyledBackBtn>

            <StyledBookingContent>
                <BookingsPageList/>
            </StyledBookingContent>

        </StyledBookingPage>
    </>
)

export default BookingPage;