import React from 'react';
import { FormattedMessage } from "react-intl";
import { Select } from 'antd';

import SelectField from "../../../components/SelectField/SelectField";
import { SPOUSE_STATUS } from "../../../constants/constants";
import StyledFormRow from '../styled/StyledFormRow';
import StyledFormCol33 from '../styled/StyledFormCol33';


const { Option } = Select;


const SpouseStatusSection = ({ onStatusChange }) => {

    return(
        <>
            <StyledFormRow>
                <StyledFormCol33>
                    <SelectField
                        label="FamilyPage.Spouse.SpouseStatus"
                        placeholder="FamilyPage.Spouse.SpouseStatus"
                        name="spouseStatus"
                        onSelect={onStatusChange}
                    >
                        {
                            SPOUSE_STATUS.map(status =>
                                <Option key={status.value} value={status.value} >
                                    <FormattedMessage id={status.label} />
                                </Option>
                            )
                        }
                    </SelectField>
                </StyledFormCol33>
            </StyledFormRow>
        </>
)};

export default SpouseStatusSection;