import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledDashboardList = styled.div`
  display: flex;
  margin: 120px -22px 0 -22px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  

  @media ${DEVICE.mobileDevices} {
    flex-wrap: wrap;
    justify-content: center;
  }
`
export default StyledDashboardList;
