import styled from 'styled-components';

const StyledLiturgyBookingReason = styled.div`
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--red);
  display: inline-block;
  padding-left: 35px;
`
export default StyledLiturgyBookingReason;