import styled from 'styled-components'

const StyledFormCol100 = styled.div`
	  width: 100%;
	  
	  &:first-child {
	     margin-left: 0;
	  }
	  
`
export default StyledFormCol100;


