import styled , {css} from 'styled-components';
import { Link } from 'react-router-dom';
import ArrowHref from '../../assets/icons/arrowWhite.svg';
import { DEVICE } from '../../constants/media';

const StyledAsideItem = styled(Link)`
    cursor: pointer;
    color: var(--white) ;
    display: inline-block;
    width: 100%;
    height: ${({type})  => (type && type === 'primary') ? '56px' : 'auto'};
    min-height: ${({type})  => (type && type === 'primary') ? '56px' : '72px'};
    font-family: var(--fontProximaBold);
    font-size: 16px;
    text-align: left;
    padding:  ${({type})  => (type && type === 'primary') ? '16px 40px 15px 40px' : '13px 40px 11px 40px'};;
    display: flex;
    box-sizing: border-box;
    transition: all 0.3s ease-in;
    border: none;
    outline: none;
  
    @media ${DEVICE.tabletDevices1200} {
		
	}
    position: relative;
                &:before {
                    content: '';
                    width: 24px;
					height: 24px;

					right: 20px;
                    top: ${({type})  => (type && type === 'primary') ? '17px' : '25px'};
					transform: rotate(-90deg);
					position: absolute;
					display: none;
					background: url(${ArrowHref}) center center no-repeat;
					${({type, isactive}) => {
						if (isactive === 'true' && type === 'secondary') {
							return css`
								top: 50%;
                                margin-top: -12px;
				                `}}}
				                
					${({type, isactive}) => {
						if (isactive === 'true' && type === 'secondary') {
							return css`
								display: block;
				                `}}}
			           
				    ${({isvalid, isactive, type}) => {
						if (isvalid !== true && (type === 'primary') && (isactive === 'true')) {
							return css`
								display: block;
				     `}
}}
                }
    &:focus,
		&:visited {
			border: none;
			outline: none;
		}
		&:hover {
	        color: var(--white) ;
            background-image: linear-gradient(to right, #2D58B9 , #3465D1);
            border: none;
			outline: none;
			svg path {
		        fill: #25b1ff;
			}
		}
		
		${({isactive}) => {
			if (isactive && isactive === 'true') {
				return css`
                    background-image: linear-gradient(to right, #2D58B9 , #3465D1);
                    svg path {
		                fill: #25b1ff;
		            } 
                `}}}
`
export default StyledAsideItem;
