import React from "react";

import StyledLangControl from "./styled/StyledLangControl";
import StyledLangRadioBtn from "./styled/StyledLangRadioBtn";
import StyledLangRadioGroup from "./styled/StyledLangRadioGroup";
import ArabicFlag from "../../assets/img/Arabic.png";
import EngFlag from "../../assets/img/English.png";

const onLanguageSelect = event => {
    localStorage.setItem("lang" , event.target.value);
    document.location.reload();
};

const LangControl = ({posType}) => (
    <StyledLangControl posType={posType}>
        <StyledLangRadioGroup defaultValue={localStorage.getItem("lang") || "en"} onChange={onLanguageSelect} >
            <StyledLangRadioBtn value="en"><img src={EngFlag} alt="en"/>English</StyledLangRadioBtn>
            <StyledLangRadioBtn value="ar"> <img src={ArabicFlag} alt="ar"/> عربي </StyledLangRadioBtn>
        </StyledLangRadioGroup>
    </StyledLangControl>
);

export default LangControl;
