import React from 'react';
import { Radio } from 'antd';
import { Field } from "redux-form";
import {FormattedMessage, injectIntl} from "react-intl";

import ControlWrapper from "../ControlWrapper/ControlWrapper";
import {compose} from "redux";
import {connect} from "react-redux";
import StyledRadioGroup from './styled/StyledRadioGroup';


const CustomRadio = ({ input, label, name, radioSet, ...meta }) => {
    return (
        <ControlWrapper
            name={name}
            label={label}
        >
            <StyledRadioGroup
                name={name}
                {...input}
                { ...meta }
            >
                {radioSet && radioSet.map(radio => (
                    <Radio key={radio.value} value={radio.value} >
                        <FormattedMessage id={radio.label} />
                    </Radio>
                ))}
            </StyledRadioGroup>
        </ControlWrapper>)
};

const RadioField = ({
    name,
    label,
    radioSet,
  disabled
}) => {
    return (
        <Field
            component={CustomRadio}
            name={name}
            label={label}
            radioSet={radioSet}
            disabled={disabled}
        />
    )};

export default compose(
    connect(
        ({ errors }) => ({ errors }),
        {}),
    injectIntl
)(RadioField)
