import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";

import ModalWrapper from "../../../hoc/withModal";
import { MODALS_ID } from "../../../constants/constants";
import { selectLiturgyById } from "../../../services/bookings/bookings.selector";
import { getLocalizedKey } from "../../../utils/utils";
import LiturgyModalForm from "./LiturgyModalForm/LiturgyModalForm";
import StyledModalTitle from "./styled/StyledModalTitle";
import StyledModalHeader from "./styled/StyledModalHeader";
import StyledLiturgyBooking from "./styled/StyledLiturgyBooking";
import StyledInfoBox from "../../styled/StyledInfoBox";
import StyledInfoBoxTxt from "../../styled/StyledInfoBoxTxt";
import StyledInfoBoxIcon from "../../styled/StyledInfoBoxIcon";
import CalendarIcon from "../../../assets/icons/liturgyicon1.svg";
import TimeIcon from "../../../assets/icons/liturgyicon2.svg";
import ChurchIcon from "../../../assets/icons/churchicon.svg";
import StyledModalContent from "./styled/StyledModalContent";
import StyledModalContentTitle from "./styled/StyledModalContentTitle";
import StyledLiturgyContentBooking from "./styled/StyledLiturgyContentBooking";

export const ModalContent = ({ liturgy, isPrivate }) => {
  return (
    <div>
      <StyledModalHeader isPrivate={isPrivate}>
        <StyledModalTitle isPrivate={isPrivate}>
          {liturgy?.title}
        </StyledModalTitle>
      </StyledModalHeader>

      <StyledLiturgyBooking isPrivate={isPrivate}>
        <StyledInfoBox fullwidth>
          <StyledInfoBoxIcon bg={ChurchIcon} />
          <FormattedMessage id="LiturgyPage.Modal.Church" />:
          <StyledInfoBoxTxt>
            {liturgy?.church[getLocalizedKey("name")]}
          </StyledInfoBoxTxt>
        </StyledInfoBox>
        <StyledInfoBox>
          <StyledInfoBoxIcon bg={CalendarIcon} />
          <FormattedMessage id="LiturgyPage.Modal.Date" /> :
          <StyledInfoBoxTxt>
            <FormattedDate
              value={liturgy.date}
              month="long"
              day="numeric"
              year="numeric"
            />
          </StyledInfoBoxTxt>
        </StyledInfoBox>
        <StyledInfoBox>
          <StyledInfoBoxIcon bg={TimeIcon} />
          <FormattedMessage id="LiturgyPage.Modal.Time" /> :
          <StyledInfoBoxTxt>
            <FormattedTime value={liturgy.date} />
          </StyledInfoBoxTxt>
        </StyledInfoBox>
        {isPrivate && (
            <StyledInfoBox>
              <StyledInfoBoxIcon bg={TimeIcon} />
              <FormattedMessage id="LiturgyPage.Booked" /> :
              <StyledInfoBoxTxt>
                {liturgy.bookedCount ?? 0} / {liturgy.maxCapacity}
              </StyledInfoBoxTxt>
            </StyledInfoBox>
        )}
      </StyledLiturgyBooking>

      <StyledModalContent>
        <StyledModalContentTitle>
          <FormattedMessage id="LiturgyPage.Modal.Attend" />:
        </StyledModalContentTitle>

        <LiturgyModalForm
          isPrivate={isPrivate}
          liturgy={liturgy}
          eventId={liturgy.id}
        />
      </StyledModalContent>
    </div>
  );
};

const LiturgyModal = ({ liturgy, members }) => {
  return (
    <ModalWrapper
      modalId={MODALS_ID.LITURGY_BOOKING_MODAL}
      component={ModalContent}
      liturgy={liturgy}
      members={members}
      destroyOnClose
    ></ModalWrapper>
  );
};

export default connect(
  createStructuredSelector({
    liturgy: (state, { selectedEvent }) =>
      selectLiturgyById(state, selectedEvent),
  }),
  {}
)(LiturgyModal);
