import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledBookingCardImg = styled.div`
    width: 207px;
    overflow: hidden;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    
     @media ${DEVICE.mobileDevices} {
		width: 100%;
	}
`

export default StyledBookingCardImg;


