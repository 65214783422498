import React, { useEffect } from 'react';
import StyledAutorizeLayout from './styled/StyledAutorizeLayout';

import { history, urlLocations } from "../../routes/urlLocations";

const AuthorizeLayout = ({ children }) => {
    useEffect(() => {
        const storage = localStorage?.getItem("storageTyp");
        const jwt = window[storage]?.getItem('jwt');
        !jwt && history.push(urlLocations.login)
    }, []);

  return(
      <StyledAutorizeLayout>
          { children }
      </StyledAutorizeLayout>
  )
};

export default AuthorizeLayout;

