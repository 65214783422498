import React from 'react';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import ChildrenForm from "./ChildrenForm/ChildrenForm";
import { CHILD_MODEL } from "../../../constants/models";
import PhotoSection from "../FieldsSections/PhotoSection";
import { familyChildSelector } from "../../../services/children/children.selector";
import { FILES_TYPE, FORMS_NAME } from "../../../constants/constants";
import {FormattedMessage} from "react-intl";
import {urlLocations} from "../../../routes/urlLocations";
import StyledPageTitle from '../../styled/StyledPageTitle';
import StyledGoBackBtn from '../styled/StyledGoBackBtn';
import StyledChildrenInfo from './styled/StyledChildrenInfo';
import StyledPageWrapSubTitle from '../styled/StyledPageWrapSubTitle';
import StyledPageSubTitle from '../styled/StyledPageSubTitle';
import {familyCountrySelector} from "../../../services/family/family.selector";

const ChildrenInfo = ({ child, country }) => {
    return (
        <StyledChildrenInfo>
            <StyledPageTitle>
                <FormattedMessage
                    id="FamilyPage.Child.Title"
                />
            </StyledPageTitle>

            <StyledGoBackBtn>
                <Link to={urlLocations.childrenList}>
                    <FormattedMessage
                        id="FamilyPage.Child.BackToList"
                    />
                </Link>
            </StyledGoBackBtn>

            {!!country?.isMain && <>
                <StyledPageWrapSubTitle>
                    <StyledPageSubTitle>
                        <FormattedMessage
                            id="FamilyPage.Child.YourPhoto"
                        />
                    </StyledPageSubTitle>
                </StyledPageWrapSubTitle>

                <PhotoSection
                    name={FILES_TYPE.PROFILE_PIC}
                    formName={FORMS_NAME.CHILDREN}
                />
            </>
            }
            <ChildrenForm
                formName={FORMS_NAME.CHILDREN}
                initialValues={{...(child ? child : CHILD_MODEL)}}
                visaType={child ? child.visaType : CHILD_MODEL.visaType}
                nationality={child ? child.nationality : CHILD_MODEL.nationality}
                country={country}
                isNew={!child?.id}
            />
        </StyledChildrenInfo>
    )
};

export default compose(
    withRouter,
    connect(
        createStructuredSelector(
            {
                child: (state, { match: { params: { id } } }) =>
                    familyChildSelector(state, id),
                country: familyCountrySelector
            }
        )
    )
)(ChildrenInfo);
