import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledForgot = styled(Link)`
	font-size: 13px;
	color: var(--hrefColorSecondary);
	padding-top: 21px;
	&:hover {
		border: none;
		outline: none;
	}
	&:active {
		border: none;
		outline: none;
	}
	&:focus {
		border: none;
		outline: none;
	}
`
export default StyledForgot;