import React, { useEffect } from 'react';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import isNull from 'lodash/isNull';

import { familySelector } from '../../../services/family/family.selector';
import AddressInfoForm from "./AddressInfoForm/AddressInfoForm";
import StyledPageTitle from '../../styled/StyledPageTitle';
import {getLocalizedKey} from "../../../utils/utils";
import {getAreasAction} from "../../../services/area/area.action";
import {areasSelector} from "../../../services/area/area.selector";

const AddressInfo = ({
     areas,
     getAreas,
     family: {
        geolocation,
        address,
        church,
        emirate,
} }) => {

    useEffect(() => {
        emirate?.id && getAreas(emirate.id)
    }, [emirate]);

    const sortedAreas =
        areas?.sort((a, b) => {
            return (
                a[getLocalizedKey("name")]
                    .localeCompare(b[getLocalizedKey("name")])
            )
        }
    );

    return (
        <div>

            <StyledPageTitle>
                <FormattedMessage
                    id="FamilyPage.Address.Title"
                />
            </StyledPageTitle>
            {((!!address && !!areas ) || isNull(address)) && <AddressInfoForm
                sortedAreas={sortedAreas}
                emirate={emirate}
                getAreas={getAreas}
                address={address}
                initialValues={{
                    geolocation,
                    addressLine: address?.addressLine,
                    areaInfo: address?.areaInfo?.id,
                    church: church?.id,
                    emirate: emirate?.id
                }}
            /> }
        </div>
    )
};

export default connect(
    createStructuredSelector({
        family: familySelector,
        areas: areasSelector,
    }),
    {
        getAreas: getAreasAction,

    }
)(AddressInfo);
