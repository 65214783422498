import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledAdditionalWrapCheckbox = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 20px;
  
  @media ${DEVICE.tablet} {
		max-width: 210px;
	}
  
  p {
      font-size: 13px;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.23;
	  letter-spacing: normal;
	  color: #27282b;
	  padding-right: 16px;
  }
  
  .ant-select-selector {
      @media ${DEVICE.mobileDevices} {
		 max-width: 208px;
	  } 
  }

`
export default StyledAdditionalWrapCheckbox;


