import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const urlLocations = {
  login: "/",
  home: "/home",
  registration: "/registration",
  family: "/family",
  personalInfo: "/family/me",
  spouseInfo: "/family/spouse",
  childrenList: "/family/children",
  childInfo: "/family/child/:id?",
  child: "/family/child",
  parentsList: "/family/parents",
  parentInfo: "/family/parent/:id?",
  parent: "/family/parent",
  parentsInfo: "/family/parents",
  addressInfo: "/family/address",
  liturgy: "/liturgy",
  liturgyInfo: "/liturgy/:id?",
  booking: "/booking",
  privacy: "/privacy",
  resetPassword: "/resetPassword",
  privateEvent: "/liturgy/private/:id?",
  marriageNoc: "/marriage-noc",
  marriageNOCDetails: "/marriage-noc/:id"
};

export const pathBuilder = {
  marriageNOCDetails: uuid => `${urlLocations.marriageNoc}/${uuid}`,
}
