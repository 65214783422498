import React from 'react';
import Styled from "./styled/StyledStatusTag";
import {FormattedMessage} from "react-intl";
import {STATUS_LOCALIZATION} from "../../constants/constants";

const StatusTag = ({status, ...props}) => (
  <Styled.StatusTag status={status} {...props}>
    <FormattedMessage id={STATUS_LOCALIZATION[status]}/>
  </Styled.StatusTag>
);

export default StatusTag;


