import styled from 'styled-components'

const StyledCardsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
     margin-top: 40px;
`

export default StyledCardsList;


