import styled from "styled-components";
import { Link } from "react-router-dom";
import cardBG from "../../assets/img/cardBG.png";

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;
`;

const Card = styled(Link)`
  width: 192px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;
  box-shadow: 0px 0px 10px rgba(139, 155, 163, 0.1);
  border-radius: 10px;
  background-image: url(${cardBG});
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: top;
  transition: box-shadow .3s;

  &:hover {
    box-shadow: 0px 0px 15px rgba(139, 155, 163, 0.3);
  }
`;

const CardImage = styled.div`
  width: 80px;
  border-radius: 10px;
  height: 80px;
  margin: 0 auto 16px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardName = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  color: #27282b;
`;

const CardStatusWrapper = styled.div`
  text-align: center;
  transform: translateY(10px);
`;

const CardStatus = styled.div`
  background: linear-gradient(70.51deg, #4164da 16.8%, #587cf4 93.54%);
  border-radius: 5px;
  padding: 4px 8px;
  color: #ffffff;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 48px auto;
  padding: 0 16px;
  gap: 24px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MembersList = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
`;

const MemberRow = styled.div`
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: #27282b;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 16px;
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  min-height: 64px;
  background-image: linear-gradient(to top, #4164da, #587cf4);
  border-radius: 10px 10px 0 0;
  padding: 16px 24px 16px 40px;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: var(--fontProximaBold);
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  color: #ffffff;
`;

export default {
  List,
  Card,
  CardImage,
  CardName,
  CardStatusWrapper,
  CardStatus,
  Container,
  HeaderRow,
  MembersList,
  MemberRow,
  ModalWrapper,
  ModalHeader,
};
