import styled from "styled-components";
import Button from "../../../../components/Button/Button";

const Content = styled.div`
  padding: 20px;
`;

const Desc = styled.div`
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  &&& {
    display: inline-block;
    margin-right: 15px;

    &:disabled {
      background-color: var(--gray);
      border: 1px solid var(--gray);
      color: var(--white);
      background-image: none;

    }
  }
`;

const StyledAccept = styled(Button)`
   && {
          display: inline-block;
          width: 126px;
          height: 40px;
		  border-radius: 4px;
		  border: 1px solid var(--uploadBtn);
		  font-family: var(--fontProximaRegular);
		  font-size: 13px;
		  line-height: 1.23;
		  color: var(--white);
		  outline: none;
		  box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.1);
		  background-image: var(--gradientMain);
		  transition: all 0.25 ease-in;
	
		  &:hover {
		     color: var(--white);
		     box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.3);
		     background-image: var(--gradientMain);
		  }
		  
		  &:disabled {
		    background-color: var(--gray);
		    border: 1px solid var(--gray);
		    color: var(--white);
		    background-image: none;
		   
		   }
		  	 
    }
`;

export default {
  Content,
  Desc,
  Button: StyledButton,
  StyledAccept
};
