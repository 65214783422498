import styled from 'styled-components'
import { Button } from 'antd';


const StyledFileUploadButton = styled(Button)`
	  min-width: 147px;
	  height: 40px;
	  border-radius: 8px;
	  box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.1);
	  border: 1px solid var(--uploadBtn);
	  background: #fff;
	  color: var(--uploadBtn);
	  font-size: 13px;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 30px;
	  letter-spacing: normal;
	  padding-left: 13px;
	  padding-right: 20px;
	  
	  &:hover {
	    border: 1px solid var(--uploadBtn);
	    color: var(--uploadBtn);
	     box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.2);
	  }
	 
	  &.ant-btn:focus {
	    border: 1px solid var(--uploadBtn);
	    color: var(--uploadBtn);
	    box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.1);
	  }
	  
	  img {
	    padding-right: 7px;
	    position: relative;
        top: -2px;
	  }
`

export default StyledFileUploadButton;


