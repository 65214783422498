import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledMainLayoutBg = styled.div`
	width: 100%;
	background: var(--bgGrayCol);
	position: absolute;
	height: 100px;
	left: 0;
	bottom: -100px;
    
    display: none;
    
    @media ${DEVICE.mobileDevices} {
		display: block;
		
	}
   
`
export default StyledMainLayoutBg;