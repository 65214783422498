import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { DEVICE } from '../../../constants/media';

const StyledButton = styled(({ ...props }) => (
	<Button {...props} />
))`
   && {
          display: block;
          margin:  ${({ aligntype }) => ((aligntype === 'center')? '0 auto;' :'10px 0 0 0;')}; 
          width: 160px;
		  height: 40px;
		  border-radius: 4px;
		  background-image: var(--gradientMain);
		  font-family: var(--fontProximaRegular), sans-serif;
		  font-size: 13px;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.23;
		  letter-spacing: normal;
		  color: var(--white);
		  border: none;
		  
		  box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.2);
		  transition: all 0.3 ease-in;
	
		  &:hover {
		     box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.3);
		  }
		  
		  &.ant-btn-sm {
		     width: 126px;
             height: 48px;
		  }
		  
		    
    ${({ uiType }) => {
		if ( uiType && uiType ==='secondary') {
			return css`
						 min-width: 158px;
						 width: auto;
						 @media ${DEVICE.tabletDevices} {
								margin-left: 8px;
							    margin-left: 8px;
						}
                         display: inline-block;
                         background: var(--bgLight);
                         color: var(--hrefColorSecondary);
                         border: 1px solid var(--hrefColorSecondary);
                         display: flex;
                         justify-content: center;
                         padding-left: 20px;
                         padding-right: 20px;
                         align-items: center;
                         box-shadow: 0 10px 15px 0 rgba(66, 101, 218, 0.1);
                         
                         &:hover {
		                    box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.2);
		                 }
                         
                         i {
                            display: inline-block;
                            margin-right: 12px;
                            position: relative;
                            top: 1px;
                         }
                         
                         &:disabled {
                            color: var(--dark);
                            border: 1px solid #d6e1e9;
                         }
                    `}
	}}	 
    }
    
`
export default StyledButton;
