import styled , { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { DEVICE } from '../../../constants/media';


const StyledBackBtn = styled(Link)`
	  width: 144px;
      height: 32px;
      border-radius: 10px;
      border: solid 1px #386ab3;
      font-size: 13px;
      padding-top: 4px;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.23;
	  letter-spacing: normal;
      color: #e4ecee;
      margin-top: 25px;
      margin-left: 40px;
      padding-left: 12px;
      cursor: pointer;
      display: inline-block;
      transition: all 0.25s ease-in;
      
      &:hover {
			color: #e4ecee;
      }
      
      ${({ type }) => {
		if ( type && type ==='secondary') {
		return css`
				border: solid 1px var(--lightGrey);
				color: var(--dark);
				background: var(--white);
				position: absolute;
				top: 0;
				left: 0;
				margin-top: 13px;
				margin-left: 0;
				
				&:hover {
			        border: solid 1px var(--lightGrey);
					color: var(--dark);
					background: var(--white);
                }
                
                @media ${DEVICE.mobileDevices} {
                    position: absolute;
                    top: -62px;
                    left: 22px;
                }
				
              `}
		}}	 
      
      img {
            transform: rotate(90deg);
        }
	  
	  // left: ${({ collapsed }) => collapsed ? '2px': '-1px' };
		//         top: ${({ collapsed }) => collapsed ? '-2px': '-1px' };
		//         transform: rotate(${({ collapsed }) => collapsed && '180deg' }); 
`
export default StyledBackBtn;