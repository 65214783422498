import styled from 'styled-components'


const StyledSpouseInfoForm = styled.div`
    margin-top: 40px;
`

export default StyledSpouseInfoForm;


