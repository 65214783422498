import React from 'react';

import isArray from 'lodash/isArray';
import ModalWrapper from '../../../../hoc/withModal';
import { MODALS_ID } from "../../../../constants/constants";
import BookingPageModifyForm from "../BookingPageModify.Form/BookingPageModify.Form"

const BookingPageModifyModal = ({
    bookedMembers,
    members,
    bookingId
}) => {
    const preparedBookedMembers = isArray(bookedMembers) ? bookedMembers : [bookedMembers];

    return (
    <>
        <ModalWrapper
            modalId={`${MODALS_ID.BOOKING_MODIFY_MODAL}_${bookingId}`}
            component={BookingPageModifyForm}
            bookedMembers={bookedMembers}
            members={members}
            eventId={bookingId}
            initialValues={
                {
                    ...members.reduce((acc, member) => (
                        {...acc, ...( member?.id && {[`"${member?.id}"`] :
                            preparedBookedMembers?.some(
                                bookedMember => bookedMember &&  bookedMember?.toString() === member?.id?.toString()
                            )})
                        }
                ), {})
                }
            }
        />
    </>
)};

export default BookingPageModifyModal;