import React, { useEffect } from 'react';
import Aside from './Aside/Aside'
import { history, urlLocations } from "../../routes/urlLocations";
import StyledMainLayout from './styled/StyledMainLayout';
import StyledColMainLayout from './styled/StyledColMainLayout';
import LangControl from '../../components/LangControl/LangControl'
import  StyledMainLayoutBg from './styled/StyledMainLayoutBg';
export default ({ children }) => {
    useEffect(() => {
        const storage = localStorage.getItem("storageTyp");
        const jwt = window[storage]?.getItem('jwt');
        jwt && history.push(urlLocations.home)
    }, []);

    return (
        <StyledMainLayout>
           <StyledColMainLayout typeCol="left">
                <Aside/>
               <StyledMainLayoutBg></StyledMainLayoutBg>
           </StyledColMainLayout>
            <StyledColMainLayout typeCol="right">
                <LangControl posType="absolute"/>
                { children }
            </StyledColMainLayout>
        </StyledMainLayout>
    )
};

