import React from "react";
import ModalWrapper from "../../../hoc/withModal";
import { MODALS_ID } from "../../../constants/constants";
import BookingPageCardCancelContent from "./BookingPageCard.CancelContent";

const BookingPageCardCancelModal = ({ bookingId, booking, onCancel }) => {
  return (
    <div>
      <ModalWrapper
        onCancel={onCancel}
        component={BookingPageCardCancelContent}
        modalId={`${MODALS_ID.BOOKING_CANCEL_MODAL}_${bookingId}`}
        booking={booking}
      />
    </div>
  );
};

export default BookingPageCardCancelModal;
