import React from 'react';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Spin } from 'antd'

import { selectFile } from '../../../services/file/file.selector';
import FileUploader from "../FieldsSections/FileUploadSection";
import { removeFileAction } from "../../../services/file/file.action";
import StyledDocumentsUploader from './styled/StyledDocumentsUploader';
import StyledUploaderBlock from './styled/StyledUploaderBlock';
import StyledUploaderRemove from '../../styled/StyledUploaderRemove';
import StyledUploaderPreviewBlock from './styled/StyledUploaderPreviewBlock';
import StyledDocumentsList from "./styled/StyledDoumentsList";
import {isFileUploadingSelector} from "../../../services/filesUploader/filesUploader.selector";

const DocumentsUploader = ({
   name,
   formName,
   children,
   files,
   removeFile,
   isUploading
}) => (
    <Spin spinning={!!isUploading} >
        <StyledDocumentsUploader>

            <FileUploader
                formName={formName}
                name={name}
            >
                { children }
            </FileUploader>
            <StyledDocumentsList>
                { files && files.map(file => {

                    return(
                        <StyledUploaderBlock key={file.id}>
                            <StyledUploaderPreviewBlock>
                                <img src={file.url} alt={""} />
                                <StyledUploaderRemove onClick={removeFile.bind(null, { id: file.id, name, formName })} />
                            </StyledUploaderPreviewBlock>
                        </StyledUploaderBlock>)
                }
                )}
            </StyledDocumentsList>
        </StyledDocumentsUploader>
    </Spin>
);

export default connect(
    createStructuredSelector({
        isUploading: (state, { name, formName }) => isFileUploadingSelector(state, formName, name),
        files: (state, { name, formName }) => selectFile(state, name, formName)
    }),
    { removeFile: removeFileAction })
(DocumentsUploader)