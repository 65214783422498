import {
  FILES_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  CLEAR_FILES,
  FILE_REMOVE,
  ADD_FILES,
} from "./file.action";
import initialState from "../../store/initialState";
import { FILES_TYPE } from "../../constants/constants";

export default (state = initialState.files, { type, payload }) => {
  switch (type) {
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        [payload.formName]: {
          ...state[payload.formName],
          [payload.name]:
            payload.name === FILES_TYPE.PROFILE_PIC || payload.name === FILES_TYPE.MARRIAGE_NOC_PHOTO
              ? [...payload.files]
              : [
                  ...state?.[payload.formName]?.[payload.name],
                  ...payload.files,
                ],
        },
      };
    case FILES_UPLOAD:
      return {
        ...state,
        [payload.formName]: {
          ...payload.file,
        },
      };

    case FILE_REMOVE:
      return {
        ...state,
        [payload.formName]: {
          ...state[payload.formName],
          [payload.name]: [
            ...(state?.[payload.formName]?.[payload.name]
              ? state?.[payload.formName]?.[payload.name].filter(
                  (file) => file.id.toString() !== payload.fileId.toString()
                )
              : []),
          ],
        },
      };
    case CLEAR_FILES:
      return {
        ...state,
        [payload.formName]: initialState.files[payload.formName],
      };
    case ADD_FILES:
      return {
        ...state,
        [payload.formName]: {
          ...state[payload.formName],
          [payload.name]: payload.files,
        },
      };
    default:
      return state;
  }
};
