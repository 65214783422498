import { call, put, select, takeEvery } from "redux-saga/effects";
import { CLEAR_ERRORS, SET_ERRORS } from "../errors/errors.action";
import { prepareFilesDataForSave } from "../file/file.utils";
import { setFamilySpouseRequest } from "../spouse/spouse.api";
import {
  SET_FAMILY_SPOUSE,
  SET_FAMILY_SPOUSE_FAILURE,
  SET_FAMILY_SPOUSE_SUCCESS
} from "../spouse/spouse.action";
import openNotification from "../../utils/notifications";
import { history, urlLocations } from "../../routes/urlLocations";
import { GET_FAMILY_INFO } from "../family/family.action";

function* setFamilySpouse({ payload }) {
  try {
    yield put({ type: CLEAR_ERRORS });
    const files = yield select(state => state.files);
    const prepareFiles = prepareFilesDataForSave(files.spouse);

    const { spouseStatus, ...spouse } = payload;

    const { response } = yield setFamilySpouseRequest({
      spouseStatus,
      spouse: { ...spouse, ...prepareFiles }
    });
    if (response.status === 200) {
      yield put({ type: SET_FAMILY_SPOUSE_SUCCESS });
      yield put({ type: GET_FAMILY_INFO });
      history.push(urlLocations.childrenList);
      yield call(openNotification, {
        message: "FamilyPage.Spouse.Success",
        description: "FamilyPage.Spouse.SuccessMessage",
        type: "success"
      });
    } else {
      yield put({
        type: SET_ERRORS,
        payload: response.data.data
      });
      yield call(openNotification, {
        message: "FamilyPage.Spouse.Error",
        description: "FamilyPage.Spouse.ErrorMessage",
        type: "error"
      });
      yield put({ type: SET_FAMILY_SPOUSE_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* spouseSaga() {
  yield takeEvery(SET_FAMILY_SPOUSE, setFamilySpouse);
}
