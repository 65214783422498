import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledHomePageCol = styled.div`
	width: 50%;
	display: inline-block;
	
	@media ${DEVICE.mobileDevices} {
		width: 100%;
	}

`
export default StyledHomePageCol;