import React from "react";
import { connect } from "react-redux";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import isEmpty from "lodash/isEmpty";

import StyledLiturgyItemHeader from "../LiturgyList/styled/StyledLiturgyItemHeader";
import StyledLiturgyItemTitle from "../LiturgyList/styled/StyledLiturgyItemTitle";
import { getLocalizedKey } from "../../../utils/utils";
import StyledInfoBox from "../../styled/StyledInfoBox";
import StyledInfoBoxIcon from "../../styled/StyledInfoBoxIcon";
import CalendarIcon from "../../../assets/icons/liturgyicon1.svg";
import TimeIcon from "../../../assets/icons/liturgyicon2.svg";
import StyledLiturgyItemBottom from "../LiturgyList/styled/StyledLiturgyItemBottom";
import StyledLiturgyButton from "../LiturgyList/styled/StyledLiturgyButton";
import { MODALS_ID } from "../../../constants/constants";
import StyledLiturgyItem from "../LiturgyList/styled/StyledLiturgyItem";
import { setModalStatusAction } from "../../../services/modals/modals.action";
import { checkEventAvailabilityAction } from "../../../services/eventAvailability/eventAvailability.action";
import { createStructuredSelector } from "reselect";
import { getBookingByEventIdSelector } from "../../../services/bookings/bookings.selector";
import StyledLiturgyExclusive from "../LiturgyList/styled/StyledLiturgyExclusive";
import StyledLiturgyNotRestricted from "../LiturgyList/styled/StyledLiturgyNotRestricted";
import StyledLiturgyTag from "../LiturgyList/styled/StyledLiturgyTag";
import StyledLiturgyTagsBox from "../LiturgyList/styled/StyledLiturgyTagsBox";

const LiturgyCard = ({
  item,
  setModalStatus,
  checkEventAvailability,
  selectEvent,
  booking,
  isPrivate,
}) => {
  const setButtonName = ({ bookedCount, maxCapacity }) => {
    if (bookedCount >= maxCapacity) {
      return "LiturgyPage.Button.FullyBooked";
    } else if (!isEmpty(booking)) {
      return "LiturgyPage.Button.Booked";
    }
    return "LiturgyPage.Button.Book";
  };

  return (
    <>
      <StyledLiturgyItem key={item?.id}>
        <StyledLiturgyItemHeader>
          <StyledLiturgyItemTitle>
            {item[getLocalizedKey("title")]} {"  "}
            {item.exclusive && (
              <StyledLiturgyExclusive>
                <FormattedMessage
                  id="LiturgyPage.Exclusive"
                  values={{ churchName: item.church[getLocalizedKey("name")] }}
                />
              </StyledLiturgyExclusive>
            )}
            {item.skipBookingWindowRestrictions && (
              <StyledLiturgyNotRestricted>
                <FormattedMessage id="LiturgyPage.NotRestricted" />
              </StyledLiturgyNotRestricted>
            )}
            <br />
            {item.eventTags && item.eventTags.length > 0 && (
              <StyledLiturgyTagsBox>
                {item.eventTags
                  .sort((t1, t2) => t1.rank - t2.rank)
                  .map((tag) => (
                    <StyledLiturgyTag bg={tag.color} textColor={tag.textColor}>
                      {tag[getLocalizedKey("title")]}
                    </StyledLiturgyTag>
                  ))}
              </StyledLiturgyTagsBox>
            )}
          </StyledLiturgyItemTitle>
          <StyledInfoBox>
            <StyledInfoBoxIcon bg={CalendarIcon} />
            <FormattedDate
              value={item.date}
              month="long"
              day="numeric"
              year="numeric"
              weekday="short"
            />
          </StyledInfoBox>

          <StyledInfoBox>
            <StyledInfoBoxIcon bg={TimeIcon} />
            <FormattedTime value={item.date} />
          </StyledInfoBox>
        </StyledLiturgyItemHeader>

        <StyledLiturgyItemBottom>
          <StyledInfoBox padding="bottom">
            <StyledInfoBoxIcon bg={TimeIcon} />
            <FormattedMessage id="LiturgyPage.Booked" /> :{" "}
            {item.bookedCount ?? 0} / {item.maxCapacity}
          </StyledInfoBox>

          {!isPrivate && (
            <StyledLiturgyButton
              propss={checkEventAvailability.bind({ id: item.id })}
              onClick={() => {
                isEmpty(booking) && selectEvent(item.id);
                isEmpty(booking) &&
                  setModalStatus(MODALS_ID.LITURGY_BOOKING_MODAL);
                isEmpty(booking) && checkEventAvailability({ id: item.id });
                !isEmpty(booking) &&
                  setModalStatus(MODALS_ID.LITURGY_BOOKED_MODAL);
              }}
              disabled={item.bookedCount >= item.maxCapacity}
            >
              <FormattedMessage
                id={setButtonName({
                  bookedCount: item.bookedCount,
                  maxCapacity: item.maxCapacity,
                })}
              />
            </StyledLiturgyButton>
          )}
        </StyledLiturgyItemBottom>
      </StyledLiturgyItem>
    </>
  );
};

export default connect(
  createStructuredSelector({
    booking: (state, { eventId }) =>
      getBookingByEventIdSelector(state, eventId),
  }),
  {
    setModalStatus: setModalStatusAction,
    checkEventAvailability: checkEventAvailabilityAction,
  }
)(LiturgyCard);
