import styled from "styled-components";
import { DEVICE } from "../../constants/media";

const Page = styled.div`
  padding: 250px 0 0 0;
  margin: 0 auto;
  justify-content: center;
  width: 328px;

  @media ${DEVICE.mobileDevicesS} {
    width: 300px;
  }
`;

export default {
  Page
};
