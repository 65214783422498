import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const StyledUserBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${DEVICE.tablet} {
    min-width: 150px;
  }

  .ant-badge {
    margin-right: 50px;

    @media ${DEVICE.mobileDevices} {
      margin-right: 10px;
    }
  }
  .ant-badge-dot {
    width: 9px;
    height: 9px;
    top: 5px;
    right: 5px;
    border: solid 1px #fbfcfd;
    background: var(--blueDot);
  }
  .ant-dropdown-link {
    & > span {
      display: none;
    }
  }

  .ant-avatar {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    box-shadow: 0 4px 4px 0 #edf0f5;
    border: solid 2px #ffffff;
  }

  .ant-dropdown-link {
    p {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;

      @media ${DEVICE.mobileDevices} {
        display: none;
      }
    }
  }

  a {
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    svg {
      margin-left: 10px;
    }
  }
`;

export default StyledUserBlock;
