import React from 'react';
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

import { urlLocations } from "../../../routes/urlLocations";
import { familyParentsSelector } from "../../../services/parents/parents.selector";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Button from "../../../components/Button/Button";
import { setNoParentsAction } from "../../../services/parents/parents.action";

import StyledFormRow from '../styled/StyledFormRow';
import StyledPageTitle from '../../styled/StyledPageTitle';
import StyledParentsList from './styled/StyledParentsList';
import StyledFormLastCol from '../styled/StyledFormLastCol';
import StyledCardsList from '../styled/StyledCardsList';
import StyledCardItem from '../styled/StyledCardItem';
import StyledCardItemContent from '../styled/StyledCardItemContent';
import StyledCardItemTxt from '../styled/StyledCardItemTxt';
import StyledCardItemImg from '../styled/StyledCardItemImg';
import StyledAddCardItem from '../styled/StyledAddCardItem';
import {familyCountrySelector} from "../../../services/family/family.selector";
import {AVATAR_URL} from "../../../constants/constants";

const ParentsList = ({ parents, setNoParents, numberOfGrantParents, country }) => {
    return <StyledParentsList>
            <StyledPageTitle>
                <FormattedMessage
                    id="FamilyPage.Parent.ListTitle"
                />
            </StyledPageTitle>

        { !parents.length && <StyledFormRow>
                <Checkbox
                    checked={numberOfGrantParents === 0}
                    disabled={numberOfGrantParents === 0}
                    onChange={event => {
                        event.target.checked && setNoParents()
                    }}
                >
                    <FormattedMessage id="FamilyPage.Parent.NoParents" />
                </Checkbox>

            </StyledFormRow> }

            <StyledCardsList>
                { parents?.map(parent => (
                    <StyledCardItem key={parent.id}>
                        <Link to={`${urlLocations.parent}/${parent.id}`} >
	                            <StyledCardItemImg><img src={parent.profilePic?.url ?? AVATAR_URL} alt=""/></StyledCardItemImg>

                                <StyledCardItemContent>
                                    <StyledCardItemTxt>{ parent.firstName }</StyledCardItemTxt>
                                    <StyledCardItemTxt>{ parent.secondName }</StyledCardItemTxt>
                                    <StyledCardItemTxt>{ parent.lastName }</StyledCardItemTxt>
                                </StyledCardItemContent>
                        </Link>
                    </StyledCardItem>
                ))}

                <StyledAddCardItem>
                    <Link to={urlLocations.parent}>
                        <FormattedMessage
                            id="FamilyPage.Parent.AddParent"
                        />
                    </Link>
                </StyledAddCardItem>
            </StyledCardsList>

        <StyledFormLastCol>
            {!!country?.isMain ?
                <Link to={urlLocations.addressInfo}>
                    <Button size="small">
                        <FormattedMessage
                            id="FamilyPage.Parent.Next"
                        />
                    </Button>
                </Link>
                :
                <Link to={urlLocations.home}>
                    <Button size="small">
                        <FormattedMessage
                            id="FamilyPage.Parent.Finish"
                        />
                    </Button>
                </Link>
            }
        </StyledFormLastCol>


    </StyledParentsList>
};

export default connect(
    createStructuredSelector({
        parents: familyParentsSelector,
        numberOfGrantParents: state => state.family?.numberOfGrantParents,
        country: familyCountrySelector
    }),
    {
        setNoParents : setNoParentsAction
    }
)(ParentsList)
