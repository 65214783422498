import React from "react";

import Button from "../../../components/BorderedButton/Button";
import Styled from "./styled";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const BookingPageCardCancelContent = ({ setPopupStatus, onCancel, booking }) => {
  return (
    <Styled.Content>
      <Styled.Message>
        <FormattedMessage id={"CancelBooking.Message"} />
      </Styled.Message>
        {booking.event.applyCanceledPenalty && moment(booking.event.date).diff(moment(), 'hour') <= 24 && <Styled.Warning>
            <FormattedMessage id={"CancelBooking.Warning"}/>
        </Styled.Warning>}
      <Styled.BottomRow>
        <Button onClick={onCancel}>
          <FormattedMessage id={"CancelBooking.Yes"} />
        </Button>
        <Button
          onClick={() => setPopupStatus(null)}
          uitype={"secondary-bordered"}
        >
          <FormattedMessage id={"CancelBooking.No"} />
        </Button>
      </Styled.BottomRow>
    </Styled.Content>
  );
};

export default BookingPageCardCancelContent;
