import React, {useState} from 'react';
import { Select } from "antd"
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import InputField from "../../../components/InputField/InputField";
import { registrationAction} from "../../../services/registration/registration.action";
import SelectField from "../../../components/SelectField/SelectField";
import CheckboxField from "../../../components/CheckboxField/CheckboxField";
import Button from "../../../components/Button/Button";
import StyledAgreement from "./styled/StyledAgreement";
import StyledFormRow from "../../styled/StyledFormRow";
import StyledAgreementRow from "./styled/StyledAgreementRow";
import {urlLocations} from "../../../routes/urlLocations";
import { passwordValidate as validate } from '../../../utils/validate'
import {compose} from "redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {
    appInfoChurchesSelector,
    appInfoCountriesSelector,
    appInfoEmiratesSelector
} from "../../../services/appInfo/appInfo.selector";
import {geolocationSelector} from "../../../services/geolocation/geolocation.selector";
import {setGeolocationAction} from "../../../services/geolocation/geolocation.action";
import {getLocalizedKey} from "../../../utils/utils";


const Option = Select.Option;


const RegistrationForm = ({ handleSubmit, countries }) => {
    const [ agreeStatus, setAgreeStatus ] = useState(false);
    return (
        <form onSubmit={handleSubmit} >
            <StyledFormRow>
                <SelectField
                    name="familyType"
                    label="Registration.Status.Placeholder"
                >
                    <Option value="family">
                        <FormattedMessage
                            id="Registration.FamilyStatus.Family"
                        />
                    </Option>
                    <Option value="alone">
                        <FormattedMessage
                            id="Registration.FamilyStatus.Alone"
                        />
                    </Option>
                </SelectField>
            </StyledFormRow>
            <StyledFormRow>
                <SelectField
                    name="country"
                    label="Registration.Country"
                >
                    {countries && countries.map(country => (
                        <Option key={country.id} value={country.id} >
                            { country[getLocalizedKey("title")] }
                        </Option>))}
                </SelectField>
            </StyledFormRow>
            <StyledFormRow>
                <InputField
                    name="email"
                    label="Registration.Email.Placeholder"
                />
            </StyledFormRow>

            <StyledFormRow>
                <InputField
                    name="password"
                    type="password"
                    label="Registration.Password.Placeholder"
                />
            </StyledFormRow>

            <StyledAgreementRow>
                <CheckboxField
                    name="agreement"
                    onChange={(event) => setAgreeStatus(event.target.checked) }
                >

                    <StyledAgreement>
                        <FormattedMessage
                            id="Registration.Agreement"
                        />
                        <Link to={urlLocations.privacy}>
                            <FormattedMessage
                                id="Registration.TermsAndConditions"
                            />
                        </Link>
                    </StyledAgreement>
                </CheckboxField>
            </StyledAgreementRow>
            <StyledFormRow>
                <Button disabled={!agreeStatus} htmlType="submit" aligntype="center" >
                    <FormattedMessage id="Registration.Button" />
                </Button>
            </StyledFormRow>


        </form>
    )
};

export default compose(
    connect(
        createStructuredSelector({
            countries: appInfoCountriesSelector,
        }),{}
    ),
    reduxForm({
        form: 'registrationForm',
        validate,
        enableReinitialize: true,
        onSubmit: (value, dispatch) => dispatch(registrationAction(value))
    })
)
(RegistrationForm);
