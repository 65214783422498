import { createAction } from "redux-actions";

export const SET_FAMILY_CHILD = "SET_FAMILY_CHILD";
export const SET_FAMILY_CHILD_SUCCESS = "SET_FAMILY_CHILD_SUCCESS";
export const SET_FAMILY_CHILD_FAILURE = "SET_FAMILY_CHILD_FAILURE";

export const SET_NO_CHILD = "SET_NO_CHILD";
export const SET_NO_CHILD_SUCCESS = "SET_NO_CHILD_SUCCESS";
export const SET_NO_CHILD_FAILURE = "SET_NO_CHILD_FAILURE";

export const setFamilyChildAction = createAction(SET_FAMILY_CHILD);
export const setNoChildrenAction = createAction(SET_NO_CHILD);
