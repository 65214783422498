import styled from 'styled-components'

const StyledParentsList = styled.div`
	max-width: 540px;
    h1 {
        margin-bottom: 40px;
    }
`

export default StyledParentsList;


