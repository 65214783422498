import React, {useEffect, useState} from 'react';
import { Select } from "antd";
import { FormattedMessage } from "react-intl";

import SelectField from "../../../components/SelectField/SelectField";
import InputField from "../../../components/InputField/InputField";
import DocumentsUploader from "../DocumentsUploader/DocumentsUploader";
import {FILES_TYPE, NATIONALITIES, NATIONALITIES_VALUES} from "../../../constants/constants";

import StyledFormRow from '../styled/StyledFormRow';
import StyledFormCol100 from '../styled/StyledFormCol100';
import StyledFormCol33 from '../styled/StyledFormCol33';

const { Option } = Select;

const NationalitySection = ({ nationality: initialNationality, formName }) => {
    const [ nationality, selectNationality ] = useState(initialNationality);

    useEffect(() => {
        selectNationality(initialNationality);
    }, [initialNationality]);

    return (
        <>

            <StyledFormRow>
                <StyledFormCol33>
                    <SelectField
                        onSelect={selectNationality}
                        label="FamilyPage.Form.Nationality"
                        placeholder="FamilyPage.Form.Nationality"
                        name="nationality"
                    >
                        {
                            NATIONALITIES.map(nationality =>
                                <Option key={nationality.value} value={nationality.value} >{
                                    <FormattedMessage id={nationality.label} />
                                }</Option>)
                        }
                    </SelectField>
                </StyledFormCol33>

                <StyledFormCol33>
	                {
		                nationality === NATIONALITIES_VALUES.other ?
                            <>
                                    <InputField
                                        label="FamilyPage.Form.PassportNumber"
                                        name="passportNumber"
                                    />
                            </>
			                :
                            <>
                                <InputField
                                        label="FamilyPage.Form.NationalID"
                                        name="nationalIDNumber"
                                    />
                            </>
                    }

                </StyledFormCol33>

                <StyledFormCol33>

                </StyledFormCol33>

            </StyledFormRow>
                    {
                        nationality === NATIONALITIES_VALUES.egyptian &&
                            <>

                            <StyledFormRow>
                                <StyledFormCol100>
                                    <DocumentsUploader
                                        formName={formName}
                                        name={FILES_TYPE.NATIONAL_ID}
                                    >
                                        <FormattedMessage
                                            id="FamilyPage.Form.UploadNationalID"
                                        />
                                    </DocumentsUploader>

                                </StyledFormCol100>
                            </StyledFormRow>
                            </>
                    }

        </>
    )
};

export default NationalitySection;