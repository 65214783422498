import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {Spin} from "antd";

import MarriageNocDetailsPersonalInfo from "./MarriageNOCDetails.PersonalInfo";
import MarriageNocDetailsPreviousEngagement from "./MarriageNOCDetails.PreviousEngagement";
import MarriageNocDetailsPreviousMarriage from "./MarriageNOCDetails.PreviousMarriage";
import MarriageNOCDetailsDivorcement from "./MarriageNOCDetails.Divorcement";
import MarriageNOCDetailsWitnesses from "./MarriageNOCDetails.Witnesses";
import MarriageNocDetailsUpload from "./MarriageNOCDetails.Upload";
import {
  submitMarriageNocDraftAction,
  updateMarriageNocDraftAction,
} from "../../services/marriageNoc/marriageNoc.actions";
import Button from "../../components/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {isLoadingSelector} from "../../services/loader/loader.selector";
import Styled from "./styled";
import {FILES_TYPE, FORMS_NAME, LOADERS, STATUS} from "../../constants/constants";
import PhotoSection from "../FamilyPage/FieldsSections/PhotoSection";
import FileUploader from "../../components/FileUploader/FileUploader";
import MarriageNocDetailsSection from "./MarriageNOCDetails.Section";
import {isNOCEditable} from "../../utils/utils";


const MarriageNocDetailsForm = ({handleSubmit, initialValues, marriageNOC}) => {
  const isLoading = useSelector((state) => isLoadingSelector(state, LOADERS.marriageNOC));
  const isEditable = isNOCEditable(marriageNOC);

  const dispatch = useDispatch();

  const handleSubmitClick = (values) => {
    dispatch(submitMarriageNocDraftAction(values));
  };

  return (
    <Spin spinning={!!isLoading}>
      <PhotoSection
        formName={FORMS_NAME.MARRIAGE_NOC}
        name={FILES_TYPE.MARRIAGE_NOC_PHOTO}
        isEditable={isEditable}
      />
      <form onSubmit={handleSubmit}>
        <MarriageNocDetailsPersonalInfo disabled={!isEditable}/>
        <MarriageNocDetailsPreviousEngagement disabled={!isEditable}/>
        <MarriageNocDetailsPreviousMarriage disabled={!isEditable}/>
        <MarriageNOCDetailsDivorcement disabled={!isEditable}/>
        <MarriageNOCDetailsWitnesses disabled={!isEditable}/>
        <MarriageNocDetailsSection title={"MarriageNOCDetails.Documents"}>
          <FileUploader
            formName={FORMS_NAME.MARRIAGE_NOC}
            name={FILES_TYPE.MARRIAGE_NOC_DOCUMENTS}
            isFormDisabled={!isEditable}>
            <FormattedMessage id={"MarriageNOCDetails.UploadDocuments"}/>
            <Styled.DocsHint>
              <FormattedMessage id={"MarriageNOCDetails.UploadDocumentsHint"}/>
            </Styled.DocsHint>
          </FileUploader>
        </MarriageNocDetailsSection>
        {isEditable && (
          <Styled.ButtonsRow>
            {marriageNOC?.status === "DRAFT" && <Button htmlType={"submit"}>
              <FormattedMessage id={"MarriageNOCDetails.SaveDraft"}/>
            </Button>}
            <Button onClick={handleSubmit(values => handleSubmitClick(values))}>
              <FormattedMessage id={"MarriageNOCDetails.Submit"}/>
            </Button>
          </Styled.ButtonsRow>
        )}
      </form>
    </Spin>
  );
};

export default reduxForm({
  form: FORMS_NAME.MARRIAGE_NOC,
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmit: (value, dispatch) => dispatch(updateMarriageNocDraftAction(value)),
})(MarriageNocDetailsForm);
