import styled, { css } from 'styled-components'
import { Layout } from 'antd';
import { DEVICE } from '../../../constants/media';
import closeIcon from '../../../assets/icons/Close.svg';
const { Sider } = Layout;

const StyledAside = styled(Sider)`
    height: 100%;
    position: relative;
    height: auto;
    padding: 36px 0 50px 0;
    background-image: linear-gradient(to top, #19407d, #1250af);
    min-height: 100vh;
    z-index: 5;
    overflow: hidden;
    
      @media ${DEVICE.tabletDevices1200} {
             flex: 0 0 0 !important;
		     max-width: 0 !important;
		     min-width: 0 !important;
		     width: 0 !important; 
		     
		   ${({ collapsed }) => {
			if (collapsed ) {
				return css`
                    flex: 0 0 300px !important;
		            max-width: 300px !important;
		            min-width: 300px !important;
		            width: 300px !important;
                `}}}
      }
     
      @media ${DEVICE.laptopS} {
            flex: 0 0 344px !important; 
		    max-width: 344px !important; 
		    min-width: 344px !important; 
		    width: 344px !important; 
		    
		    ${({ collapsed }) => {
				if (collapsed ) {
					return css`
                    flex: 0 0 344px !important; 
		            max-width: 344px !important; 
		            min-width: 344px !important; 
		            width: 344px !important; 
                `}}}
      }
     
     }
      

      
    
     @media ${DEVICE.laptopS} {
		.ant-layout-sider-trigger {
            display: none;
        }  
	  }
	   
	   
	  @media ${DEVICE.tabletDevices1200} {
		.ant-layout-sider-children {
	        padding-top: 40px }; 
	    }
	    
	     .ant-layout-sider-trigger {
            top: 0;
            position: static;
            background: none;
            
            &:after {
                content: '';
                position: absolute;
                width: 24px;
                height: 24px;
                top: 20px;
                right: 15px;
                background: url(${closeIcon}) center center no-repeat;
            }
        }
	  }
	    
`
export default StyledAside;