import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledBookingCardWrapBtn = styled.div`
    // width: 155px;
    display: flex;
    
    @media ${DEVICE.mobileDevicesS} {
		// width: 145px;
	}
	
	@media ${DEVICE.mobileDevices} {
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 20px;
		
		&& {
			button {
				margin-bottom: 20px;
			}
		}
	}
	
`

export default StyledBookingCardWrapBtn;


