import styled from 'styled-components';
import CollapseIcon from '../../assets/icons/collapsed.svg'
import { DEVICE } from '../../constants/media';

const StyledCollapseIcon = styled.div`
   position: absolute;
    width: 24px;
    height: 24px;
    position: relative;
    top: 40px;
    left: 20px;
    z-index: 5;
    
     @media ${DEVICE.mobileDevices} {
        top: 40px;
        left: 20px;
     }
     @media ${DEVICE.tabletDevices} {
        top: 40px;
        left: 20px;
     }
     @media ${DEVICE.tabletDevicesS} {
        top: 30px;
        left: 20px;
     }

     @media ${DEVICE.laptopS} {
        display: none;
     }
    background: url(${CollapseIcon}) center center no-repeat;
    
`
export default StyledCollapseIcon;
