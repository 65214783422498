import { all } from "redux-saga/effects";

import registrationSaga from "./registration/registration.saga";
import loginSaga from "./login/login.saga";
import logoutSaga from "./logout/logout.saga";
import appInfoSaga from "./appInfo/appInfo.saga";
import familySaga from "./family/family.saga";
import errorsSaga from "./errors/errors.saga";
import fileUploadSaga from "./file/file.saga";
import areaSaga from "./area/area.saga";
import mainMemberSaga from "./mainMember/mainMember.saga";
import spouseSaga from "./spouse/spouse.saga";
import childrenSaga from "./children/children.saga";
import parentsSaga from "./parents/parents.saga";
import addressSaga from "./address/address.saga";
import eventsSaga from "./events/events.saga";
import bookingSaga from "./bookings/bookings.saga";
import eventAvailabilitySaga from "./eventAvailability/eventAvailability.saga";
import resendEmailSaga from "./resendEmail/resendEmail.saga";
import passwordSaga from "./password/password.saga";
import generalLoaderSaga from "./generalLoader/generalLoader.saga";
import marriageNocSaga from './marriageNoc/marriageNoc.saga';

export default function* rootSaga() {
  yield all([
    registrationSaga(),
    generalLoaderSaga(),
    passwordSaga(),
    resendEmailSaga(),
    loginSaga(),
    logoutSaga(),
    appInfoSaga(),
    familySaga(),
    errorsSaga(),
    fileUploadSaga(),
    areaSaga(),
    mainMemberSaga(),
    spouseSaga(),
    childrenSaga(),
    parentsSaga(),
    addressSaga(),
    eventsSaga(),
    bookingSaga(),
    eventAvailabilitySaga(),
    marriageNocSaga()
  ]);
}
