import styled from 'styled-components';
import { Button } from 'antd';

const StyledModalButtonClose = styled(Button)`
   && {
          display: inline-block;
		  border: 1px solid var(--uploadBtn);
		  font-family: var(--fontProximaRegular);
		  font-size: 13px;
		  line-height: 1.23;
		  color: var(--dark);
		  background: var(--white);
		  outline: none;
		  width: 87px;
          height: 40px;
          margin-right: 18px;
          border-radius: 8px;
          box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.1);
          border: solid 1px var(--gray);
	
		  &:hover {
		     color: var(--dark);
		     box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.3);
		     background: var(--white);
		     border: solid 1px var(--gray);
		  }
		  
		  &:disabled {
		    background-color: var(--gray);
		    border: 1px solid var(--gray);
		    color: var(--white);
		    
		   }	 
    }
    
`
export default StyledModalButtonClose;
