import styled from "styled-components";

const PolicyPage = styled.div`
  padding: 5% 10% 20%;
`;

const Logo = styled.div`
  padding: 35px 35px 20px 40px;
`;

export default {
  PolicyPage,
  Logo
};
