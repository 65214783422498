import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {FormattedMessage} from 'react-intl';

import {getChurchLiturgySelector} from "../../../services/events/events.selector";
import StyledPageTitle from '../../styled/StyledPageTitle';
import StyledLiturgyList from './styled/StyledLiturgyList';
import LiturgyCard from "../LiturgyCard/LiturgyCard";
import {getLocalizedKey} from "../../../utils/utils";
import sortBy from "lodash/sortBy"
import {categorizeEvents} from "../../../services/events/events.utils";
import {StyledFilters, StyledFilterTab} from "./styled/StyledLiturgyTabs";


const options = [
    {label: 'LiturgyPage.Filter.Liturgies', value: 0},
    {label: 'LiturgyPage.Filter.Meetings', value: 1},
    {label: 'LiturgyPage.Filter.SundaySchools', value: 2},
];

const LiturgyList = ({liturgy, selectEvent}) => {
    const sortedLiturgy = sortBy(liturgy, ['date', 'id'])
    const {liturgies, meetings, sundaySchools} = categorizeEvents(sortedLiturgy)
    const [selectedTab, setSelectedTab] = useState(0)
    // const displayLiturgies = selectedTab === 0 ? liturgies : meetings
    let displayLiturgies = []
    switch (selectedTab) {
        case 0:
            displayLiturgies = liturgies
            break
        case 1:
            displayLiturgies = meetings
            break
        case 2:
            displayLiturgies = sundaySchools
            break
    }

    const church = sortedLiturgy?.[0]?.church;

    const onTabChanged = e => {
        setSelectedTab(e.target.value)
    };

    return (
        <div>
            <StyledPageTitle>
                {church?.[getLocalizedKey("name")]}
            </StyledPageTitle>
            {sortedLiturgy && sortedLiturgy.length ?
                <StyledFilters
                    defaultValue={options[selectedTab].value}
                    onChange={onTabChanged}
                    optionType={"button"}
                    buttonStyle={"solid"}>
                    {
                        options.map(option =>
                            <StyledFilterTab value={option.value}>
                                <FormattedMessage id={option.label}/>
                                &nbsp;({option.value === 0 ? liturgies.length : option.value === 1 ? meetings.length : sundaySchools.length})
                            </StyledFilterTab>
                        )
                    }
                </StyledFilters>
                : ""
            }
            <StyledLiturgyList>
                {
                    displayLiturgies.length ? displayLiturgies.map(item => (
                            item && <LiturgyCard
                                key={item.id}
                                eventId={item.id}
                                item={item}
                                selectEvent={selectEvent}
                            />
                        )) :
                        <h2>
                            <FormattedMessage id={"LiturgyPage.NoLiturgy"} values={{type: options[selectedTab].label}}/>
                        </h2>
                }
            </StyledLiturgyList>
        </div>
    )
};

export default compose(
    withRouter,
    connect(
        createStructuredSelector({
            liturgy: (state, {match: {params: {id}}}) =>
                getChurchLiturgySelector(state, id),
        }),
    )
)(LiturgyList)
