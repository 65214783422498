import styled from "styled-components";

const Content = styled.div`
  padding: 56px 16px;
`;

const Message = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;

const Warning = styled.p`
  text-align: center;
  font-size: 16px;
  color: #fc6161;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default {
  Content,
  Message,
  BottomRow,
  Warning
};
