export const CHILD_MODEL = {
  churchService: null,
  dateOfBirth: null,
  deacon: true,
  email: null,
  emiratesID: null,
  emiratesIDCopy: null,
  fatherOfConfession: null,
  firstName: null,
  gender: "male",
  lastName: null,
  mobile: null,
  nationalIDCopy: null,
  nationalIDNumber: null,
  nationality: "egyptian",
  passportNumber: null,
  profilePic: null,
  secondName: null,
  status: "single",
  visaType: "residence",
  whatsapp: null,
  workingField: null
};

export const PARENT_MODEL = {
  churchService: null,
  dateOfBirth: null,
  deacon: true,
  email: null,
  emiratesID: null,
  emiratesIDCopy: null,
  fatherOfConfession: null,
  firstName: null,
  gender: "male",
  lastName: null,
  mobile: null,
  nationalIDCopy: null,
  nationalIDNumber: null,
  nationality: "egyptian",
  passportNumber: null,
  profilePic: null,
  secondName: null,
  status: "single",
  visaType: "residence",
  whatsapp: null,
  workingField: null
};
