import { isUrlMatch } from "../router/router.utils";
import { pathBuilder, urlLocations } from "../../routes/urlLocations";
import { call, put, takeEvery, select } from "redux-saga/effects";
import * as marriageNocActions from "./marriageNoc.actions";
import { LOCATION_CHANGE } from "connected-react-router";
import {
  createMarriageNocDraftRequest,
  getMarriageNocsRequest,
  submitMarriageNocDraftRequest,
  updateMarriageNocDraftRequest,
  updateMarriageNocRequest,
} from "./marriageNoc.api";
import { history } from "../../routes/urlLocations";
import { SET_MODAL_STATUS } from "../modals/modals.action";
import openNotification from "../../utils/notifications";
import {SET_LOADER_STATUS} from "../loader/loader.action";
import {FORMS_NAME, LOADERS} from "../../constants/constants";
import {loadingPayload} from "../../utils/utils";
import {prepareFilesDataForSave} from "../file/file.utils";

function* getMarriageNOCOnLocationChange({ payload }) {
  try {
    if (
      isUrlMatch(payload, urlLocations.marriageNoc) ||
      isUrlMatch(payload, urlLocations.marriageNOCDetails)
    ) {
      yield put({
        type: marriageNocActions.GET_MARRIAGE_NOC,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchMarriageNOC() {
  try {

    yield put(loadingPayload(LOADERS.marriageNOC, true));

    const { response } = yield call(getMarriageNocsRequest);
    yield put({
      type: marriageNocActions.GET_MARRIAGE_NOC_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: marriageNocActions.GET_MARRIAGE_NOC_FAILURE });
    console.log(error);
  } finally {
    yield put(loadingPayload(LOADERS.marriageNOC, false));
  }
}

function* createMarriageNOCDraft({ payload }) {
  try {

    yield put(loadingPayload(LOADERS.marriageNOC, true));

    const { response } = yield call(createMarriageNocDraftRequest, payload);

    if(response.status < 400) {
      yield put({
        type: marriageNocActions.CREATE_MARRIAGE_NOC_DRAFT_SUCCESS,
        payload: response.data,
      });

      yield put({
        type: marriageNocActions.GET_MARRIAGE_NOC,
      });

      yield history.push(pathBuilder.marriageNOCDetails(response?.data.uuid));

      yield put({
        type: SET_MODAL_STATUS,
        payload: null,
      });
    } else {
      // marriage-noc.draft.member-already-has-draft
      const errorCode = response.data?.message[0].messages[0].id
      if (errorCode) {
        yield call(openNotification, {
          message: "MarriageNOC.Notification.Error",
          description: errorCode,
          type: "error",
        });
      } else {
        yield call(openNotification, {
          message: "MarriageNOC.Notification.Error",
          description: "MarriageNOC.Notification.ErrorMessage",
          type: "error",
        });
      }
      yield put({
        type: marriageNocActions.CREATE_MARRIAGE_NOC_DRAFT_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: marriageNocActions.CREATE_MARRIAGE_NOC_DRAFT_FAILURE,
    });
    console.log(error);
  } finally {
    yield put(loadingPayload(LOADERS.marriageNOC, false));
  }
}

function* updateMarriageNOCDraft({ payload }) {
  try {

    yield put(loadingPayload(LOADERS.marriageNOC, true));

    const files = yield select(state => state.files);
    const prepareFiles = prepareFilesDataForSave(files[FORMS_NAME.MARRIAGE_NOC]);

    const { response } = yield call(updateMarriageNocDraftRequest, {...payload, ...prepareFiles});

    yield put({
      type: marriageNocActions.UPDATE_MARRIAGE_NOC_DRAFT_SUCCESS,
      payload: response.data,
    });

    // yield history.push(urlLocations.marriageNoc);

    yield call(openNotification, {
      type: "success",
      message: "Success",
      description: "MarriageNOCDetails.SuccessfullyUpdate",
    });
  } catch (error) {
    yield put({
      type: marriageNocActions.UPDATE_MARRIAGE_NOC_DRAFT_FAILURE,
    });
    console.log(error);
  } finally {
    yield put(loadingPayload(LOADERS.marriageNOC, false));
  }
}

function* submitMarriageNOCDraft({ payload }) {
  try {

    yield put(loadingPayload(LOADERS.marriageNOC, true));

    const files = yield select(state => state.files);
    const prepareFiles = prepareFilesDataForSave(files[FORMS_NAME.MARRIAGE_NOC]);

    const { response } = yield call(submitMarriageNocDraftRequest, {...payload, ...prepareFiles});

    yield put({
      type: marriageNocActions.SUBMIT_MARRIAGE_NOC_DRAFT_SUCCESS,
      payload: response.data,
    });

    yield history.push(urlLocations.marriageNoc);

    yield call(openNotification, {
      type: "success",
      message: "Success",
      description: "MarriageNOCDetails.SuccessfullySubmitted",
    });
  } catch (error) {
    yield put({
      type: marriageNocActions.SUBMIT_MARRIAGE_NOC_DRAFT_FAILURE,
    });
    console.log(error);
  } finally {
    yield put(loadingPayload(LOADERS.marriageNOC, false));
  }
}

export default function* marriageNocSaga() {
  yield takeEvery(LOCATION_CHANGE, getMarriageNOCOnLocationChange);
  yield takeEvery(marriageNocActions.GET_MARRIAGE_NOC, fetchMarriageNOC);
  yield takeEvery(marriageNocActions.UPDATE_MARRIAGE_NOC_DRAFT, updateMarriageNOCDraft);
  yield takeEvery(marriageNocActions.SUBMIT_MARRIAGE_NOC_DRAFT, submitMarriageNOCDraft);
  yield takeEvery(marriageNocActions.CREATE_MARRIAGE_NOC_DRAFT, createMarriageNOCDraft);
}
