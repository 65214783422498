export const prepareMemberIds = (ids = {}) => {
  const checked = ids && Object.entries(ids).filter(id => id && !!id[1]);
  return (
    checked &&
    Object.values(checked).map(
      id => id && parseInt(id[0].replace(/['"]+/g, ""))
    )
  );
};

export const categorizeEvents = (events) => {
  const categorized = {
    liturgies: [],
    meetings: [],
    sundaySchools: []
  }
  for (const event of events) {
    if(event.eventType === 'sundaySchools') {
      categorized.sundaySchools.push(event)
    } else if(event.eventType === 'youthMeeting') {
      categorized.meetings.push(event)
    } else {
      categorized.liturgies.push(event)
    }
  }
  return categorized
}
