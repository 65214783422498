import React from 'react';
import { Link } from 'react-router-dom';

import LoginForm from "./LoginForm/LoginForm";
import {FormattedMessage} from "react-intl";
import {urlLocations} from "../../routes/urlLocations";
import StyledLoginPage from "./styled/StyledLoginPage";
import StyledFormBottom from "../styled/StyledFormBottom";
import StyledFormBottomText from "../styled/StyledFormBottomText";
import StyledFormTitle from "../styled/StyledFormTitle";
import ResendEmail from "./ResendEmail/ResendEmail";
import ForgotPassword from "./ForgotPassword/ForgotPassword.Modal/ForgotPassword.Modal";

const Login = () => <StyledLoginPage>
  <StyledFormTitle>
      <FormattedMessage id="Login.Title" />
  </StyledFormTitle>

  <LoginForm/>
  <ResendEmail/>
  <ForgotPassword/>

  <StyledFormBottom>
      <StyledFormBottomText>
        <FormattedMessage id="Login.CTA" />
      </StyledFormBottomText>

      <Link to={urlLocations.registration} >
        <FormattedMessage id="Login.CTA.Register" />
      </Link>

  </StyledFormBottom>

</StyledLoginPage>;

export default Login;