import styled from 'styled-components';
import { DEVICE } from '../../constants/media';

const StyledPageMainInfo = styled.div`
      width: 100%;
      max-width: 690px;
      margin: 0 auto;
      padding-bottom: 120px;
      
      @media ${DEVICE.laptopS} {
			margin: 0 auto 0 150px;  
	  }
`

export default StyledPageMainInfo;


