import styled from 'styled-components';

const StyledDashboardItemTitle = styled.h3`
	width: 100%;
	font-family: var(--fontProximaBold);
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: var(--dark);
	margin-top: 60px;
	text-align: center;
	padding-bottom: 8px;
`
export default StyledDashboardItemTitle;
