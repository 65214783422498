import styled from 'styled-components'
import { DEVICE } from '../../../../../constants/media';

const StyledPersonalInfoForm = styled.div`
    .ant-select-selector {
		width: 208px;
		
		@media ${DEVICE.tablet} {
		    max-width: 208px;
	    } 
	}
	.ant-picker-clear {
		right: -11px;
	}
`

export default StyledPersonalInfoForm;


