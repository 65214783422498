import React from 'react';
import { reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { Form } from 'antd';

import InputField from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import { resetPasswordAction } from "../../../services/password/password.action";
import { passwordValidate as validate } from '../../../utils/validate'

const ResetPasswordForm = ({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
            <Form.Item>
                <InputField
                    type="password"
                    name="password"
                    placeholder="ResetPassword.Password"
                    label="ResetPassword.Password"
                />
            </Form.Item>
            <Form.Item>
                <InputField
                    type="password"
                    name="passwordConfirmation"
                    placeholder="ResetPassword.ConfirmPassword"
                    label="ResetPassword.ConfirmPassword"
                />
            </Form.Item>
            <Button htmlType="submit" >
                <FormattedMessage
                    id="ResetPassword.Button"
                />
            </Button>
        </form>
);

export default reduxForm({
    form: "resetPasswordForm",
    validate,
    onSubmit: (value, dispatch, { code }) =>
        dispatch(resetPasswordAction({...value, code}))
})(ResetPasswordForm)