import styled from 'styled-components';
import UserIcon from '../../../../assets/icons/emptyavatar.png';

const StyledPhotoSectionImgContent = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 10px;
  border: 1px solid  #d6e1e9;
  position: relative;
  background: url(${UserIcon}) center center no-repeat;
  overflow: hidden;
 
  img {
	  width: 110%;
	  height: 110%;
	  margin: -5px;
	  object-fit: cover;
	  border-radius: 10px;
  }
`

export default StyledPhotoSectionImgContent;


