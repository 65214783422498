import React, {useEffect} from 'react';
import { Select } from 'antd';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';

import {appInfoChurchesSelector, appInfoEmiratesSelector} from "../../../../services/appInfo/appInfo.selector";
import SelectField from "../../../../components/SelectField/SelectField";
import InputField from "../../../../components/InputField/InputField";
import { getLocalizedKey } from "../../../../utils/utils";
import Button from '../../../../components/Button/Button';
import { setGeolocationAction } from "../../../../services/geolocation/geolocation.action";
import { geolocationSelector } from "../../../../services/geolocation/geolocation.selector";
import { setFamilyAddressAction } from "../../../../services/address/address.action";
import StyledPageSubTitle from '../../styled/StyledPageSubTitle';
import StyledPageWrapSubTitle from '../../styled/StyledPageWrapSubTitle';
import StyledFormRow from '../../styled/StyledFormRow';
import StyledFormCol67 from '../../styled/StyledFormCol67';
import StyledFormCol33 from '../../styled/StyledFormCol33';
import StyledFormLastCol from '../../styled/StyledFormLastCol';
import DoneIcon from '../../../../assets/icons/Checkbox-done.svg';

const { Option } = Select;

const AddressInfoForm = ({
    handleSubmit,
    emirates,
    sortedAreas,
    getAreas,
    setGeolocation,
    churches,
    initialValues,
    geolocation,
    dispatch,
    change
}) => {
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <StyledPageWrapSubTitle>
                    <StyledPageSubTitle>
                        <FormattedMessage
                            id="FamilyPage.Address.CurrentLocation"
                        />
                    </StyledPageSubTitle>
                </StyledPageWrapSubTitle>

                <StyledFormRow>
                    <StyledFormCol33>
                        <SelectField
                            name="emirate"
                            placeholder=""
                            label="FamilyPage.Address.Emirates"
                            onSelect={(value) => {
                                getAreas(value);
                                dispatch(change("areaInfo", " "))
                            }}
                        >{
		                    emirates && emirates.map(emirate => (
                                <Option key={emirate.id} value={emirate.id} >
				                    { emirate[getLocalizedKey("name")] }
                                </Option>)
		                    )
	                    }</SelectField>
                    </StyledFormCol33>
                    <StyledFormCol33 typeEmpty={true}></StyledFormCol33>
                    <StyledFormCol33 typeEmpty={true}></StyledFormCol33>
                </StyledFormRow>

                <StyledFormRow>
                    <StyledFormCol67>
                        <InputField
                            name="addressLine"
                            placeholder=""
                            label="FamilyPage.Address.Address"
                        />
                    </StyledFormCol67>

                    <StyledFormCol33>
                        <SelectField
                            name="areaInfo"
                            placeholder=""
                            label="FamilyPage.Address.Area"
                        >
		                    {
                                sortedAreas && sortedAreas?.map(area => (
                                    <Option key={area.id} value={area.id} >
                                    { area[getLocalizedKey("name")] }
                                    </Option>
                                ))
		                    }
                        </SelectField>
                    </StyledFormCol33>
                </StyledFormRow>

                <StyledFormRow>
                    <Button
                        uiType="secondary"
                        disabled={!isEmpty(geolocation)}
                        onClick={() => {
			                navigator.geolocation.getCurrentPosition(
				                ({ coords: { latitude,  longitude} }) => {
					                setGeolocation({
						                latitude,
						                longitude
					                });
				                },
				                (error) => console.log(error)
			                )
		                }
		                }
                    >
                        <i>
                            { !isEmpty(geolocation) ?
	                            <img src={DoneIcon} />
                                :
                                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m12.4988 5.51885c3.1913.24217 5.7388 2.78889 5.9822 5.97995h3.519v1h-3.5189c-.2422 3.1922-2.7901 5.7401-5.9823 5.9823v3.5189h-1v-3.519c-3.19106-.2434-5.73778-2.7909-5.97995-5.9822h-3.51885v-1h3.51904c.24328-3.19025 2.78951-5.73648 5.97976-5.97976v-3.51904h1zm-5.9988 6.48115c0-3.03757 2.46243-5.5 5.5-5.5 3.0376 0 5.5 2.46243 5.5 5.5 0 3.0376-2.4624 5.5-5.5 5.5-3.03757 0-5.5-2.4624-5.5-5.5zm7.5 0c0 1.1046-.8954 2-2 2s-2-.8954-2-2 .8954-2 2-2 2 .8954 2 2zm-2 3c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3z" fill="#4265da" fillRule="evenodd"/></svg>
                            }

                        </i>
                        <FormattedMessage
                            id={
				                !isEmpty(geolocation) ?
					                "FamilyPage.Address.CurrentLocationAdded" :
					                "FamilyPage.Address.AddCurrentLocation"
			                }
                        />
                    </Button>
                </StyledFormRow>

                <StyledPageWrapSubTitle>
                    <StyledPageSubTitle>
                        <FormattedMessage
                            id="FamilyPage.Address.Additional"
                        />
                    </StyledPageSubTitle>
                </StyledPageWrapSubTitle>

                <StyledFormRow>
                    <StyledFormCol33>
                        <SelectField
                            name="church"
                            placeholder=""
                            label="FamilyPage.Address.Church"
                            onSelect={getAreas}
                            // disabled={initialValues.church}
                        >{
		                    churches && churches.map(church => (
                                <Option key={church.id} value={church.id} >
				                    { church[getLocalizedKey("name")] }
                                </Option>))
	                    }</SelectField>
                    </StyledFormCol33>
                    <StyledFormCol33></StyledFormCol33>
                    <StyledFormCol33></StyledFormCol33>
                </StyledFormRow>

                <StyledFormLastCol>
                    <Button size="small" htmlType="submit" >
                        <FormattedMessage
                            id="FamilyPage.Address.Save"
                        />
                    </Button>
                </StyledFormLastCol>

            </form>
        </div>
    )
};

export default compose(
    connect(
        createStructuredSelector({
            emirates: appInfoEmiratesSelector,
            churches: appInfoChurchesSelector,
            geolocation: geolocationSelector,
        }),
        {
            setGeolocation : setGeolocationAction
        }
    ),
    reduxForm({
        form: "addressForm",
        onSubmit: (value, dispatch, props) => {
            dispatch(
                setFamilyAddressAction(value)
            )

        }
    }),
)
(AddressInfoForm)
