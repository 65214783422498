import styled from 'styled-components';
import Arrow from '../../../assets/img/arrowDown.svg';
import {DEVICE} from '../../../constants/media';

const StyledDashboardItemContent = styled.div`
  background: var(--white);
  position: relative;
  width: 100%;
  height: 260px;
  margin-top: 115px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 20px;
    top: -18px;
    left: 0;
    z-index: 1;
    background: url(${Arrow}) center center no-repeat;

    @media ${DEVICE.tabletDevices} {
      top: -16px;
    }
  }
}
`
export default StyledDashboardItemContent;
