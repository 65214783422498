import React from 'react'
import StyledLogo from './styled/StyledLogo';
import { injectIntl } from "react-intl";
import LogoPageImg from '../../assets/img/logo/logopage.svg';
import {Link} from "react-router-dom";
import {urlLocations} from "../../routes/urlLocations";

const LogoPage = ({ intl }) => (
	<StyledLogo>
		<Link to={urlLocations.home} >
			<img src={LogoPageImg} alt={intl.formatMessage({id: 'Logo.Page.Alt'})} />
		</Link>
	</StyledLogo>
);

export default injectIntl(LogoPage);


