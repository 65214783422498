import styled from 'styled-components';
import { Checkbox } from 'antd';
import { DEVICE } from '../../../constants/media';
import Arrow from '../../../assets/icons/checkboxIcon.svg';
import ArrowDisabled from '../../../assets/icons/checkboxDisabled.svg';

export default styled(Checkbox)`
    &&& {
			font-family: var(--fontProximaBold);
			font-size: 15px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.6;
			letter-spacing: normal;
			color: var(--dark);
			padding-left: 2px;
			display: flex;
			border-radius: 2px;
			
			
			&.ant-checkbox-wrapper-checked {
				color: var(--dark);
			}
			
			@media ${DEVICE.mobileDevices} {
                font-size: 12px;
            }
            
            input[type='checkbox'] {
                width: 24px;
		        height: 24px;
            }
            
            &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled {
                color: var(--lightGrey);
            }
            
            &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
                color: var(--dark);
            }
		
		&:hover {
		   border-color: var(--blueSecondary);
		   .ant-checkbox-inner {
		        border-color: var(--blueSecondary);
		        box-shadow: 0 1px 3px 0 rgba(76, 111, 230, 0.3);
		   }
		}
		 	
        .ant-checkbox {
		    width: 24px;
		    height: 24px;
		    margin-right: 5px;

		     &.ant-checkbox-checked .ant-checkbox-inner::after {
	            opacity: 1;
		        width: 13px;
                height: 10px;
                left: 5px;
                top: 6px;
                transform: none;
                border: none;
	            background: url(${Arrow}) center center no-repeat;
             }
             
             &.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner::after { 
                background: url(${ArrowDisabled}) center center no-repeat;
             }
             
             &.ant-checkbox-checked .ant-checkbox-inner {
	             border: 1px solid var(--blueSecondary);
	             color: var(--dark);
	             box-shadow: 0 1px 3px 0 rgba(76, 111, 230, 0.3);
             }

             & + span {
                width: 100%;
                display: inline-block;
             }
        }
        
      .ant-checkbox-inner {
	        width: 24px;
	        height: 24px;
	        background: transparent;
	        border: 1px solid var(--lightGrey);
	        position: relative;
	        border-radius: 0;
	  }  
	  .ant-checkbox-checked.ant-checkbox-inner {
	        border: 1px solid var(--blueSecondary);
	  }
	  .ant-checkbox-checked.ant-checkbox-disabled {
	        
	  }
    }
`
