import styled from "styled-components";

const StyledModalHeader = styled.div`
  width: 100%;
  min-height: 64px;
  background-image: ${({ isPrivate }) =>
    isPrivate ? "" : "linear-gradient(to top, #4164da, #587cf4)"};
  border-radius: 10px 10px 0 0;
  padding: 16px 24px 16px 40px;
`;
export default StyledModalHeader;
