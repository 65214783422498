import { GET_EVENTS_SUCCESS, GET_PRIVATE_EVENT_SUCCESS } from "./events.action";
import initialState from "../../store/initialState";

export default (state = initialState.events, action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case GET_PRIVATE_EVENT_SUCCESS:
      return {
        ...state,
        private: action.payload
      };
    default:
      return state;
  }
};
