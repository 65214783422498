import React from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from "react-intl";

import MainSection from "../../FieldsSections/MainSection";
import ContactSection from "../../FieldsSections/ContactSection";
import AdditionalSection from "../../FieldsSections/AdditionalSection";
import Button from "../../../../components/Button/Button";
import { setFamilyMainMember } from "../../../../services/mainMember/mainMember.action";
import NationalitySection from "../../FieldsSections/NationalitySection";
import VisaSection from "../../FieldsSections/VisaSection";
import StyledPageWrapSubTitle from '../../styled/StyledPageWrapSubTitle';
import StyledPageSubTitle from '../../styled/StyledPageSubTitle';
import StyledFormLastCol from '../../styled/StyledFormLastCol';
import StyledPersonalInfoForm from './styled/StyledPersonalInfoForm';

const PersonalInfoForm = ({
    handleSubmit,
    visaType,
    nationality,
    country,
    formName
}) => {
    return (
        <StyledPersonalInfoForm>
            <form onSubmit={handleSubmit}>
                <MainSection/>
                <ContactSection/>

                {!!country?.isMain && <>
                    <StyledPageWrapSubTitle>
                        <StyledPageSubTitle>
                            <FormattedMessage
                                id="FamilyPage.Section.Identification"
                            />
                        </StyledPageSubTitle>
                    </StyledPageWrapSubTitle>


                    <NationalitySection
                        formName={formName}
                        nationality={nationality}
                    />
                    <VisaSection
                        formName={formName}
                        visaType={visaType}
                    />
                    <AdditionalSection
                        formName={formName}
                        visaType={visaType}
                        nationality={nationality}
                    />
                </>
                }
                <StyledFormLastCol>
                    <Button size="small" htmlType="submit">
                        <FormattedMessage
                            id="FamilyPage.Form.SaveButton"
                        />
                    </Button>
                </StyledFormLastCol>

            </form>
        </StyledPersonalInfoForm>
    )
};


export default reduxForm({
    form: 'personalInfoForm',
    enableReinitialize: true,
    onSubmit: (value, dispatch ) =>
        dispatch(setFamilyMainMember(value))
})(PersonalInfoForm)
