import React from 'react';
import { Field } from "redux-form";
import { injectIntl } from "react-intl";
import {compose} from "redux";
import {connect} from "react-redux";

import Checkbox from '../../components/Checkbox/Checkbox';
import ControlWrapper from "../ControlWrapper/ControlWrapper";

const CustomCheckbox = (
    {
        input,
        label,
        formatMessage,
        name,
        children,
        disabled,
        defaultChecked,
        onChange,
        ...meta
    }
) => {
    return (
        <ControlWrapper
            name={ input.name}
            label={label}
        >
            <Checkbox
	            { ...input }
                { ...meta }
                checked={
                    input?.value === '' ? defaultChecked : input?.value
                }
                disabled={disabled}
                defaultChecked={defaultChecked}
            >
                {children}
            </Checkbox>
        </ControlWrapper>)
};

const CheckboxField = ({
    name,
    label,
    children,
	intl: { formatMessage },
    disabled,
    defaultChecked,
    onChange
}) => {
    return (
        <Field
            component={CustomCheckbox}
            name={name}
            children={children}
            formatMessage={formatMessage}
            label={label}
            disabled={disabled}
            defaultChecked={defaultChecked}
            onChange={onChange}
        />
    )};

export default compose(
    connect(
        ({ errors }) => ({ errors }),
        {}),
    injectIntl
)(CheckboxField)