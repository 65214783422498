import React from 'react';
import { connect } from 'react-redux'
import { createStructuredSelector } from "reselect";
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";
import QRCode from 'qrcode.react'
import isArray from "lodash/isArray";

import {getBookingChurch} from "../../../services/bookings/bookings.selector";
import { getLocalizedKey} from "../../../utils/utils";
import { familyMembersSelector } from "../../../services/family/family.selector";
import Button from "../../../components/BorderedButton/Button";
import { cancelBookingAction } from "../../../services/bookings/bookings.action";
import StyledBookingPageCard from './styled/StyledBookingPageCard';
import StyledBookingCardContent from './styled/StyledBookingCardContent';
import StyledBookingCardImg from './styled/StyledBookingCardImg';
import StyledBookingCardTitle from './styled/StyledBookingCardTitle';
import StyledInfoBox from '../../styled/StyledInfoBox';
import StyledInfoBoxTxt from '../../styled/StyledInfoBoxTxt';
import StyledInfoBoxIcon from '../../styled/StyledInfoBoxIcon';
import StyledBookingCardCode from './styled/StyledBookingCardCode';
import CalendarIcon from '../../../assets/icons/liturgyicon1.svg';
import TimeIcon from '../../../assets/icons/liturgyicon2.svg';
import ChurchIcon from '../../../assets/icons/churchicon.svg';
import StyledBookingCardFooter from './styled/StyledBookingCardFooter';
import addIcon from '../../../assets/icons/add.svg';
import StyledBookingCardCol from './styled/StyledBookingCardCol';
import StyledBookingCardWrapBtn from './styled/StyledBookingCardWrapBtn';
import AttendIcon from '../../../assets/icons/bookedusers.svg';
import {isBookingCanModify, isBookingPast, isMemberAttend} from "../../../services/bookings/bookings.utils";

import StyledBookingTextQr from './styled/StyledBookingTextQr';
import {setModalStatusAction} from "../../../services/modals/modals.action";
import {MODALS_ID} from "../../../constants/constants";
import BookingPageModifyModal from "../BookingPageModify/BookingPageModify.Modal/BookingPageModify.Modal";
import {checkEventAvailabilityAction} from "../../../services/eventAvailability/eventAvailability.action";
import StyledLiturgyTag from "../../LiturgyPage/LiturgyList/styled/StyledLiturgyTag";
import StyledBookingTagsBox from "../../LiturgyPage/LiturgyList/styled/StyledBookingTagsBox";
import BookingPageCardCancelModal from "../BookingPageCard.CancelModal/BookingPageCard.CancelModal";

const BookingsPageCard = (
    { booking,
        church: {
            banner,
        } = {
            banner: {},
        },
        booking: {
            event
        },
        church,
        members,
        cancelBooking,
        setModalStatus,
        checkEventAvailability
    }) => {
    const bookedMembers = isArray(booking?.bookedMembers) ? booking?.bookedMembers : [booking?.bookedMembers];

    return (
        <>
    <StyledBookingPageCard hasTags={(event.eventTags && event.eventTags.length > 0) || booking.canceled}>
        <StyledBookingCardImg>
            <img width="100" src={banner ? banner.url : ""} alt=""/>
        </StyledBookingCardImg>

        <StyledBookingCardContent>

            <StyledBookingCardTitle>
                { booking.event[getLocalizedKey('title')] }
            </StyledBookingCardTitle>

            <StyledBookingTagsBox>
                {booking.canceled &&
                    <StyledLiturgyTag bg={"var(--red)"} textColor={"#FFFFFF"}>
                        <FormattedMessage id="BookingsPage.Canceled"/>
                    </StyledLiturgyTag>
                }
                {event.eventTags.sort((t1,t2) => t1.rank - t2.rank).map(tag => (
                    <StyledLiturgyTag bg={tag.color} textColor={tag.textColor}>
                        {tag[getLocalizedKey("title")]}
                    </StyledLiturgyTag>
                ))}
            </StyledBookingTagsBox>

            <StyledBookingCardCol>
                <div>
                    <StyledInfoBox fullwidth>
                        <StyledInfoBoxIcon bg={ChurchIcon}/>
                        <FormattedMessage
                            id="BookingsPage.Church"
                        /> :
                        <StyledInfoBoxTxt>
				            { church?.[getLocalizedKey('name')] }
                        </StyledInfoBoxTxt>
                    </StyledInfoBox>

                    <StyledInfoBox>
                        <StyledInfoBoxIcon bg={CalendarIcon}/>
                        <FormattedMessage
                            id="BookingsPage.Date"
                        />:
                        <StyledInfoBoxTxt>
                            <FormattedDate
                                value={event?.date}
                                month="long"
                                day="numeric"
                                year="numeric"
                            />
                        </StyledInfoBoxTxt>
                    </StyledInfoBox>

                    <StyledInfoBox>
                        <StyledInfoBoxIcon bg={TimeIcon}/>
                        <FormattedMessage
                            id="BookingsPage.Time"
                        />:
                        <StyledInfoBoxTxt>
                            <FormattedTime
                                value={event?.date}
                            />
                        </StyledInfoBoxTxt>
                    </StyledInfoBox>
                </div>
                <StyledBookingCardCode>
                    <StyledBookingTextQr>{booking?.qrCode}</StyledBookingTextQr>
                    <QRCode height='100%' width='100%' value={booking?.qrCode} />
                </StyledBookingCardCode>
            </StyledBookingCardCol>

            <StyledBookingCardFooter>
                <StyledInfoBox>
                    <StyledInfoBoxIcon bg={AttendIcon}/>

                    <FormattedMessage
                        id="BookingsPage.Attend"
                    />:
                    <StyledInfoBoxTxt>
	                    { bookedMembers?.map(memberId => (
                            <span
                                style={
                                    { color : !isBookingPast(booking) ? 'initial' : isMemberAttend(booking, memberId) ? 'green' : "var(--red)" }
                                }
                                key={memberId}
                            >{
			                    members?.find(member => (
				                    member?.id?.toString() === memberId?.toString()
			                    ))?.firstName
		                    }, </span>)) }
                    </StyledInfoBoxTxt>
                </StyledInfoBox>

                { !isBookingPast(booking) && !booking.canceled  && <StyledBookingCardWrapBtn>
                    <Button
                        onClick={
                            () => {
                                setModalStatus(`${MODALS_ID.BOOKING_MODIFY_MODAL}_${booking.id}`);
                                checkEventAvailability({ id: event.id, query: '?isModify=true' })
                            }
                        }

                    >
                        <i><img src={addIcon} alt={""} /></i>
                        <FormattedMessage
                            id="BookingsPage.Modify"
                        />
                    </Button>
                    <Button
                        onClick={() => {
                            setModalStatus(`${MODALS_ID.BOOKING_CANCEL_MODAL}_${booking.id}`);
                        }}
                        uitype="secondary-bordered"
                    >
                        <FormattedMessage
                            id="BookingsPage.Cancel"
                        />
                    </Button>
                </StyledBookingCardWrapBtn> }
            </StyledBookingCardFooter>
        </StyledBookingCardContent>
    </StyledBookingPageCard>
    <BookingPageModifyModal
        bookingId={booking.id}
        members={members}
        bookedMembers={bookedMembers}
    />
    <BookingPageCardCancelModal
        bookingId={booking.id}
        booking={booking}
        onCancel={cancelBooking.bind(null, booking.id)}
    />
    </>
)};

export default connect(
    createStructuredSelector({
        church: (state, { booking: { event: { church } } }) => getBookingChurch(state,church),
        members: familyMembersSelector,
    }),
    {
        cancelBooking: cancelBookingAction,
        setModalStatus: setModalStatusAction,
        checkEventAvailability: checkEventAvailabilityAction
    }
)(BookingsPageCard);
