export const firebaseConfig = {
  apiKey: "AIzaSyCO-YjS0S2oU5kLousmy4306yNhrDGeEW8",
  authDomain: "uaecopts.firebaseapp.com",
  databaseURL: "https://uaecopts.firebaseio.com",
  projectId: "uaecopts",
  storageBucket: "uaecopts.appspot.com",
  messagingSenderId: "320302966187",
  appId: "1:320302966187:web:3bfca5387723e52b9d9eee",
  measurementId: "G-ZYKJ3RG5KD"
};
