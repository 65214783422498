import styled from 'styled-components'


const StyledFormLastCol = styled.div`
       width: 100%;
       display: flex;
       margin-top: 30px;
       justify-content: flex-end;      
`
export default StyledFormLastCol;


