import styled from 'styled-components'
import { Layout } from 'antd';
import { DEVICE } from '../../constants/media';
const { Content } = Layout;

const StyledPageContent = styled(Content)`
      width: 100%;
	  
	  position: relative;
	  z-index: 3;
	  overflow-x: hidden;
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-flex: 1;
	      -ms-flex: auto;
	          flex: auto;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	      -ms-flex-direction: column;
	          flex-direction: column;
	  min-height: 0;
	  
	 
	  @media ${DEVICE.mobileDevices} {
			padding-left: 0;
	        padding-right: 15px;
	  }
	     
	  @media ${DEVICE.tabletDevices} {
			padding-left: 20px;
	        padding-right: 20px;
	  } 
	   @media ${DEVICE.tabletDevicesS} {
			padding-left: 100px;
			padding-right: 100px;
	  } 
	  
	 @media ${DEVICE.laptopS} {
	    width: calc(100% - 344px);
	 }
	 
	  
`

export default StyledPageContent;


