import React from 'react';
import { connect } from "react-redux";
import { Collapse } from 'antd';
import { createStructuredSelector } from 'reselect';

import { appInfoChurchesSelector } from '../../../../services/appInfo/appInfo.selector';
import { getLocalizedKey } from "../../../../utils/utils";
import {FormattedMessage} from "react-intl";
import StyledAccordion from "./styled/StyledAccordion";
import StyledAccordionListLink from "./styled/StyledAccordionListLink";
import StyledAccordionInfo from "./styled/StyledAccordionInfo";
import StyledPanel from "./styled/StyledPanel";
import StyledAccordionLink from "./styled/StyledAccordionLink";
import iconWebsite from "../../../../assets/icons/Website.svg";
import iconFb from "../../../../assets/icons/Facebook.svg";
import iconMap from "../../../../assets/icons/Map.svg"

const AsideAccordion = ({ churches }) => <StyledAccordion>
    { churches && <Collapse accordion
              expandIconPosition="right"

    >
        {
            churches?.map(church => {
                const name = getLocalizedKey('name');
                const contactInfo = getLocalizedKey('contactInfo');
                return (
                    <StyledPanel
                        header={ church[name] }
                        key={ church.id.toString() }

                    >
                            <StyledAccordionInfo>
                                <p>
                                    <FormattedMessage id="MainPage.ContactPerson" />:
                                </p>
                                <br/>
                                <p>{ church[contactInfo] }</p>
                            </StyledAccordionInfo>

	                        <StyledAccordionListLink>
                                <StyledAccordionLink
	                                rel="noopener noreferrer"
	                                target="_blank"
                                    href={ church?.website }
                                >
                                    <img src={iconWebsite} alt="" />
                                </StyledAccordionLink>

                                <StyledAccordionLink
	                                rel="noopener noreferrer"
	                                target="_blank"
	                                href= { church?.facebookPage }
                                >
                                    <img src={iconFb} alt="" />
                                </StyledAccordionLink>

                                <StyledAccordionLink
	                                target="_blank"
                                    rel="noopener noreferrer"
	                                href= { church?.googleMapsLink }
                                >
                                    <img src={iconMap} alt="" />
                                </StyledAccordionLink>
	                        </StyledAccordionListLink>
                    </StyledPanel>
                )
            } )
        }

    </Collapse> }
</StyledAccordion>;

export default connect(
    createStructuredSelector({
        churches: appInfoChurchesSelector
    }),
    {}
)(AsideAccordion);